import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lost-withdraw',
  templateUrl: './lost-withdraw.component.html',
  styleUrls: ['./lost-withdraw.component.sass']
})
export class LostWithdrawComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
