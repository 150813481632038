import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-cabinet404error',
  templateUrl: './cabinet404error.component.html',
  styleUrls: ['./cabinet404error.component.sass']
})
export class Cabinet404errorComponent implements OnInit, AfterViewInit,OnDestroy {

  constructor() { }

  private loadExternalStyles(styleUrl: string) {
    return new Promise((resolve, reject) => {
      const styleElement = document.createElement('link');
      styleElement.href = styleUrl;
      styleElement.rel="stylesheet";
      styleElement.onload = resolve;
      document.getElementById('elem').appendChild(styleElement);
    });
  }


  ngAfterViewInit(): void {
    this.loadExternalStyles('/assets/auth/css/app.css').then(() => {
      $('body').addClass('app');
      $('.container2').css('display','none');
      $('body').css('display','block');
      $('#load').css('display','block');
    }).catch(() => {
      $('body').addClass('app');
      $('.container2').css('display','none');
      $('body').css('display','block');
      $('#load').css('display','block');
    });

  
  }
  ngOnDestroy(): void {
    $('body').css('display','none');
    $('#load').css('display','none');
    $('.container2').css('display','');
   $('body').removeClass('app');
  }

  ngOnInit(): void {
  }

}
