import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-cabinet',
  templateUrl: './cabinet.component.html',
  styleUrls: ['./cabinet.component.sass']
})
export class CabinetComponent implements  OnInit//,AfterViewInit,OnDestroy
  {

  constructor() { }
 
  ngOnInit(): void {
  }
   
  ngAfterViewInit(): void {
   // $('.container2').fadeOut(800,'swing',function () {
      /*$('.container2').hide()
      $('body').css('display','block');
      $('#load').show();*/
   // }); 
  }

  ngOnDestroy(): void {
   // $('.container2').fadeIn(800,'swing',function () {
    /*  $('body').css('display','');
      $('#load').hide()
      $('.container2').show()*/

    //}); 
  }
}
