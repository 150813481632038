import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutComponent } from './about.component';
import { SiteHeaderModule } from 'src/app/components/site-header/site-header.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [AboutComponent],
  imports: [
    CommonModule,SiteHeaderModule,RouterModule,
  ]
})
export class AboutModule { }
