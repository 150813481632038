import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvestsettingsComponent } from './investsettings.component';
import { FormsModule } from '@angular/forms';
import { DepositComponent } from './deposit/deposit.component';
import { RobotsComponent } from './robots/robots.component';



@NgModule({
  declarations: [InvestsettingsComponent,DepositComponent, RobotsComponent],
  imports: [
    CommonModule,FormsModule
  ],
  exports:[DepositComponent, RobotsComponent]
})
export class InvestsettingsModule { }
