import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.sass']
})
export class FaqComponent implements OnInit,OnDestroy,AfterViewInit {

  constructor() { }
  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    $('body').removeClass('faq-page');
  }
  ngAfterViewInit(): void {
    $('body').addClass('faq-page');
  }
  click(id)
  {
    if($('#'+id).hasClass('collapse'))
    {
      $('#'+id).removeClass('collapse')
    }
    else
    {
      $('#'+id).addClass('collapse')
    }
  }

}
