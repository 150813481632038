import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/Service/http.service';

@Injectable({
  providedIn: 'root'
})
export class InvestService {

  constructor(private request:HttpService) { }

  public async get() {
    let body = {};
    var result = await this.request.post('/cabinet/invest',body).toPromise().then(
        (res:any)=>{
         
          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result;
  }

  public async creates(number_invest:number,rangeInputDeposit:number) {
    let body = {number_invest,rangeInputDeposit};

      var result = await this.request.post('/cabinet/invests',body).toPromise().then(
          (res:any)=>{
            //console.log(res);

            return res;
        },
        err=>{
          console.log(err);
          return null;
        });
        return result;
  }

  public async admin_investing() {
    let body = {};
    var result = await this.request.post('/cabinet/admin/invest',body).toPromise().then(
        (res:any)=>{
          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result;
  }

  public async admin_invest_create_post(type,name,procent,term,min_summa,max_summa) {
    let body = {type,name,procent,term,min_summa,max_summa};
    var result = await this.request.post('/cabinet/admin/invest/create',body).toPromise().then(
        (res:any)=>{
          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result;
  }

  public async admin_invest_remove(id:number) {
    let body = {};
    var result = await this.request.post('/cabinet/admin/invest/'+id+'/remove',body).toPromise().then(
        (res:any)=>{
          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result;
  }


  public async admin_profile_user_cancel_deposit(id:number) {
    let body = {'dep_id':id};
    var result = await this.request.post('/cabinet/admin/invest/cancel_user',body).toPromise().then(
        (res:any)=>{
          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result;
  }

}
