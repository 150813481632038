<app-site-header></app-site-header>
<section class="banner-section">
    <div class="container">
        <div class="header-title text-center">
            <h2 class="title">Часто задаваемые вопросы</h2>
            <span class="sub-title">Главная &gt; <span>Вопрос - ответ</span></span>
            <div class="mouse hidden-xs wow fadeInDown animated" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <div class="mouse-icon">
                    <div class="wheel"></div>
                </div>
            </div>
        </div>
        <!-- header-title -->
    </div>
</section>
<section class="faq-section section-padding">
    <div class="container">
        <div class="row">
            <div class="col-md-9">
                <div class="faq-wrapper">
                    <h3>Список ответов на часто задаваемые вопросы:</h3>
                    <p>На этой странице вы найдете ответы на самые распространенные вопросы, касающиеся наших услуг и принципов работы. Если Вы не нашли ответ на свой вопрос, то можете обратиться к онлайн-консультанту на нашем сайте или написать нам на почту
                        через <a [routerLink]="['/contacts']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active">форму обратной связи</a>.</p>
                    <div class="content-bar">
                        <div class="faq wow fadeInLeft animated animated" data-wow-duration="1s" style="visibility: visible; animation-duration: 1s; animation-name: fadeInLeft;">
                            <div class="panel-group" id="accordion">
                                <div class="panel panel-default">
                                    <div class="panel-heading">
                                        <h4 class="panel-title">
                                            <a data-toggle="collapse" class="panel-toggle collapsed" data-parent="#accordion" href="#collapseOne" (click)="click('collapseOne')" aria-expanded="false">
                                                КАК НАЧАТЬ РАБОТУ С КОМПАНИЕЙ AIR SOLUTIONS GROUP?
                                            </a>
                                            <span class="pull-right"><i class="fa fa-plus" aria-hidden="true"></i></span>
                                        </h4>
                                    </div>
                                    <div id="collapseOne" class="panel-collapse collapse" style="height: 0px;" aria-expanded="false">
                                        <span class="pull-right"><i class="fa fa-minus" aria-hidden="true"></i></span>
                                        <div class="panel-body">
                                            Для того, чтобы начать работу с компанией Air Solutions Group, Вам следует пройти простую процедуру регистрации на нашем сайте. Далее необходимо внести свой первый депозит, выбрав соответствующий раздел в личном кабинете, после чего вся инвестиционная
                                            работа будет проводиться нашей компанией, а Вы, в свою очередь, будете получать с этого ежедневный доход.
                                        </div>
                                    </div>
                                </div>
                                <div class="panel panel-default">
                                    <div class="panel-heading">
                                        <h4 class="panel-title">
                                            <a data-toggle="collapse" class="panel-toggle collapsed" data-parent="#accordion" href="#collapsenew" aria-expanded="false">
                                                МОГУ ЛИ Я "ПРОИГРАТЬ" И НЕ ЗАРАБОТАТЬ?
                                            </a> <span class="pull-right"><i class="fa fa-plus" aria-hidden="true"></i></span>
                                        </h4>
                                    </div>
                                    <div id="collapsenew" class="panel-collapse collapse" style="height: 0px;" aria-expanded="false"> <span class="pull-right"><i class="fa fa-minus" aria-hidden="true"></i></span>
                                        <div class="panel-body">
                                            Конечно же, наш проект – это не беспроигрышная лотерея. В настоящем бизнесе всегда есть риск. Но речь идет о том, чтобы этот риск был не просто оправдан, а о сведении его к минимуму. Именно на это нацелены мы, наша бизнес-модель. Стратегия минимизации
                                            рисков предусматривает сразу несколько компонентов:<br><br>
                                            <strong>первый</strong> – это команда аналитиков, которая отвечает за проект и постоянно работает с рисками разного уровня, как краткосрочными, так и долгосрочными;<br><br>
                                            <strong>второй</strong> – у нас есть отдельная стратегия инвестирования (5 критериев), которая также предусматривает минимизацию рисков именно в этом направлении;<br><br>
                                            <strong>третий</strong> – диверсификация в работе, в первую очередь – инвестиций. Мы четко держим баланс между краткосрочными инвестициями, которые должны принести прибыль уже в ближайшей перспективе, и долгосрочными
                                            инвестициями, которые служат для нас почвой для стабильности и стратегического развития. Проще говоря, если у нас возникнут какие-либо финансовые сложности с одним стартапом, то мы сможем это компенсировать
                                            прибылью с других.<br><br>
                                            <strong>четвертый</strong> – проект Air Solutions Group основан на личных инвестициях его собственников, которые являются также учредителями компании. Эти средства составляют 100% первичных инвестиций Air Solutions
                                            Group в стартапы, и, согласно документам компании, процент собственников в операционной инвестиционной деятельности никогда не должен составлять меньше 51% от общей суммы инвестиций, которые компания может привлечь.
                                            Таким образом, учредители нашей компании не просто являются гарантами ее деятельности, но и обеспечивают стабильность выплат для инвесторов, гарантируя их инвестиции своими.<br><br>
                                            <strong>пятый</strong> – мы постоянно ведем переговоры не только с новыми стартапами, но также с инвестиционными и венчурными фондами, стартап-инкубаторами и большими компаниями, в которых есть интерес в IT,
                                            чтобы привлечь их к сотрудничеству, в том числе на уровне финансовой поддержки – кредитной, инвестиционной, а в перспективе возможно и ICO, IPO.<br>
                                            <br> Все это отображено также в нашей бизнес-модели и ежедневной работе, поэтому следите за проектом и становитесь нашим инвестором, чтобы лично убедиться, как все работает, и в том, что зарабатывать на технологиях
                                            будущего можно уже сегодня и зарабатывать отлично.
                                        </div>
                                    </div>
                                </div>
                                <div class="panel panel-default">
                                    <div class="panel-heading">
                                        <h4 class="panel-title">

                                            <a data-toggle="collapse" class="panel-toggle collapsed" data-parent="#accordion" href="#collapseFive" aria-expanded="false">
                                                ЕСТЬ ЛИ ОГРАНИЧЕНИЯ НА СУММУ ВЫВОДА ДЕНЕЖНЫХ СРЕДСТВ?
                                            </a> <span class="pull-right"><i class="fa fa-plus" aria-hidden="true"></i></span>
                                        </h4>
                                    </div>
                                    <div id="collapseFive" class="panel-collapse collapse" style="height: auto;" aria-expanded="false"> <span class="pull-right"><i class="fa fa-minus" aria-hidden="true"></i></span>
                                        <div class="panel-body">
                                            Всё, что находится у инвестора на личном счету, он может вывести в соответствии с персональным лимитом транзакций на своем платежном реквизите.
                                        </div>
                                    </div>
                                </div>
                                <div class="panel panel-default">
                                    <div class="panel-heading">
                                        <h4 class="panel-title">

                                            <a data-toggle="collapse" class="panel-toggle collapsed" data-parent="#accordion" href="#collapseSix" aria-expanded="false">
                                                КАК ЧАСТО ОСУЩЕСТВЛЯЮТСЯ НАЧИСЛЕНИЯ? И НАСКОЛЬКО БЫСТРО ПРОИЗВОДЯТСЯ ВЫПЛАТЫ?
                                            </a> <span class="pull-right"><i class="fa fa-plus" aria-hidden="true"></i></span>
                                        </h4>
                                    </div>
                                    <div id="collapseSix" class="panel-collapse collapse" style="height: auto;" aria-expanded="false"> <span class="pull-right"><i class="fa fa-minus" aria-hidden="true"></i></span>
                                        <div class="panel-body">
                                            Прибыль от инвестиции начисляется ежедневно. Обработка заявок на вывод средств производится минимум 5 минут и не более 24 часов. Однако практика показывает, что обработка заявки занимает всего пару часов.
                                        </div>
                                    </div>
                                </div>
                                <div class="panel panel-default">
                                    <div class="panel-heading">
                                        <h4 class="panel-title">
                                            <a data-toggle="collapse" class="panel-toggle collapsed" data-parent="#accordion" href="#collapseSeven" aria-expanded="false">
                                                ПО КАКИМ ИНВЕСТИЦИОННЫМ ПЛАНАМ РАБОТАЕТ Air Solutions Group?
                                            </a> <span class="pull-right"><i class="fa fa-plus" aria-hidden="true"></i></span>
                                        </h4>
                                    </div>
                                    <div id="collapseSeven" class="panel-collapse collapse" style="height: auto;" aria-expanded="false"> <span class="pull-right"><i class="fa fa-minus" aria-hidden="true"></i></span>
                                        <div class="panel-body">
                                            Наша бизнес-модель предусматривает, что вы выбираете один из 5-х периодов инвестирования – 1, 14, 30, 60 и 90 дней.<br><br> При этом, вы получаете 1% в день от суммы инвестиции, а также Вы можете получить бонус
                                            от 1.5% до 5% от финальной суммы в конце 14, 30, 60 или 90-дневного периода инвестиции соответственно. Минимальная инвестиция в рамках нашего проекта составляет 25 USD.<br><br> Дополнительные подробности вы
                                            найдете в разделе <a target="_blank" [routerLink]="['/bussiness-model']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active"> «Как с нами зарабатывать?» </a> и
                                            <a target="_blank" [routerLink]="['/about']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active"> «О Компании» </a> </div>
                                    </div>
                                </div>
                                <div class="panel panel-default">
                                    <div class="panel-heading">
                                        <h4 class="panel-title">

                                            <a data-toggle="collapse" class="panel-toggle collapsed" data-parent="#accordion" href="#collapseEight" aria-expanded="false">
                                                ЕСТЬ ЛИ У ВАС ПАРТНЕРСКАЯ ПРОГРАММА?
                                            </a> <span class="pull-right"><i class="fa fa-plus" aria-hidden="true"></i></span>
                                        </h4>
                                    </div>
                                    <div id="collapseEight" class="panel-collapse collapse" style="height: auto;" aria-expanded="false"> <span class="pull-right"><i class="fa fa-minus" aria-hidden="true"></i></span>
                                        <div class="panel-body">
                                            Да, есть. Вы сможете единоразово получить бонус в размере 5% от размера депозита нового партнера нашей компании, которого пригласили именно Вы. Получить прибыль с партнера можно лишь в том случае, если он зарегистрируется на нашем сайте по вашей
                                            <a target="_blank" href="/lkpartner">специальной ссылке</a> и пополнит свой депозит. Только тогда Вы получите бонус по партнерской программе.
                                        </div>
                                    </div>
                                </div>
                                <div class="panel panel-default">
                                    <div class="panel-heading">
                                        <h4 class="panel-title">

                                            <a data-toggle="collapse" class="panel-toggle collapsed" data-parent="#accordion" href="#collapseNine" aria-expanded="false">
                                                МОЖНО ЛИ СОЗДАВАТЬ СРАЗУ НЕСКОЛЬКО ДЕПОЗИТОВ?
                                            </a> <span class="pull-right"><i class="fa fa-plus" aria-hidden="true"></i></span>
                                        </h4>
                                    </div>
                                    <div id="collapseNine" class="panel-collapse collapse" style="height: auto;" aria-expanded="false"> <span class="pull-right"><i class="fa fa-minus" aria-hidden="true"></i></span>
                                        <div class="panel-body">
                                            Да, можно. При создании нового депозита его сумма прибавляется к общей сумме всех ваших депозитов. В данном случае проценты будут увеличены на новую сумму. В итоге такая модель инвестирования принесет Вам еще больше прибыли.
                                        </div>
                                    </div>
                                </div>
                                <div class="panel panel-default">
                                    <div class="panel-heading">
                                        <h4 class="panel-title">
                                            <a data-toggle="collapse" class="panel-toggle collapsed" data-parent="#accordion" href="#collapseTen" aria-expanded="false">
                                                ВЫ ГАРАНТИРУЕТЕ КОНФИДЕНЦИАЛЬНОСТЬ ЛИЧНОЙ ИНФОРМАЦИИ ИНВЕСТОРОВ?
                                            </a> <span class="pull-right"><i class="fa fa-plus" aria-hidden="true"></i></span>
                                        </h4>
                                    </div>
                                    <div id="collapseTen" class="panel-collapse collapse" style="height: auto;" aria-expanded="false"> <span class="pull-right"><i class="fa fa-minus" aria-hidden="true"></i></span>
                                        <div class="panel-body">
                                            Да, гарантируем. Персональная информация наших инвесторов не передается третьим лицам.
                                        </div>
                                    </div>
                                </div>
                                <div class="panel panel-default">
                                    <div class="panel-heading">
                                        <h4 class="panel-title">

                                            <a data-toggle="collapse" class="panel-toggle collapsed" data-parent="#accordion" href="#collapseEleven" aria-expanded="false">
                                                Я НЕ НАШЕЛ В СПИСКЕ ОТВЕТ НА МОЙ ВОПРОС
                                            </a> <span class="pull-right"><i class="fa fa-plus" aria-hidden="true"></i></span>
                                        </h4>
                                    </div>
                                    <div id="collapseEleven" class="panel-collapse collapse" style="height: auto;" aria-expanded="false"> <span class="pull-right"><i class="fa fa-minus" aria-hidden="true"></i></span>
                                        <div class="panel-body">
                                            Если в «FAQ» вам не удалось найти ответ на интересующий вопрос, то вы можете обратиться в нашу службу техподдержки. Вся контактная информация находится на странице <a target="_blank" href="">«Контакты».</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="section-sidebar">
                    <div class="project-part">
                        <h4>Полезные ссылки</h4>
                        <ul>
                            <li><a [routerLink]="['/']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active">Главная <i class="fa fa-angle-right" aria-hidden="true"></i></a></li>
                            <li><a [routerLink]="['/about']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active">О компании <i class="fa fa-angle-right" aria-hidden="true"></i></a></li>
                            <li><a [routerLink]="['/bussiness-model']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active">Бизнес-модель <i class="fa fa-angle-right" aria-hidden="true"></i></a></li>
                            <li><a [routerLink]="['/faq']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active">F.A.Q. <i class="fa fa-angle-right" aria-hidden="true"></i></a></li>
                            <li><a [routerLink]="['/contacts']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active">Контакты <i class="fa fa-angle-right" aria-hidden="true"></i></a></li>
                        </ul>
                    </div>

                    <div class="project-doc">
                        <div class="col-md-12">
                            <h4>Документы компании:</h4>
                            <div class="col-md-6 col-sm-6 col-xs-6">
                                <div class="portfolio-item wow bounceIn" data-wow-delay="0.3s" style=" animation-delay: 0.3s; animation-name: none;">
                                    <div class="portfolio-image">
                                        <a data-fancybox="gallery" href="/assets/site/img/reg-mini.jpg"><img style="width: 100%;" src="/assets/site/img/reg-mini.jpg"></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-6">
                                <div class="portfolio-item wow bounceIn" data-wow-delay="0.3s" style=" animation-delay: 0.3s; animation-name: none;">
                                    <div class="portfolio-image">
                                        <a data-fancybox="gallery" href="/assets/site/img/sv-mini.jpg"><img style="width: 100%;" src="/assets/site/img/sv-mini.jpg"></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-12 col-xs-12" style="font-size: 10px;text-align: center;"> СВИДЕТЕЛЬСТВО O РЕГИСТРАЦИИ ЮРИДИЧЕСКОГО ЛИЦА <br>Insider Business Group Limited <br> Company Number: 2780528</div>
                            <div class="asset-part">
                                <a [routerLink]="['/cabinet']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active" class="btn btn-default"><i class="fa fa-user-circle-o" aria-hidden="true"></i> Личный кабинет</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>