<fieldset class="uk-fieldset">

    <legend class="uk-legend uk-flex-center">Сумма пополнения <span class="rangeInputDepositShow">
        <ng-container *ngIf="valute=='ruble'">{{(100*kurs_usd_rub).toFixed()}}</ng-container>
                                                    <ng-container *ngIf="valute=='dollars'">{{(100).toFixed()}}</ng-container>
</span>
        <ng-container *ngIf="valute=='ruble'">Р</ng-container>
        <ng-container *ngIf="valute=='dollars'">$</ng-container>

    </legend>

    <div class="uk-margin">
        <label class="pure-material-slider">
<!--input id="rangeInputDeposit" (change)="rangeInputDepositChange();" type="range" value="100" min="100" max="9999999" step="1"-->

<ng-container *ngIf="valute=='dollars'">
    <input class="uk-input" id="rangeInputDeposit" (change)="rangeInputDepositChange();" style="border-radius: 50px; border: 1px solid black; background-color: white" value="100" required>
</ng-container>
<ng-container *ngIf="valute=='ruble'">

    <input class="uk-input" id="rangeInputDeposit" (change)="rangeInputDepositChange();" style="border-radius: 50px; border: 1px solid black; background-color: white" value="{{(100*kurs_usd_rub).toFixed()}}" required>

</ng-container>
</label>
    </div>
    <br/>
</fieldset>

<div class="uk-child-width-1-4@s  uk-child-width-1-1 uk-text-center" style="    display: flex;
flex-wrap: wrap;
margin: 0;
padding: 0;
list-style: none;" uk-grid>

    <form method='get' hidden id="form_pay" action='https://www.free-kassa.ru/merchant/cash.php'>
        <input type='hidden' name='m'  id="Merchand_id" value='1'>
        <input type='hidden' name='oa' id="order_amount" value='100'>
        <input type='hidden' name='o' id="order_id" value='1'>
        <input type='hidden' name='s' id="sign" value='1'>
        <input type='hidden' name='lang' value='ru'>
        <input type='hidden' name='us_login' [value]='id'>
    </form>



    <div class="uk-margin-medium-bottom" style="margin:auto;padding: 10px;">
        <a (click)="create_order('100')">
            <div class="uk-card uk-card-small uk-card-default uk-card-hover uk-card-body" style="border-radius: 15px;background: #ffffff;">
                <img class="uk-align-center" width="auto" style="height:100px" src="/assets/cabinet/img/visa_master.svg" />
            </div>
        </a>
    </div>
    <div class="uk-margin-medium-bottom" style="margin:auto;padding: 10px;">
        <a href="https://my.qiwi.com/form/Sergei-K3Chc375gq">
            <div class="uk-card uk-card-small uk-card-default uk-card-hover uk-card-body" style="border-radius: 15px;background: #ffffff;">
                <img class="uk-align-center" width="auto" style="height:100px" src="/assets/cabinet/img/qiwi.svg" />
            </div>
        </a>
    </div>
    <div class="uk-margin-medium-bottom" style="margin:auto;padding: 10px;">
        <a (click)="create_order('45')">
            <div class="uk-card uk-card-small uk-card-default uk-card-hover uk-card-body" style="border-radius: 15px;background: #ffffff;">
                <img class="uk-align-center" width="auto" style="height:100px" src="/assets/cabinet/img/yandex.svg" />
            </div>
        </a>
    </div>
    <div class="uk-margin-medium-bottom" style="margin:auto;padding: 10px;">
        <a (click)="create_order('133')">
            <div class="uk-card uk-card-small uk-card-default uk-card-hover uk-card-body" style="border-radius: 15px;background: #ffffff;">
                <img class="uk-align-center" width="auto" style="height:100px" src="/assets/cabinet/img/fkwallet.svg" />
            </div>
        </a>
    </div>
    <div class="uk-margin-medium-bottom" style="margin:auto;padding: 10px;">
        <a (click)="create_order('132')">
            <div class="uk-card uk-card-small uk-card-default uk-card-hover uk-card-body" style="border-radius: 15px;background: #ffffff;">
                <img class="uk-align-center" width="auto" style="height:100px" src="/assets/cabinet/img/tele2.svg" />
            </div>
        </a>
    </div>
    <div class="uk-margin-medium-bottom" style="margin:auto;padding: 10px;">
        <a (click)="create_order('116')">
            <div class="uk-card uk-card-small uk-card-default uk-card-hover uk-card-body" style="border-radius: 15px;background: #ffffff;">
                <img class="uk-align-center" width="auto" style="height:100px" src="/assets/cabinet/img/BTC-alt.svg" />
            </div>
        </a>
    </div>
    <div class="uk-margin-medium-bottom" style="margin:auto;padding: 10px;">
        <a (click)="create_order_interkassa()">
            <div id="form_freekassa"></div>
            <div class="uk-card uk-card-small uk-card-default uk-card-hover uk-card-body" style="border-radius: 15px;background: #ffffff;">
                <img class="uk-align-center" width="100%" style="height:100px" src="/assets/cabinet/img/oplata_icon/visa.jpg" />
            </div>
        </a>
    </div>



</div>
<div class="uk-grid">
    <div class="uk-width-1-1 uk-margin-top">
        <div class="uk-card uk-card-small uk-card-default uk-card-body uk-margin-small-bottom uk-card-hover
    uk-text-small">
            <div class="uk-card-header">
                <div class="uk-grid uk-grid-small uk-text-large" data-uk-grid="">
                    <div class="uk-width-expand uk-text-center">
                        <span class="uk-text-emphasis ">Ваши депозиты</span>
                    </div>
                </div>
            </div>

            <div class="uk-card-body">

                <div style="overflow-x:auto;">
                    <table class="uk-table uk-table-middle uk-text-bold  uk-table-divider">
                        <thead class="uk-text-bold">
                            <tr>
                                <th>Дата</th>
                                <th>Сумма</th>
                                <th>Номер транзакции</th>
                                <th>Статус</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of list_items">
                                <td>{{item.created_at}}</td>
                                <td style="color: green">
                                    <ng-container *ngIf="valute=='ruble'">{{item.summa * kurs_usd_rub}}Р</ng-container>
                                    <ng-container *ngIf="valute=='dollars'">{{item.summa}}$</ng-container>
                                </td>
                                <td>{{item.id}}</td>

                                <ng-container *ngIf="item.status=='Ошибка'">
                                    <td style="color: red">{{item.status}}</td>
                                </ng-container>
                                <ng-container *ngIf="item.status=='Ожидается оплата'">
                                    <td style="color: blue">{{item.status}}</td>
                                </ng-container>
                                <ng-container *ngIf="item.status=='Пополнено'">
                                    <td style="color: green">{{item.status}}</td>
                                </ng-container>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</div>