import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SiteModule } from './pages/site/site.module';
import { CabinetModule } from './pages/cabinet/cabinet.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { GuardsService } from './Guards/GuardsService.service';
import { AdminService } from './LogicApi/Admin/admin.service';
import { AuthService } from './LogicApi/Auth/auth.service';
import { InvestService } from './LogicApi/Invest/invest.service';
import { PayService } from './LogicApi/Pay/pay.service';
import { ProfileService } from './LogicApi/Profile/profile.service';
import { LoginGuard } from './Guards/LoginGuard.service';
import { AuthGuard } from './Guards/AuthGuard.service';
import { AdminGuard } from './Guards/AdminGuard.service';
import { BuySellService } from './LogicApi/buysell/buysell.service';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    AppRoutingModule,
    SiteModule,
    CabinetModule
  ],
  providers: [
    AdminService,
    AuthService,
    InvestService,
    PayService,
    ProfileService,
    LoginGuard,
    AdminGuard,
    BuySellService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GuardsService,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
