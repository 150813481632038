<div class="uk-container uk-container-center">
    <ul class="uk-tab" data-uk-tab="{connect:'#my-id'}">
        <li (click)="change_menu(1)">
            <a>
                <h4>Пополнить счет</h4>
            </a>
        </li>
        <li (click)="change_menu(2)">
            <a>
                <h4>Вывести</h4>
            </a>
        </li>
    </ul>
    <ul id="my-id" class="uk-switcher uk-margin">
        <li [ngClass]="{'uk-active': item_uk_switcher==1}">
            <app-deposit></app-deposit>
        </li>
        <li [ngClass]="{'uk-active': item_uk_switcher==2}">
            <app-withdraw></app-withdraw>
        </li>
    </ul>

</div>