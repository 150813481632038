import { Component, OnInit, AfterViewInit, OnDestroy, Inject, Renderer2 } from '@angular/core';
import { AuthService } from 'src/app/LogicApi/Auth/auth.service';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
declare var $:any;


@Component({
  selector: 'app-personalcabinet',
  templateUrl: './personalcabinet.component.html',
  styleUrls: ['./personalcabinet.component.sass']
})
export class PersonalcabinetComponent implements OnInit, AfterViewInit, OnDestroy {
  interval;
  private subscriptions: Subscription[] = [];
  admin=false;
  constructor(public auth :AuthService,
    @Inject(DOCUMENT) private document: Document,
    private renderer2: Renderer2) { }

    private loadExternalStyles(styleUrl: string) {
      return new Promise((resolve, reject) => {
        const styleElement = document.createElement('link');
        styleElement.href = styleUrl;
        styleElement.rel="stylesheet";
        styleElement.onload = resolve;
        document.getElementById('elem').appendChild(styleElement);
      });
    }
  
  ngAfterViewInit(): void {
    this.loadExternalStyles('/assets/cabinet/css/cabinet.css').then(() => {
      $('body').addClass('app');
    $('.container2').css('display','none');
      $('body').css('display','block');
      $('#load').css('display','block');
    }).catch(() => {
      $('body').addClass('app');
    $('.container2').css('display','none');
      $('body').css('display','block');
      $('#load').css('display','block');
    });

    
    
  }
  ngOnDestroy(): void {
    $('body').css('display','none');
    $('#load').css('display','none');
    $('.container2').css('display','');
   $('body').removeClass('app');
   clearInterval(this.interval);
   this.subscriptions
    .forEach(s => s.unsubscribe());
  }
  photo=""
  public show=false;
  show_menu()
  {
    /*if($('#menu').hasClass('uk-open'))
    {
      $('#menu').removeClass('uk-open')
    }
    else
    $('#menu').addClass('uk-open')*/
    //$('#menu').addClass('uk-open')
    this.show=!this.show;
  }
  hide_menu()
  {
    this.show=false;
    //$('#menu').removeClass('uk-open')
  }

  ngOnInit(): void {
    const textScript = this.renderer2.createElement('script');
    textScript.src = 'https://cdn.jsdelivr.net/npm/uikit@latest/dist/js/uikit-icons.min.js';
    document.getElementById('elem').appendChild(textScript);
    const textScript2 = this.renderer2.createElement('script');
    textScript2.src = 'https://cdnjs.cloudflare.com/ajax/libs/uikit/3.5.6/js/uikit.min.js';
    document.getElementById('elem').appendChild(textScript2);

    this.interval = setInterval(() => {
     var r = this.auth.getMe();
     if(r==null)
     clearInterval(this.interval);

    },20000)
    const langSub = this.auth.UserData$
    .subscribe(() => {
      console.log(this.auth.userdata);
      
      if(this.auth.userdata.profile.photo!=null)
        this.photo='/cabinet/profile/get_avatar/'+this.auth.userdata.profile.id 
        else 
        this.photo=null;
        this.admin=this.auth.userdata.profile.is_admin ==1; 
    });
  this.subscriptions.push(langSub);
  }
 
  show_submenu()
  {
    if($('#dropdown_user_menu').hasClass('show'))
    {
      $('#dropdown_user_menu').removeClass('show')
    }
    else
    {
      $('#dropdown_user_menu').addClass('show')
    }
  }
  logout()
  {
    this.auth.logout();
  }
}
