<!--link rel="stylesheet" href="/assets/cabinet/css/cabinet.css"-->
<div id="elem"></div>
<div id="scripts">

</div>

<!--HEADER-->
<header id="top-head" class="uk-position-fixed">
    <div class="uk-container uk-container-expand uk-background-primary">
        <nav class="uk-navbar uk-light" data-uk-navbar="mode:click; duration: 250">
            <div class="uk-navbar-left">
                <div class="uk-navbar-item uk-hidden@m">
                    <a class="uk-logo" [routerLink]="['/cabinet/']"><img class="custom-logo" src="/assets/cabinet/img/startwhitelogo.png" alt=""></a>
                </div>
                <ul class="uk-navbar-nav uk-hidden@m">
                    <li (click)="show_menu()">
                        <a >Меню <span data-uk-icon="icon: triangle-down"></span></a>
                        <div id="menu" class="uk-navbar-dropdown" [class]="{'uk-open': show}">
                            <ul class="uk-nav uk-navbar-dropdown-nav">
                                <li><a [routerLink]="['/cabinet/']"><span class="uk-margin-small-right"><i class="fas fa-money-bill-wave"></i></span>Пополнение счета</a></li>
                                <li><a [routerLink]="['/cabinet/invest']"><span class="uk-margin-small-right"><i class="fas fa-money-bill-wave"></i></span>Депозиты</a></li>
                                <li><a [routerLink]="['/cabinet/profile']"><span class="uk-margin-small-right"><i class="fas fa-user-circle"></i></span>Профиль</a></li>
                                <li><a [routerLink]="['/cabinet/buysell']"><span class="uk-margin-small-right"><i class="fas fa-user-circle"></i></span>Торговля</a></li>

                                <ng-container *ngIf="admin">
                                    <li class="uk-nav-divider"></li>
                                    <li><a [routerLink]="['/cabinet/admin/pay']"><span  class="uk-margin-small-right"><i class="fas fa-user-cog"></i></span>Операции по счетам</a></li>
                                    <li><a [routerLink]="['/cabinet/admin/']"><span  class="uk-margin-small-right"><i class="fas fa-user-cog"></i></span>Управление пользователями</a></li>
                                    <li><a [routerLink]="['/cabinet/admin/invest']"><span  class="uk-margin-small-right"><i class="fas fa-user-cog"></i></span>Управление инвестициями</a></li>
                                </ng-container>
                                <li class="uk-nav-divider"></li>
                                <li><a (click)="logout()"><span  class="uk-margin-small-right"><i class="fas fa-sign-out-alt"></i></span> Выйти</a></li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="uk-navbar-right">
                <ul class="uk-navbar-nav">
                    <li><a [routerLink]="['/cabinet/']" title="Профиль" data-uk-tooltip><i class="fas fa-user-circle"></i></a></li>
                    <li><a (click)="logout()" title="Выйти" data-uk-tooltip><i class="fas fa-sign-out-alt"></i></a></li>
                </ul>
            </div>
        </nav>
    </div>
</header>
<!--/HEADER-->
<!-- LEFT BAR -->
<aside id="left-col" class="uk-light uk-visible@m" style="position: fixed;
left: 0;
top: 0;
bottom: 0;
overflow-x: hidden;
overflow-y: auto;
background-color: #222a30;
width: 240px;
z-index: 2;
transition: height .3s ease-out;">
    <div class="left-logo uk-flex uk-flex-middle">
        <a [routerLink]="['/cabinet/']">
            <img class="custom-l*ogo" src="/assets/cabinet/img/startwhitelogo.png" alt="">
        </a>
    </div>
    <div class="left-content-box  content-box-dark">
        <img src="/assets/cabinet/img/favicon.98322d20.png" alt="" class="profile-img">

        <h4 class="uk-text-center uk-margin-remove-vertical text-light">{{auth.userdata.profile.family}} {{auth.userdata.profile.name}} </h4>
        <div class="uk-position-relative uk-text-center uk-display-block">
            <a href="#" class="uk-text-small uk-text-muted uk-display-block uk-text-center" data-uk-icon="icon: triangle-down; ratio: 0.7">
                <ng-container *ngIf="admin==false">
                    Клиент
                </ng-container>
                <ng-container *ngIf="admin">
                    Admin
                </ng-container>
            </a>

        </div>
    </div>

    <div class="left-nav-wrap">
        <ul class="uk-nav uk-nav-default uk-nav-parent-icon" data-uk-nav>
            <li class="uk-nav-header">Навигация</li>

            <li><a [routerLink]="['/cabinet/']"><span class="uk-margin-small-right"><i class="fas fa-money-bill-wave"></i></span>Пополнение счета</a></li>

            <li><a [routerLink]="['/cabinet/invest']"><span class="uk-margin-small-right"><i class="fab fa-invision"></i></span>Депозиты</a></li>
            <li><a [routerLink]="['/cabinet/profile']"><span class="uk-margin-small-right"><i class="fas fa-user-circle"></i></span>Профиль</a></li>
            <li><a [routerLink]="['/cabinet/buysell']"><span class="uk-margin-small-right"><i class="fas fa-user-circle"></i></span>Торговля</a></li>


            <ng-container *ngIf="admin">
                <li class="uk-nav-divider"></li>
                <li><a [routerLink]="['/cabinet/admin/pay']"><span  class="uk-margin-small-right"><i class="fas fa-user-cog"></i></span>Операции по счетам</a></li>

                <li><a [routerLink]="['/cabinet/admin/']"><span  class="uk-margin-small-right"><i class="fas fa-user-cog"></i></span>Управление пользователями</a></li>
                <li><a [routerLink]="['/cabinet/admin/invest']"><span  class="uk-margin-small-right"><i class="fas fa-user-cog"></i></span>Управление инвестициями</a></li>
                <li><a [routerLink]="['/cabinet/statistics']"><span  class="uk-margin-small-right"><i class="fas fa-user-cog"></i></span>Статистика</a></li>

            </ng-container>
            <li class="uk-nav-divider"></li>
            <li><a (click)="logout()"><span  class="uk-margin-small-right"><i class="fas fa-sign-out-alt"></i></span> Выйти</a></li>

        </ul>
        <ng-container *ngIf="admin">
            <!--div class="left-content-box uk-margin-top">
                <h5>Daily Reports</h5>
                <div>
                    <span class="uk-text-small">Traffic <small>(+50)</small></span>
                    <progress class="uk-progress" value="50" max="100"></progress>
                </div>
                <div>
                    <span class="uk-text-small">Income <small>(+78)</small></span>
                    <progress class="uk-progress success" value="78" max="100"></progress>
                </div>
                <div>
                    <span class="uk-text-small">Feedback <small>(-12)</small></span>
                    <progress class="uk-progress warning" value="12" max="100"></progress>
                </div>
            </div-->
        </ng-container>
    </div>

</aside>


<div id="content" style="min-height: 575px;">
    <div class="uk-container uk-container-expand">

        <div class="uk-grid uk-grid-divider uk-grid-medium uk-child-width-1-2 uk-child-width-1-3@l uk-child-width-1-5@xl" data-uk-grid="">
            <div class="uk-first-column">
                <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary uk-icon"><i class="fas fa-coins"></i></span>Текущий баланс</span>
                <h1 class="uk-heading-primary uk-margin-remove  uk-text-primary">

                    <ng-container *ngIf="auth.userdata!=null">

                        <ng-container *ngIf="auth.userdata.profile.currency=='ruble'">
                            {{(auth.userdata.profile.balance * auth.userdata.kurs).toFixed()}} Р

                        </ng-container>
                        <ng-container *ngIf="auth.userdata.profile.currency=='dollars'">
                            {{(auth.userdata.profile.balance).toFixed()}} $

                        </ng-container>
                    </ng-container>
                </h1>
            </div>
            <!--div>
                <span class="uk-text-small"><span data-uk-icon="icon:clock" class="uk-margin-small-right uk-text-primary uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="clock"><circle fill="none" stroke="#000" stroke-width="1.1" cx="10" cy="10" r="9"></circle><rect x="9" y="4" width="1" height="7"></rect><path fill="none" stroke="#000" stroke-width="1.1" d="M13.018,14.197 L9.445,10.625"></path></svg></span>Текущее время</span>
                <h1 class="uk-heading-primary uk-margin-remove uk-text-primary" ><span id="hours">12</span>.<span id="minuts">00</span></h1>
            </div-->
            <div>
                <span class="uk-text-small"><span data-uk-icon="icon:clock" class="uk-margin-small-right uk-text-primary uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="clock"><circle fill="none" stroke="#000" stroke-width="1.1" cx="10" cy="10" r="9"></circle><rect x="9" y="4" width="1" height="7"></rect><path fill="none" stroke="#000" stroke-width="1.1" d="M13.018,14.197 L9.445,10.625"></path></svg></span>Сумма
                дохода
                </span>
                <h1 class="uk-heading-primary uk-margin-remove uk-text-primary">
                    <!-- @switch(\Auth::user()->currency)
                        @case("ruble")
                        {{(int)((\Auth::user()->profit??0) * \App\Models\Currency\MainCurrencyPars::where(['name'=>'usd_rub'])->first()->price)}} Р
                        @break
                        @case("dollars")
                        {{(int)(\Auth::user()->profit??0)}}$
                        @break
                    @endswitch-->
                    <ng-container *ngIf="auth.userdata!=null">

                        <ng-container *ngIf="auth.userdata.profile.currency=='ruble'">
                            {{(auth.userdata.profile.profit * auth.userdata.kurs).toFixed()}} Р

                        </ng-container>
                        <ng-container *ngIf="auth.userdata.profile.currency=='dollars'">
                            {{(auth.userdata.profile.profit).toFixed()}} $

                        </ng-container>
                    </ng-container>
                </h1>
            </div>
            <div>
                <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary uk-icon"><i class="fas fa-coins"></i></span>Всего вы вывели</span>
                <h1 class="uk-heading-primary uk-margin-remove  uk-text-primary">0</h1>
            </div>
        </div>
        <hr>
        <router-outlet></router-outlet>


        <!-- /panel -->
        <footer class="uk-section uk-section-small uk-text-center">
            <hr>
            <p class="uk-text-small uk-text-center">Copyright 2020 - <a href="/">Created by AirSolutions</a></p>
        </footer>
    </div>
</div>
<div id="elem">

</div>