import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ProfileComponent } from './profile.component';
import { FormsModule } from '@angular/forms';
import { VerificationModule } from './verification/verification.module';
import { SecureModule } from './secure/secure.module';
import { MyDataModule } from './my-data/my-data.module';



@NgModule({
  declarations: [ProfileComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    VerificationModule,
    SecureModule,
    MyDataModule
  ],
  exports:[
  ],
  bootstrap:[ProfileComponent]
})
export class ProfileModule { }
