import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.sass']
})
export class AboutComponent implements OnInit,OnDestroy,AfterViewInit {

  constructor() { }

  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    $('body').removeClass('about-page');
  }
  ngAfterViewInit(): void {
    $('body').addClass('about-page');

  }

}
