import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusinessModelComponent } from './business-model.component';
import { SiteHeaderModule } from 'src/app/components/site-header/site-header.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [BusinessModelComponent],
  imports: [
    CommonModule,SiteHeaderModule,RouterModule,
  ]
})
export class BusinessModelModule { }
