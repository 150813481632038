<div id="elem"></div>
<div class="container">
    <!-- BEGIN: Error Page -->
    <div class="error-page flex flex-col lg:flex-row items-center justify-center h-screen text-center lg:text-left">
        <div class="-intro-x lg:mr-20">
            <img alt="" class="h-48 lg:h-auto" src="/assets/cabinet/img/error-illustration.svg">
        </div>
        <div class="text-white mt-10 lg:mt-0">
            <div class="intro-x text-6xl font-medium">404</div>
            <div class="intro-x text-xl lg:text-3xl font-medium">Oops. Вы нашли несуществующую страницу.</div>
            <div class="intro-x text-lg mt-3">Вы ввели несуществующий адресс или страница была перемещена.</div>
        </div>
    </div>
    <!-- END: Error Page -->
</div>