<div class="uk-container uk-container-center">
    <ul class="uk-tab" data-uk-tab="{connect:'#my-id'}">
        <li (click)="change_menu(1)">
            <a>
                <h4>Личные данные</h4>
            </a>
        </li>
        <li (click)="change_menu(2)">
            <a>
                <h4>Безопастность</h4>
            </a>
        </li>
        <li (click)="change_menu(3)">
            <a>
                <h4>Верификация документов</h4>
            </a>
        </li>
    </ul>
    <ul id="my-id" class="uk-switcher uk-margin">
        <li [ngClass]="{'uk-active': item_uk_switcher==1}">
            <app-my-data></app-my-data>
        </li>
        <li [ngClass]="{'uk-active': item_uk_switcher==2}">
            <app-secure></app-secure>
        </li>
        <li [ngClass]="{'uk-active': item_uk_switcher==3}">
            <app-verification></app-verification>
        </li>

    </ul>

</div>