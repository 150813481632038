import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/LogicApi/Auth/auth.service';
import { InvestService } from 'src/app/LogicApi/Invest/invest.service';
import { ProfileService } from 'src/app/LogicApi/Profile/profile.service';

@Component({
  selector: 'app-secure',
  templateUrl: './secure.component.html',
  styleUrls: ['./secure.component.sass']
})
export class SecureComponent implements OnInit {

  constructor(private invest:InvestService,public auth :AuthService,private profile:ProfileService) { }

  ngOnInit(): void {
  }

  lost_password
  newpassword
  refreshpassword

  change_password()
  {
      if(this.newpassword==this.refreshpassword)
      this.profile.update_password(this.lost_password,this.newpassword,this.auth.userdata.profile.id).
      then((res:any)=>
      {
          this.lost_password=""
          this.newpassword=""
          this.refreshpassword=""
      })
  }
}
