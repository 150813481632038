import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BuysellComponent } from './buysell.component';


const children_routes:Routes=[
 
 
]

const routes: Routes = [

  {
    path:'',
    component:BuysellComponent
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BuysellRoutingModule { }
