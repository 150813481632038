import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/LogicApi/Admin/admin.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.sass']
})
export class ListComponent implements OnInit {

 
  constructor(private admin:AdminService,private route:Router) { }
  list_users=null;
  ngOnInit(): void {
    this.admin.list_users_Get().then((res)=>
    {
      //console.log(res);
      this.list_users=res.users;
    });
  }
  show(id:any)
  {
    this.route.navigate(['/cabinet/admin/profile/',id]);
  }

}
