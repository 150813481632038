<div class="uk-container uk-container-center">
    <ul class="uk-tab" data-uk-tab="{connect:'#my-id'}">
        <li (click)="change_menu(1)">
            <a>
                <h4>Депозиты</h4>
            </a>
        </li>
        <li (click)="change_menu(2)">
            <a>
                <h4>Торговые роботы</h4>
            </a>
        </li>
    </ul>
    <ul id="my-id" class="uk-switcher uk-margin">
        <li [ngClass]="{'uk-active': item_uk_switcher==1}">
            <div class="uk-grid">
                <div class="uk-width-3-4@s uk-width-1-1">

                    <div class="uk-card uk-card-small uk-card-default">
                        <div class="uk-card-header">
                            <div class="uk-grid uk-grid-small uk-text-large" data-uk-grid="">
                                <div class="uk-width-expand uk-text-center">
                                    <span class="uk-text-emphasis ">Выберите инвестиционный план</span>
                                </div>
                            </div>
                        </div>
                        <div class="uk-card-body">

                            <ng-container *ngFor="let item of deposit_items; let i = index">
                                <ng-container *ngIf="i==0">
                                    <div id="datadeposit{{i}}">
                                        <form method="post">
                                            <fieldset class="uk-fieldset">
                                                <legend class="uk-legend uk-flex-center">Сумма инвестиции <span class="rangeInputDepositShow{{i}}">
                                                                  
                                                    <ng-container *ngIf="valute=='ruble'">{{(item.min_deposit*kurs_usd_rub).toFixed()}}</ng-container>
                                                    <ng-container *ngIf="valute=='dollars'">{{(item.min_deposit).toFixed()}}</ng-container>
</span>
                                                    <ng-container *ngIf="valute=='ruble'">Р</ng-container>
                                                    <ng-container *ngIf="valute=='dollars'">$</ng-container>

                                                </legend>
                                                <div class="uk-margin">
                                                    <label class="pure-material-slider">
                                                                
                                                                <input name="rangeInputDeposit" id="rangeInputDeposit{{i}}" (change)="rangeInputDepositChange(i,item);" type="range" value="{{item.min_deposit}}" min="{{item.min_deposit}}" max="{{balance}}" step="1">
                                                                <input hidden name="number_invest" value="{{item.id}}">
                                                            </label>
                                                </div>
                                            </fieldset>
                                            <div class="uk-grid uk-grid-small uk-text-large" data-uk-grid="">
                                                <div class="uk-width-expand uk-text-center">
                                                    <span class="uk-text-emphasis ">Описание выбранного инвестиционного плана</span>
                                                </div>
                                            </div>
                                            <div class="uk-grid-2" uk-grid>
                                                <div class="uk-width-1-3@s uk-width-1-1">
                                                    <div class="uk-card uk-card-default uk-card-body uk-text-middle" style="border-right: 1px solid #dadada;">
                                                        <p><b><span class="uk-text-large" style="color:#2f80ed;">{{item.name}} <span id="percent{{i}}">{{item.percent}}</span>%</span> в сутки</b></p>
                                                        <p>Срок контракта: <span id="days{{i}}">{{item.count_days}}</span> дней</p>
                                                        <p>Одноразовый депозит не требующий верификаци</p>
                                                    </div>
                                                </div>

                                                <div class="uk-width-1-3@s uk-width-1-1">
                                                    <div class="uk-card uk-card-default uk-card-body uk-text-middle" style="border-right: 1px solid #dadada;">
                                                        <p><b><span class="uk-text-large" style="color:#2f80ed;">
                                                            
                                                            <ng-container *ngIf="valute=='ruble'">
                                                                <span id="day_price{{i}}">{{(item.min_deposit/100*item.percent * kurs_usd_rub).toFixed()}}</span>Р       
                                                            </ng-container>
                                                            <ng-container *ngIf="valute=='dollars'">
                                                                <span id="day_price{{i}}">{{(item.min_deposit/100*item.percent).toFixed()}}</span>$</ng-container>
                                                            </span></b><br> Ваша прибыль в <span style="color:#2f80ed;">день</span>
                                                        </p>
                                                        <p><b><span class="uk-text-large" style="color:#2f80ed;">
                                                            
                                                            <ng-container *ngIf="valute=='ruble'">
                                                                <span id="term_price{{i}}"> {{(item.min_deposit/100*item.percent*item.count_days * kurs_usd_rub).toFixed()}}</span>
                                                               </ng-container>
                                                            <ng-container *ngIf="valute=='dollars'">
                                                                <span id="term_price{{i}}">
                                                                    {{(item.min_deposit/100*item.percent*item.count_days).toFixed()}}</span></ng-container>
                                                            </span></b><br> Ваша прибыль за <span style="color:#2f80ed;">{{item.count_days}} дней</span>
                                                        </p>
                                                        <!--p><b><span class="uk-text-large" style="color:#2f80ed;">$ 42.00</span></b><br>
                                                                Ваша прибыль за <span style="color:#2f80ed;">весь период</span>
                                                            </p-->
                                                    </div>
                                                </div>
                                                <div class="uk-width-1-3@s uk-width-1-1">
                                                    <div class="uk-card uk-card-default uk-card-body uk-text-middle">
                                                        <p>Прибыль по выбранному депозиту начисляется с частотой: один раз в 1 день. Фондовый сбор равен 1%. Автоматически списывается за управление активами.</p>
                                                        <ng-container *ngIf="balance>item.min_deposit">
                                                            <button class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" style="border-radius: 50px " (click)="pay_serv(item.id,'deposits',i)">Инвестировать</button>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="i!=0">
                                    <div id="datadeposit{{i}}" style="display: none">
                                        <form method="post">
                                            <fieldset class="uk-fieldset">
                                                <legend class="uk-legend uk-flex-center">Сумма инвестиции <span class="rangeInputDepositShow{{i}}">
                                                                  
                                                    <ng-container *ngIf="valute=='ruble'">{{(item.min_deposit*kurs_usd_rub).toFixed()}}</ng-container>
                                                    <ng-container *ngIf="valute=='dollars'">{{(item.min_deposit).toFixed()}}</ng-container>
</span>
                                                    <ng-container *ngIf="valute=='ruble'">Р</ng-container>
                                                    <ng-container *ngIf="valute=='dollars'">$</ng-container>

                                                </legend>
                                                <div class="uk-margin">
                                                    <label class="pure-material-slider">
                                                                
                                                                <input name="rangeInputDeposit" id="rangeInputDeposit{{i}}" (change)="rangeInputDepositChange(i,item);" type="range" value="{{item.min_deposit}}" min="{{item.min_deposit}}" max="{{balance}}" step="1">
                                                                <input hidden name="number_invest" value="{{item.id}}">
                                                            </label>
                                                </div>
                                            </fieldset>
                                            <div class="uk-grid uk-grid-small uk-text-large" data-uk-grid="">
                                                <div class="uk-width-expand uk-text-center">
                                                    <span class="uk-text-emphasis ">Описание выбранного инвестиционного плана</span>
                                                </div>
                                            </div>
                                            <div class="uk-grid-2" uk-grid>
                                                <div class="uk-width-1-3@s uk-width-1-1">
                                                    <div class="uk-card uk-card-default uk-card-body uk-text-middle" style="border-right: 1px solid #dadada;">
                                                        <p><b><span class="uk-text-large" style="color:#2f80ed;">{{item.name}} <span id="percent{{i}}">{{item.percent}}</span>%</span> в сутки</b></p>
                                                        <p>Срок контракта: <span id="days{{i}}">{{item.count_days}}</span> дней</p>
                                                        <p>Одноразовый депозит не требующий верификаци</p>
                                                    </div>
                                                </div>

                                                <div class="uk-width-1-3@s uk-width-1-1">
                                                    <div class="uk-card uk-card-default uk-card-body uk-text-middle" style="border-right: 1px solid #dadada;">
                                                        <p><b><span class="uk-text-large" style="color:#2f80ed;">
                                                            
                                                            <ng-container *ngIf="valute=='ruble'">
                                                                Р <span id="day_price{{i}}">{{(item.min_deposit/100*item.percent * kurs_usd_rub).toFixed()}}</span>       
                                                            </ng-container>
                                                    <ng-container *ngIf="valute=='dollars'">$ <span id="day_price{{i}}">{{(item.min_deposit/100*item.percent).toFixed()}}</span></ng-container>
                                                            </span></b><br> Ваша прибыль в <span style="color:#2f80ed;">день</span>
                                                        </p>
                                                        <p><b><span class="uk-text-large" style="color:#2f80ed;">
                                                            
                                                            <ng-container *ngIf="valute=='ruble'">
                                                                <span id="term_price{{i}}"> {{(item.min_deposit/100*item.percent*item.count_days * kurs_usd_rub).toFixed()}}Р</span>
                                                               </ng-container>
                                                            <ng-container *ngIf="valute=='dollars'">
                                                                <span id="term_price{{i}}">
                                                                    {{(item.min_deposit/100*item.percent*item.count_days).toFixed()}}$</span></ng-container>
                                                            </span></b><br> Ваша прибыль за <span style="color:#2f80ed;">{{item.count_days}} дней</span>
                                                        </p>
                                                        <!--p><b><span class="uk-text-large" style="color:#2f80ed;">$ 42.00</span></b><br>
                                                                Ваша прибыль за <span style="color:#2f80ed;">весь период</span>
                                                            </p-->
                                                    </div>
                                                </div>
                                                <div class="uk-width-1-3@s uk-width-1-1">
                                                    <div class="uk-card uk-card-default uk-card-body uk-text-middle">
                                                        <p>Прибыль по выбранному депозиту начисляется с частотой: один раз в 1 день. Фондовый сбор равен 1%. Автоматически списывается за управление активами.</p>
                                                        <ng-container *ngIf="balance>item.min_deposit">
                                                            <button class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" style="border-radius: 50px " (click)="pay_serv(item.id,'deposits',i)">Инвестировать</button>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </ng-container>

                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="uk-hidden@s">
                    <hr class="uk-divider-icon">
                </div>
                <div class="uk-width-1-4@s uk-width-1-1">


                    <ng-container *ngFor="let item of deposit_items; let i = index">
                        <ng-container *ngIf="i==0">


                            <div id="btndeposit{{i}}" (click)="deposit_investing_change(i)" class="uk-card uk-card-small uk-card-primary uk-card-body uk-margin-small-bottom uk-card-hover
                    uk-text-small" style="border-radius: 10px">
                                <h3 class="uk-card-title">{{item.name}}
                                    <p class="uk-float-right">{{item.percent}}%</p>
                                    <p class="uk-text-small">
                                        <ng-container *ngIf="valute=='ruble'">
                                            Min {{(item.min_deposit*kurs_usd_rub).toFixed()}}Р-Max {{(item.max_deposit*kurs_usd_rub).toFixed()}}Р
                                        </ng-container>
                                        <ng-container *ngIf="valute=='dollars'">Min {{item.min_deposit.toFixed()}}$-Max {{item.max_deposit.toFixed()}}$</ng-container>
                                        <br> Срок контракта: {{item.count_days}} дней
                                    </p>
                                </h3>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="i!=0">
                            <div id="btndeposit{{i}}" (click)="deposit_investing_change(i)" class="uk-card uk-card-small uk-card-default uk-card-body uk-margin-small-bottom uk-card-hover
                            uk-text-small" style="border-radius: 10px; border: 2px solid blue;">
                                <h3 class="uk-card-title">{{item.name}}
                                    <p class="uk-float-right">{{item.percent}}%</p>
                                    <p class="uk-text-small">
                                        <ng-container *ngIf="valute=='ruble'">
                                            Min {{(item.min_deposit*kurs_usd_rub).toFixed()}}Р-Max {{(item.max_deposit*kurs_usd_rub).toFixed()}}Р
                                        </ng-container>
                                        <ng-container *ngIf="valute=='dollars'">Min {{item.min_deposit.toFixed()}}$-Max {{item.max_deposit.toFixed()}}$</ng-container>
                                        <br> Срок контракта: {{item.count_days}} дней
                                    </p>
                                </h3>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>

                <div class="uk-width-1-1 uk-margin-top">
                    <div class="uk-card uk-card-small uk-card-default uk-card-body uk-margin-small-bottom uk-card-hover
                    uk-text-small">
                        <div class="uk-card-header">
                            <div class="uk-grid uk-grid-small uk-text-large" data-uk-grid="">
                                <div class="uk-width-expand uk-text-center">
                                    <span class="uk-text-emphasis ">Ваши вклады</span>
                                </div>
                            </div>
                        </div>

                        <div class="uk-card-body">

                            <div style="overflow-x:auto;">
                                <table class="uk-table uk-table-middle uk-text-bold  uk-table-divider">
                                    <thead class="uk-text-bold">
                                        <tr>
                                            <th>Тип вклада</th>
                                            <th>Дата</th>
                                            <th>Сумма</th>
                                            <th>Прибыль</th>
                                            <th>Номер транзакции</th>
                                            <th>Статус</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let item_table of deposit_table_list">

                                            <tr *ngFor="let item of item_table[0]">
                                                <td>{{item_table[1].name}}</td>
                                                <td>{{item.created_at}}</td>
                                                <td>
                                                    <ng-container *ngIf="valute=='ruble'">{{(item.summa * kurs_usd_rub).toFixed()}}Р</ng-container>
                                                    <ng-container *ngIf="valute=='dollars'">{{(item.summa).toFixed()}}</ng-container>

                                                </td>
                                                <td style="color:green;">
                                                    <ng-container *ngIf="valute=='ruble'">{{(item.profit * kurs_usd_rub).toFixed()}}Р</ng-container>
                                                    <ng-container *ngIf="valute=='dollars'">{{(item.profit).toFixed()}}</ng-container>

                                                </td>
                                                <td>{{item.id}}</td>
                                                <ng-container *ngIf="item.status=='Обрабатывается'">
                                                    <td style="color:blue">{{item.status}}</td>
                                                </ng-container>
                                                <ng-container *ngIf="item.status=='Выполнено'">
                                                    <td style="color:red">{{item.status}}</td>
                                                </ng-container>

                                            </tr>


                                        </ng-container>







                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </li>
        <li [ngClass]="{'uk-active': item_uk_switcher==2}">
            <div class="uk-grid">
                <div class="uk-width-3-4@s uk-width-1-1">

                    <div class="uk-card uk-card-small uk-card-default">
                        <div class="uk-card-header">
                            <div class="uk-grid uk-grid-small uk-text-large" data-uk-grid="">
                                <div class="uk-width-expand uk-text-center">
                                    <span class="uk-text-emphasis ">Выберите инвестиционный план</span>
                                </div>
                            </div>
                        </div>
                        <div class="uk-card-body">

                            <ng-container *ngFor="let item of robots_items; let i = index">
                                <ng-container *ngIf="i==0">
                                    <div id="datarobot{{i}}">
                                        <form method="post">
                                            <fieldset class="uk-fieldset">
                                                <legend class="uk-legend uk-flex-center">Сумма инвестиции <span class="rangeInputRobotsShow{{i}}">
                                                          
                                            <ng-container *ngIf="valute=='ruble'">{{(item.min_deposit*kurs_usd_rub).toFixed()}}</ng-container>
                                            <ng-container *ngIf="valute=='dollars'">{{(item.min_deposit).toFixed()}}</ng-container>
</span>
                                                    <ng-container *ngIf="valute=='ruble'">Р</ng-container>
                                                    <ng-container *ngIf="valute=='dollars'">$</ng-container>

                                                </legend>
                                                <div class="uk-margin">
                                                    <label class="pure-material-slider">
                                                        
                                                        <input name="rangeInputRobots" id="rangeInputRobots{{i}}" (change)="rangeInputRobotsChange(i,item);" type="range" value="{{item.min_deposit}}" min="{{item.min_deposit}}" max="{{balance}}" step="1">
                                                        <input hidden name="number_invest" value="{{item.id}}">
                                                    </label>
                                                </div>
                                            </fieldset>
                                            <div class="uk-grid uk-grid-small uk-text-large" data-uk-grid="">
                                                <div class="uk-width-expand uk-text-center">
                                                    <span class="uk-text-emphasis ">Описание выбранного инвестиционного плана</span>
                                                </div>
                                            </div>
                                            <div class="uk-grid-2" uk-grid>
                                                <div class="uk-width-1-3@s uk-width-1-1">
                                                    <div class="uk-card uk-card-default uk-card-body uk-text-middle" style="border-right: 1px solid #dadada;">
                                                        <p><b><span class="uk-text-large" style="color:#2f80ed;">{{item.name}} <span id="percent{{i}}">{{item.percent}}</span>%</span> в сутки</b></p>
                                                        <p>Срок контракта: <span id="days{{i}}">{{item.count_days}}</span> дней</p>
                                                        <p>Одноразовый депозит не требующий верификаци</p>
                                                    </div>
                                                </div>

                                                <div class="uk-width-1-3@s uk-width-1-1">
                                                    <div class="uk-card uk-card-default uk-card-body uk-text-middle" style="border-right: 1px solid #dadada;">
                                                        <p><b><span class="uk-text-large" style="color:#2f80ed;">
                                                    
                                                    <ng-container *ngIf="valute=='ruble'">
                                                       <span id="day_price_robots{{i}}">{{(item.min_deposit/100*item.percent * kurs_usd_rub).toFixed()}}</span> Р     
                                                    </ng-container>
                                            <ng-container *ngIf="valute=='dollars'"><span id="day_price_robots{{i}}">{{(item.min_deposit/100*item.percent).toFixed()}}</span>$ </ng-container>
                                                    </span></b><br> Ваша прибыль в <span style="color:#2f80ed;">день</span>
                                                        </p>
                                                        <p><b><span class="uk-text-large" style="color:#2f80ed;">
                                                          

                                                    <ng-container *ngIf="valute=='ruble'">  <span id="term_price_robots{{i}}">{{(item.min_deposit/100*item.percent*item.count_days * kurs_usd_rub).toFixed()}}Р</span></ng-container>
                                                    <ng-container *ngIf="valute=='dollars'">  <span id="term_price_robots{{i}}">{{(item.min_deposit/100*item.percent*item.count_days).toFixed()}}$</span></ng-container>
                                                    </span></b><br> Ваша прибыль за <span style="color:#2f80ed;">{{item.count_days}} дней</span>
                                                        </p>
                                                        <!--p><b><span class="uk-text-large" style="color:#2f80ed;">$ 42.00</span></b><br>
                                                        Ваша прибыль за <span style="color:#2f80ed;">весь период</span>
                                                    </p-->
                                                    </div>
                                                </div>
                                                <div class="uk-width-1-3@s uk-width-1-1">
                                                    <div class="uk-card uk-card-default uk-card-body uk-text-middle">
                                                        <p>Прибыль по выбранному депозиту начисляется с частотой: один раз в 1 день. Фондовый сбор равен 1%. Автоматически списывается за управление активами.</p>
                                                        <ng-container *ngIf="balance>item.min_deposit">
                                                            <button class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" style="border-radius: 50px " (click)="pay_serv(item.id,'robots',i)">Инвестировать</button>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="i!=0">
                                    <div id="datarobot{{i}}" style="display: none">
                                        <form method="post">
                                            <fieldset class="uk-fieldset">
                                                <legend class="uk-legend uk-flex-center">Сумма инвестиции <span class="rangeInputRobotsShow{{i}}">
                                                          
                                            <ng-container *ngIf="valute=='ruble'">{{(item.min_deposit*kurs_usd_rub).toFixed()}}</ng-container>
                                            <ng-container *ngIf="valute=='dollars'">{{(item.min_deposit).toFixed()}}</ng-container>
</span>
                                                    <ng-container *ngIf="valute=='ruble'">Р</ng-container>
                                                    <ng-container *ngIf="valute=='dollars'">$</ng-container>

                                                </legend>
                                                <div class="uk-margin">
                                                    <label class="pure-material-slider">
                                                        
                                                        <input name="rangeInputRobots" id="rangeInputRobots{{i}}" (change)="rangeInputRobotsChange(i,item);" type="range" value="{{item.min_deposit}}" min="{{item.min_deposit}}" max="{{balance}}" step="1">
                                                        <input hidden name="number_invest" value="{{item.id}}">
                                                    </label>
                                                </div>
                                            </fieldset>
                                            <div class="uk-grid uk-grid-small uk-text-large" data-uk-grid="">
                                                <div class="uk-width-expand uk-text-center">
                                                    <span class="uk-text-emphasis ">Описание выбранного инвестиционного плана</span>
                                                </div>
                                            </div>
                                            <div class="uk-grid-2" uk-grid>
                                                <div class="uk-width-1-3@s uk-width-1-1">
                                                    <div class="uk-card uk-card-default uk-card-body uk-text-middle" style="border-right: 1px solid #dadada;">
                                                        <p><b><span class="uk-text-large" style="color:#2f80ed;">{{item.name}} <span id="percent{{i}}">{{item.percent}}</span>%</span> в сутки</b></p>
                                                        <p>Срок контракта: <span id="days{{i}}">{{item.count_days}}</span> дней</p>
                                                        <p>Одноразовый депозит не требующий верификаци</p>
                                                    </div>
                                                </div>

                                                <div class="uk-width-1-3@s uk-width-1-1">
                                                    <div class="uk-card uk-card-default uk-card-body uk-text-middle" style="border-right: 1px solid #dadada;">
                                                        <p><b><span class="uk-text-large" style="color:#2f80ed;">
                                                    
                                                    <ng-container *ngIf="valute=='ruble'">
                                                       <span id="day_price_robots{{i}}">{{(item.min_deposit/100*item.percent * kurs_usd_rub).toFixed()}}</span> Р     
                                                    </ng-container>
                                            <ng-container *ngIf="valute=='dollars'"><span id="day_price_robots{{i}}">{{(item.min_deposit/100*item.percent).toFixed()}}</span>$ </ng-container>
                                                    </span></b><br> Ваша прибыль в <span style="color:#2f80ed;">день</span>
                                                        </p>
                                                        <p><b><span class="uk-text-large" style="color:#2f80ed;">
                                                            <ng-container *ngIf="valute=='ruble'">  <span id="term_price_robots{{i}}">{{(item.min_deposit/100*item.percent*item.count_days * kurs_usd_rub).toFixed()}}</span></ng-container>
                                                            <ng-container *ngIf="valute=='dollars'">  <span id="term_price_robots{{i}}">{{(item.min_deposit/100*item.percent*item.count_days).toFixed()}}</span></ng-container>
                                                             </span></b><br> Ваша прибыль за <span style="color:#2f80ed;">{{item.count_days}} дней</span>
                                                        </p>
                                                        <!--p><b><span class="uk-text-large" style="color:#2f80ed;">$ 42.00</span></b><br>
                                                        Ваша прибыль за <span style="color:#2f80ed;">весь период</span>
                                                    </p-->
                                                    </div>
                                                </div>
                                                <div class="uk-width-1-3@s uk-width-1-1">
                                                    <div class="uk-card uk-card-default uk-card-body uk-text-middle">
                                                        <p>Прибыль по выбранному депозиту начисляется с частотой: один раз в 1 день. Фондовый сбор равен 1%. Автоматически списывается за управление активами.</p>
                                                        <ng-container *ngIf="balance>item.min_deposit">
                                                            <button class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" style="border-radius: 50px " (click)="pay_serv(item.id,'robots',i)">Инвестировать</button>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </ng-container>

                            </ng-container>
                        </div>
                    </div>

                </div>
                <div class="uk-hidden@s">
                    <hr class="uk-divider-icon">
                </div>
                <div class="uk-width-1-4@s uk-width-1-1">


                    <ng-container *ngFor="let item of deposit_items; let i = index">
                        <ng-container *ngIf="i==0">


                            <div id="btnrobots{{i}}" (click)="robots_investing_change(i)" class="uk-card uk-card-small uk-card-primary uk-card-body uk-margin-small-bottom uk-card-hover
                uk-text-small" style="border-radius: 10px">
                                <h3 class="uk-card-title">{{item.name}}
                                    <p class="uk-float-right">{{item.percent}}%</p>
                                    <p class="uk-text-small">
                                        <ng-container *ngIf="valute=='ruble'">
                                            Min {{(item.min_deposit*kurs_usd_rub).toFixed()}}Р-Max {{(item.max_deposit*kurs_usd_rub).toFixed()}}Р
                                        </ng-container>
                                        <ng-container *ngIf="valute=='dollars'">Min {{item.min_deposit.toFixed()}}$-Max {{item.max_deposit.toFixed()}}$</ng-container>
                                        <br> Срок контракта: {{item.count_days}} дней
                                    </p>
                                </h3>

                            </div>
                        </ng-container>
                        <ng-container *ngIf="i!=0">
                            <div id="btnrobots{{i}}" (click)="robots_investing_change(i)" class="uk-card uk-card-small uk-card-default uk-card-body uk-margin-small-bottom uk-card-hover
                        uk-text-small" style="border-radius: 10px; border: 2px solid blue;">
                                <h3 class="uk-card-title">{{item.name}}
                                    <p class="uk-float-right">{{item.percent}}%</p>
                                    <p class="uk-text-small">
                                        <ng-container *ngIf="valute=='ruble'">
                                            Min {{(item.min_deposit*kurs_usd_rub).toFixed()}}Р-Max {{(item.max_deposit*kurs_usd_rub).toFixed()}}Р
                                        </ng-container>
                                        <ng-container *ngIf="valute=='dollars'">Min {{item.min_deposit.toFixed()}}$-Max {{item.max_deposit.toFixed()}}$</ng-container>
                                        <br> Срок контракта: {{item.count_days}} дней
                                    </p>
                                </h3>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>

                <div class="uk-width-1-1 uk-margin-top">
                    <div class="uk-card uk-card-small uk-card-default uk-card-body uk-margin-small-bottom uk-card-hover
                    uk-text-small">
                        <div class="uk-card-header">
                            <div class="uk-grid uk-grid-small uk-text-large" data-uk-grid="">
                                <div class="uk-width-expand uk-text-center">
                                    <span class="uk-text-emphasis ">Ваши вклады</span>
                                </div>
                            </div>
                        </div>

                        <div class="uk-card-body">

                            <div style="overflow-x:auto;">
                                <table class="uk-table uk-table-middle uk-text-bold  uk-table-divider">
                                    <thead class="uk-text-bold">
                                        <tr>
                                            <th>Тип вклада</th>
                                            <th>Дата</th>
                                            <th>Сумма</th>
                                            <th>Прибыль</th>
                                            <th>Номер транзакции</th>
                                            <th>Статус</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let item_table of robots_table_list">

                                            <tr *ngFor="let item of item_table[0]">
                                                <td>{{item_table[1].name}}</td>
                                                <td>{{item.created_at}}</td>
                                                <td>
                                                    <ng-container *ngIf="valute=='ruble'">{{(item.summa * kurs_usd_rub).toFixed()}}Р</ng-container>
                                                    <ng-container *ngIf="valute=='dollars'">{{(item.summa).toFixed()}}</ng-container>

                                                </td>
                                                <td style="color:green;">
                                                    <ng-container *ngIf="valute=='ruble'">{{(item.profit * kurs_usd_rub).toFixed()}}Р</ng-container>
                                                    <ng-container *ngIf="valute=='dollars'">{{(item.profit).toFixed()}}</ng-container>

                                                </td>
                                                <td>{{item.id}}</td>
                                                <ng-container *ngIf="item.status=='Обрабатывается'">
                                                    <td style="color:blue">{{item.status}}</td>
                                                </ng-container>
                                                <ng-container *ngIf="item.status=='Выполнено'">
                                                    <td style="color:red">{{item.status}}</td>
                                                </ng-container>

                                            </tr>


                                        </ng-container>

                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>


            </div>
        </li>
    </ul>

</div>