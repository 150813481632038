import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.sass']
})
export class RulesComponent implements OnInit,OnDestroy,AfterViewInit {

  constructor() { }

  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    $('body').removeClass('blogSingle-page');
  }
  ngAfterViewInit(): void {
    $('body').addClass('blogSingle-page');

  }
}
