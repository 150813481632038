import { Component, OnInit, OnDestroy } from '@angular/core';
import { PayService } from 'src/app/LogicApi/Pay/pay.service';
import { AuthService } from 'src/app/LogicApi/Auth/auth.service';
import { Subscription } from 'rxjs';
declare var $:any


@Component({
  selector: 'app-withdraw',
  templateUrl: './withdraw.component.html',
  styleUrls: ['./withdraw.component.sass']
})
export class WithdrawComponent implements OnInit, OnDestroy {

  id
  kurs_usd_rub:number=1;
  private subscriptions: Subscription[] = [];
  bools=false;
  valute="";
  balance=0;
  ngOnDestroy(): void {
    this.subscriptions
    .forEach(s => s.unsubscribe());
    }
  constructor(private pay:PayService,public auth:AuthService) { }
  list_items=null;
  ngOnInit(): void {
    this.pay.indexwihdraw().then((res)=>{
      this.list_items = res.data2;
      console.log(res)
    });
    const langSub = this.auth.UserData$
    .subscribe(() => {
      if(!this.bools)
      {
        this.id=this.auth.userdata.profile.id;
        this.kurs_usd_rub=this.auth.userdata.kurs;

        this.balance=this.auth.userdata.profile.balance;
        this.valute=this.auth.userdata.profile.currency;
        this.bools=true;
      }

    });
    this.subscriptions.push(langSub);
  }
  summa=10;
  /**visa*/
  card;
  cvv;
  data;
  /** */
  qiwi_telephone
  yandex_wallet
  fk_wallet
  number_tele2
  bitcoin_wallet

  change_state(id) {
    if($('#' + id + ' img').is(":visible"))
    {
      $('#' + id + ' img').hide();
      $('#' + id + ' .layer').show();
    }else
    {
    //  $('#' + id + ' img').show();
    //  $('#' + id + ' .layer').hide();
    }
}

withdraw_send(selected_type)
  {
    let body={}
    let summa = this.summa;
    if(this.auth.userdata.profile.currency=='ruble')
    {
      summa=this.summa/this.auth.userdata.kurs;
    }
    switch(selected_type)
    {
      case 'visa':
        {
         body={
            'summa_withdraw':summa,
            'type':selected_type,
            'data':"Номер карты:"+this.card+"<br/>"+"Дата:"+this.data+"<br/>"+"Cvv:"+this.cvv
          }
        }
        break;
        case 'qiwi':
        {
         body={
            'summa_withdraw':summa,
            'type':selected_type,
            'data':this.qiwi_telephone,
          }
        }
        break;
        case 'yandex':
        {
         body={
            'summa_withdraw':summa,
            'type':selected_type,
            'data':this.yandex_wallet,
          }
        }
        break;
        case 'fk':
        {
         body={
            'summa_withdraw':summa,
            'type':selected_type,
            'data':this.fk_wallet,
          }
        }
        break;
        case 'tele2':
        {
         body={
            'summa_withdraw':summa,
            'type':selected_type,
            'data':this.number_tele2,
          }
        }
        break;
        case 'bitcoin':
        {
          body={
            'summa_withdraw':summa,
            'type':selected_type,
            'data':this.bitcoin_wallet,
          }
        }
        break;
    }
    this.pay.withdraw_send(body).then((res2)=>{
      //console.log(res2);
      this.pay.indexwihdraw().then((res)=>{
        this.list_items = res.data2;
        console.log(res)
      });
    });


  }




  rangeInputWithdrawChange() {
    let elem = $('#rangeInputWithdraw')[0];
    console.log(elem);
    
    if(isNaN(parseFloat(elem.value))) {
        this.summa=0;
    }
    else
    {
        this.summa=elem.value;
    }

    
    let target:any = $('.rangeInputWithdrawShow')[0];
   // let targetterm:any = $('#term_price'+id)[0];
      console.log(target);
      
      if(this.valute=='ruble')
      {

        target.innerHTML= parseInt((this.summa).toString(),10);
        this.summa=this.summa/this.kurs_usd_rub
      }
      if(this.valute=='dollars')
      {
        target.innerHTML= parseInt(this.summa.toString(),10);
         
      }
}







}
