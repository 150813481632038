import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { SiteRoutingModule } from './site-routing.module';
import { SiteComponent } from './site.component';
import { AboutModule } from './about/about.module';
import { BusinessModelModule } from './business-model/business-model.module';
import { ContactsModule } from './contacts/contacts.module';
import { FaqModule } from './faq/faq.module';
import { IndexModule } from './index/index.module';
import { RulesModule } from './rules/rules.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [SiteComponent],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    SiteRoutingModule,
    AboutModule,
    BusinessModelModule,
    ContactsModule,
    FaqModule,
    IndexModule,
    RulesModule
  ]
})
export class SiteModule { }
