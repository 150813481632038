import { Observable, BehaviorSubject, throwError, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { AuthService } from '../LogicApi/Auth/auth.service';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })
  export class GuardsService {

constructor(private authService:AuthService){}
private isRefreshing = false;
private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.getJwtToken()) {
      request = this.addToken(request, this.authService.getJwtToken());
    }
    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
          this.authService.logout();
      } else {
        return throwError(error);
      }
      return next.handle(request);
    }));

  }


  data: Observable<HttpEvent<any>>;
private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
 {
  if (!this.isRefreshing) {
    this.isRefreshing = true;
    this.refreshTokenSubject.next(null);

     this.data = this.authService.refreshToken().pipe(
     (token: any) => {
        this.isRefreshing = false;
        this.refreshTokenSubject.next(token);
        return next.handle(this.addToken(request, token));
      });
      return this.data;

  } else {
   /* return this.refreshTokenSubject.toPromise().then(
        (result: any) =>{
            if (result != null) {
                return next.handle(this.addToken(request, result));
            }
            return next.handle(request)
        }),
        (error:any)=>{
          return next.handle(request)
        }*/
        return this.refreshTokenSubject.pipe(
          (res:any)=>{
            return next.handle(this.addToken(request,res));
        },
        (err:any)=>{
          return next.handle(request);
        })
  }
}
private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }
}