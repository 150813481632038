import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthHeaderModule } from 'src/app/components/auth-header/auth-header.module';
import { LostPasswordComponent } from './lost-password.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [LostPasswordComponent],
  imports: [
    CommonModule,AuthHeaderModule,RouterModule,
  ]
})
export class LostPasswordModule { }
