import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'src/app/Service/config.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient,
    private config: ConfigService) { }

    public post(href,body)
    {
      let headerOptions = new HttpHeaders();
      headerOptions.append('Access-Control-Allow-Origin', '*');
      headerOptions.append('Access-Control-Request-Headers', '*');
      headerOptions.append('Content-Type', 'application/json');
      headerOptions.append('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,PATCH,OPTIONS');
      //let credentials = JSON.stringify(body);
      console.log(this.config.urlApi+ href);
      console.log(body);
      
      return this.http.post(this.config.urlApi+ href, body, {
      headers: headerOptions,
      responseType:"json"
    })

    }
}
