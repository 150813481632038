import { Component, OnInit, AfterContentInit, OnDestroy } from '@angular/core';
import { InvestService } from 'src/app/LogicApi/Invest/invest.service';
import { ProfileService } from 'src/app/LogicApi/Profile/profile.service';
import { switchMap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
declare var $:any
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.sass']
})
export class ProfileComponent implements OnInit,AfterContentInit,OnDestroy {

  constructor(private profile:ProfileService,private invest:InvestService, private route: ActivatedRoute) { }
      id: number;

  ngOnDestroy(): void {

    }
  ngAfterContentInit(): void {
    this.files = [];
  }


  deposit_items=null;
  robots_items=null;
  summa=0;
  deposit_table_list=null
  robots_table_list=null;

  type=1;//1-5

  email=""
  family=""
  name=""
  thirty_name=""
  data=""
  telephone=""
  balance
  valute=""
  photo=""
  created_at

  lost_password
  newpassword
  refreshpassword
  kurs_usd_rub=1;
  public files: any[];

  passport=null
  photo_with_passport=null
  address=null

  passport_tag
  photo_with_passport_tag
  address_tag

  update_data()
  {
    this.profile.admin_open_profile_user(this.id).then((res:any)=>{
      if(res!=null)
      {


        this.address=res.address;
        this.address_tag=res.address_tag;

        this.deposit_items=res.list_deposit_type;
        this.robots_items=res.list_torg_robots_type;
        this.deposit_table_list=res.deposit_table_list;
        this.robots_table_list=res.robots_table_list;
        this.kurs_usd_rub=res.kurs_usd_rub;


        this.passport_tag=res.passport_tag;
        this.passport=res.passport;
        this.photo_with_passport=res.photo_with_passport;
        this.photo_with_passport_tag=res.photo_with_passport_tag;

        this.email=res.user.email;
        this.family=res.user.family;
        this.name=res.user.name;
        this.thirty_name=res.user.middlename;
        this.data=res.user.birthday;
        this.telephone=res.user.telephone;
        this.balance=res.user.balance;
        this.created_at=res.user.created_at;

        this.valute=res.user.currency;
        this.photo='/cabinet/profile/get_avatar/'+this.id

          console.log(res);

      }
    })
  }
  ngOnInit(): void {

    this.route.paramMap.pipe(
      switchMap(params => params.getAll('id'))
  )
  .subscribe(data=> {
    this.id = +data


    this.update_data();

  });


    $('.datatable').DataTable({
      responsive: true
    });

  }
  change_link(type)
  {
    $('#item'+this.type).removeClass('text-theme-1');
    $('#item'+this.type).removeClass('font-medium');
    this.type=type;
    $('#item'+this.type).addClass('text-theme-1');
    $('#item'+this.type).addClass('font-medium');
  }
  
  openfile(id)
  {
    $('#'+id).click();
  }

  personal_save()
  {
    this.profile.profile_data_update(this.email,this.name,this.family,this.thirty_name,this.data,this.telephone,
      this.valute,this.id,this.balance);
  }
  change_password()
  {
      if(this.newpassword==this.refreshpassword)
      this.profile.update_password(this.lost_password,this.newpassword,this.id);
  }

  onFileChanged(event: any) {
    this.files = event.target.files;
    this.profile.ImageUploadPost(this.files,this.id)
  }
  ProfileAddressUploadPost(event: any) {
    this.profile.ProfileAddressUploadPost(event.target.files,this.id)
  }
  ProfilePassportUploadPost(event: any) {
    this.profile.ProfilePassportUploadPost(event.target.files,this.id)
  }
  ProfilePhotoWithPassportUploadPost(event: any) {
    this.profile.ProfilePhotoWithPassportUploadPost(event.target.files,this.id)
  }
  cancel_deposit(id)
  {
    this.invest.admin_profile_user_cancel_deposit(id).then((res)=>{
      this.update_data();
    })

  }

  item_uk_switcher=1;
  change_menu(id)
  {
    console.log(id);
    this.item_uk_switcher=id;
  }
}
