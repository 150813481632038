import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/Service/http.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private request:HttpService) { }

  public async load_settings() {
    let body = {};
    var result = await this.request.post('/cabinet/admin',body).toPromise().then(
        (res:any)=>{
          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result;
  }
  public async list_users_Get() {
    let body = {};
    var result = await this.request.post('/cabinet/admin/users',body).toPromise().then(
        (res:any)=>{
          console.log(res);
          
          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result;
  }
  public async list_users_search(text) {
    let body = {text};
    var result = await this.request.post('/cabinet/admin/users/search',body).toPromise().then(
        (res:any)=>{
          console.log(res);
          
          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result;
  }
  public async settings_kurs_Post() {
    let body = {};
    var result = await this.request.post('/cabinet/admin/settings/kurs_post',body).toPromise().then(
        (res:any)=>{
          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result;
  }
  public async settings_poisk_Post() {
    let body = {};
    var result = await this.request.post('/cabinet/admin/settings/poisk_post',body).toPromise().then(
        (res:any)=>{
          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result;
  }
  public async settings_Post() {
    let body = {};
    var result = await this.request.post('/cabinet/admin/settings_post',body).toPromise().then(
        (res:any)=>{
          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result;
  }

  public async statistics_Post() {
    let body = {};
    var result = await this.request.post('/cabinet/admin/statistics',body).toPromise().then(
        (res:any)=>{
          //console.log(res);

          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result;
  }
}
