import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuysellComponent } from './buysell.component';
import { BuysellRoutingModule } from './buysell-routing';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [BuysellComponent],
  imports: [
    CommonModule,FormsModule,BuysellRoutingModule
  ],
  bootstrap:[BuysellComponent]
})
export class BuysellModule { }
