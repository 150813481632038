import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin-routing';
import { UsersComponent } from './users/users.component';
import { InvestsettingsModule } from './investsettings/investsettings.module';
import { ProfileModule } from './profile/profile.module';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { PayModule } from './pay/pay.module';
import { UsersModule } from './users/users.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,AdminRoutingModule,RouterModule,FormsModule,
    InvestsettingsModule,ProfileModule,
    PayModule,UsersModule
    ]
})
export class AdminModule { }
