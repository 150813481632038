import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-business-model',
  templateUrl: './business-model.component.html',
  styleUrls: ['./business-model.component.sass']
})
export class BusinessModelComponent implements OnInit,OnDestroy,AfterViewInit {

  constructor() { }

  
  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    $('body').removeClass('partner-page');
  }
  ngAfterViewInit(): void {
    $('body').addClass('partner-page');

  }

}
