import { OnInit, AfterViewInit, OnDestroy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/LogicApi/Auth/auth.service';

declare var $:any;
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.sass']
})
export class RegisterComponent implements OnInit, AfterViewInit,OnDestroy {

  constructor(private auth:AuthService,private router:Router) { }
  name: string
  family: string
  telephone: string
  email: string
  password: string 
  refreshpassword:string;
  password_level=0;
  rules:boolean=false; 
  
  
  private loadExternalStyles(styleUrl: string) {
    return new Promise((resolve, reject) => {
      const styleElement = document.createElement('link');
      styleElement.href = styleUrl;
      styleElement.rel="stylesheet";
      styleElement.onload = resolve;
      document.getElementById('elem').appendChild(styleElement);
    });
  }


  ngOnInit(): void {

  }
  ngAfterViewInit(): void {
    this.loadExternalStyles('/assets/auth/css/app.css').then(() => {
      $('body').addClass('login');
    $('.container2').css('display','none');
      $('body').css('display','block');
      $('#load').css('display','block')
    }).catch(() => {
      $('body').addClass('login');
      $('.container2').css('display','none');
        $('body').css('display','block');
        $('#load').css('display','block')
    });

    
  }
  ngOnDestroy(): void {
    $('body').css('display','flex');
    $('#load').css('display','none');
    $('.container2').css('display','')
   $('body').removeClass('login');
  }
  async register()
  {
    if(this.password!=this.refreshpassword)
    {
      return;
    }
    if(!this.rules)
    {
      return;
    }
    if(this.name=="" || this.family=="" || this.telephone=="" || this.email=="" || this.password=="")
    {
      return;
    }
    $('body').css('display','flex');
    $('#load').css('display','none');
    $('.container2').css('display','')
    this.auth.register(this.name, this.family, this.telephone, this.email, this.password).then(
      (result)=>{
        if(result)
        {
           this.auth.login(this.email,this.password).then((result)=>{
            if(result)
            {
              $('body').css('display','flex');
              $('#load').css('display','none');
              $('.container2').css('display','')
             this.router.navigate(['/cabinet/']);
            }
            else
            {
              $('body').css('display','flex');
              $('#load').css('display','none');
              $('.container2').css('display','')
            }
          })
         /* $('body').css('display','flex');
          $('#load').css('display','none');
          $('.container2').css('display','')*/
         //this.router.navigate(['/cabinet/login']);
        }
        else
        {
          $('body').addClass('login');
          $('.container2').css('display','none');
            $('body').css('display','block');
            $('#load').css('display','block')
        }
      }
    )
  }

  update_password()
  {
   this.password_level= this.checkPasswords(this.password);
  } 
  

   checkPasswords(password) {
    var s_letters = "qwertyuiopasdfghjklzxcvbnm"; // Буквы в нижнем регистре
    var b_letters = "QWERTYUIOPLKJHGFDSAZXCVBNM"; // Буквы в верхнем регистре
    var digits = "0123456789"; // Цифры
    var specials = "!@#$%^&*()_-+=\|/.,:;[]{}"; // Спецсимволы
    var is_s = false; // Есть ли в пароле буквы в нижнем регистре
    var is_b = false; // Есть ли в пароле буквы в верхнем регистре
    var is_d = false; // Есть ли в пароле цифры
    var is_sp = false; // Есть ли в пароле спецсимволы
    for (var i = 0; i < password.length; i++) {
      /* Проверяем каждый символ пароля на принадлежность к тому или иному типу */
      if (!is_s && s_letters.indexOf(password[i]) != -1) is_s = true;
      else if (!is_b && b_letters.indexOf(password[i]) != -1) is_b = true;
      else if (!is_d && digits.indexOf(password[i]) != -1) is_d = true;
      else if (!is_sp && specials.indexOf(password[i]) != -1) is_sp = true;
    }
    var rating = 0;
    var text = 0;
    if (is_s) rating++; // Если в пароле есть символы в нижнем регистре, то увеличиваем рейтинг сложности
    if (is_b) rating++; // Если в пароле есть символы в верхнем регистре, то увеличиваем рейтинг сложности
    if (is_d) rating++; // Если в пароле есть цифры, то увеличиваем рейтинг сложности
    if (is_sp) rating++; // Если в пароле есть спецсимволы, то увеличиваем рейтинг сложности
    /* Далее идёт анализ длины пароля и полученного рейтинга, и на основании этого готовится текстовое описание сложности пароля */
    if (password.length < 6 && rating < 3) text = 1;
     if (password.length < 6 && rating >= 3) text = 2;
     if (password.length >= 8 && rating < 3) text = 2;
     if (password.length >= 8 && rating >= 3) text = 3;
     if (password.length >= 6 && rating == 1) text = 1;
     if (password.length >= 6 && rating > 1 && rating < 4) text = 2;
     if (password.length >= 6 && rating == 4) text = 3;
     if (password.length >= 8 && rating == 4) text = 4;

    return text; // Форму не отправляем
  }
}