import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/LogicApi/Admin/admin.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.sass']
})
export class UsersComponent implements OnInit {
  ngOnInit(): void {
  }
  item_uk_switcher=1;
  change_menu(id)
  {
    this.item_uk_switcher=id;
  }
}
