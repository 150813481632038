import { Component,AfterContentInit, OnDestroy, OnInit,  } from '@angular/core';
import { createChart,IChartApi } from 'lightweight-charts';
import { BuySellService } from 'src/app/LogicApi/buysell/buysell.service';
import { AuthService } from 'src/app/LogicApi/Auth/auth.service';
import { Subscription } from 'rxjs';
declare var $:any
@Component({
  selector: 'app-buysell',
  templateUrl: './buysell.component.html',
  styleUrls: ['./buysell.component.sass']
})
export class BuysellComponent implements AfterContentInit,OnDestroy,OnInit {


  width = 600;
  height = 300;
 toolTipWidth = 100;
 toolTipHeight = 80;
 toolTipMargin = 15;
 selected_pairs
 toolTip=null;
 areaSeriesAsk=null;
 areaSeriesBid=null;
 chart:IChartApi=null;
 container=null;
 pairs=null;

 interval=null;
 type_action="buy";
 private subscriptions: Subscription[] = [];
  table_data=null;
  balance;
  funds;
  error="";
  lot="0";
  pnc = 10;
  constructor( private buysell:BuySellService,private auth:AuthService) { }
  ngOnInit(): void {
    const langSub = this.auth.UserData$
    .subscribe(() => {
        this.balance=(this.auth.userdata.profile.balance).toFixed();
    });
  this.subscriptions.push(langSub);
  }
  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscriptions
    .forEach(s => s.unsubscribe());
  }
  ngAfterContentInit(): void {
    this.chart_init();
    this.buysell.get_pairs().then((res:any)=>
    {
      if(res!=null){
      this.pairs=res.pairs;
      console.log(this.pairs);
      this.selected_pairs=res.pairs[0].symbol;
      this.update_chart_data();
      this.interval=setInterval(()=>this.update_chart_data(),10000)
      }
    })
  }


  create()
  {
    this.error="";
    let tmp =parseFloat(this.lot)
    if(!tmp){
    this.error="Лот - это число";
  return
    }
    if(this.funds<tmp*100000)
     {
       this.error="Недостаточно средств на счету"
      return
    }
    this.buysell.create_order(this.selected_pairs,tmp,this.type_action).then((res:any)=>
    {
      //console.log(res);
      if(res!=null)
      {
        if(res.status=='success')
        {
          this.update_data();
        }
        else
        {
          this.error=res.msg;
        }
      }
    }

    )

    this.lot="0";
  }
  close(id)
  {
    this.buysell.close_order(id).then((res:any)=>{
      //console.log(res);
      this.update_data();
    })
  }
  update_data()
  {
    this.buysell.get_info_statistics().then((res:any)=>{
      //console.log(res);
      this.table_data=res.data;
      if(res.funds==null)
      {
        this.funds=this.balance;
      }
      else
      {
        this.funds=(this.balance+res.funds).toFixed(3)
      }
    })
    this.onResize(null);
  }





  onResize(event) {
    this.width=$('#chart').width()-20
    this.chart.resize(
      this.width,
	    this.height,
    );
  }

  change_pairs(event)
  {
   console.log('event'+event.target.value);
   this.buysell.get_data_pairs(this.selected_pairs).then((res:any)=>
   {
     if(res!=null && res.status=='success'){
    this.update_chart(res);
       //console.log(res);
       this.update_chart_data();
     }
   })
  }
  update_chart_data()
  {
    this.update_data();
    this.onResize(null)
   this.buysell.get_data_pairs(this.selected_pairs).then((res:any)=>
   {
     if(res!=null && res.status=='success'){
       this.update_chart(res);
       this.onResize(null)
     }
   })
  }





  chart_init()
  {
    this.container = document.getElementById('chart');

    this.chart = createChart(this.container, {
      width: this.width,
	      height: this.height,
        layout: {
          backgroundColor: '#100841',
          textColor: '#ffffff',
        },
        grid: {
          vertLines: {
            color: 'rgba(197, 203, 206, 0.4)',
          },
          horzLines: {
            color: 'rgba(197, 203, 206, 0.4)',
          },
        },
    timeScale: {

      rightOffset: 12,
      barSpacing: 3,
      fixLeftEdge: true,
      lockVisibleTimeRangeOnResize: true,
      rightBarStaysOnScroll: true,
      borderVisible: false,
          borderColor: 'rgba(197, 203, 206, 0.4)',
      visible: true,
      timeVisible: true,
      secondsVisible: false,

  },
  });

    this.chart.applyOptions({
          watermark: {
              color: 'rgba(11, 94, 29, 0.4)',
              visible: true,
              text: 'AirSolution Trading',
              fontSize: 24,
              horzAlign: 'left',
              vertAlign: 'bottom',
          },
      });
      this.areaSeriesAsk = this.chart.addAreaSeries({
        lineColor: 'red',
        lineWidth: 2,
      });
      this.areaSeriesBid = this.chart.addAreaSeries({
        topColor: 'rgba(67, 83, 254, 0.7)',
        bottomColor: 'rgba(67, 83, 254, 0.3)',
        lineColor: 'rgba(67, 83, 254, 1)',
        lineWidth: 2,
      });

    this.toolTip = document.createElement('div');
    this.toolTip.className = 'floating-tooltip-2';
    this.container.appendChild( this.toolTip);

    // update tooltip
    this.chart.subscribeCrosshairMove((param)=> this.update_tooltip(param,this));
    this.chart.timeScale().fitContent()

  }

  update_tooltip(param,t):any
  {
        if (!param.time || param.point.x < 0 || param.point.x > this.width || param.point.y < 0 || param.point.y > this.height) {
          t.toolTip.style.display = 'none';
      return;
    }

    var dateStr =  t.businessDayToString(param.time)

    t.toolTip.style.display = 'block';
    let priceask:any = param.seriesPrices.get(t.areaSeriesAsk);
    let pricebid:any = param.seriesPrices.get(t.areaSeriesBid);

    t.toolTip.innerHTML = '<div style="color: rgba(255, 70, 70, 1)">'+this.selected_pairs+'</div>' +
      '<div style="font-size: 12px; margin: 4px 0px"> Продажа' + Math.round(priceask * 100) / 100 + '<br/>Покупка'+
      Math.round(pricebid * 100) / 100 +
      '</div>' +
      '';

    var y = param.point.y;

    var left = param.point.x + t.toolTipMargin;
    if (left > t.width - t.toolTipWidth) {
      left = param.point.x - t.toolTipMargin - t.toolTipWidth;
    }

    var top = y + t.toolTipMargin;
    if (top > t.height - t.toolTipHeight) {
      top = y - t.toolTipHeight - t.toolTipMargin;
    }

    t.toolTip.style.left = left + 'px';
    t.toolTip.style.top = top + 'px';
  }
  update_chart(data)
  {
    this.areaSeriesAsk.setData(data.ask);
    this.areaSeriesBid.setData(data.bid);

  }

  businessDayToString(businessDay) {
    return businessDay.year + '-' + businessDay.month + '-' + businessDay.day;
  }
}
