import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Cabinet404errorComponent } from './cabinet404error.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [Cabinet404errorComponent],
  imports: [
    CommonModule,RouterModule,
  ]
})
export class Cabinet404errorModule { }
