import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/LogicApi/Auth/auth.service';
import { Subscription } from 'rxjs';
declare var $:any;


@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
})
export class financeComponent {
  item_uk_switcher=1;
  change_menu(id)
  {
    this.item_uk_switcher=id;
  }
}
