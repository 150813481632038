<div class="uk-container uk-container-center">
    <ul class="uk-tab" data-uk-tab="{connect:'#my-id'}">
        <li (click)="change_menu(1)">
            <a>
                <h4>Закрытые заявки снятия</h4>
            </a>
        </li>
        <li (click)="change_menu(2)">
            <a>
                <h4>Активные заявки снятия</h4>
            </a>
        </li>
        <li (click)="change_menu(3)">
            <a>
                <h4>Отклоненные заявки снятия</h4>
            </a>
        </li>

    </ul>
    <ul id="my-id" class="uk-switcher uk-margin">
        <li [ngClass]="{'uk-active': item_uk_switcher==1}">
            <div class="uk-grid">
                <div class="uk-width-1-1 uk-margin-top">
                    <div class="uk-card uk-card-small uk-card-default uk-card-body uk-margin-small-bottom uk-card-hover
                    uk-text-small">

                        <div class="uk-card-body">

                            <div style="overflow-x:auto;">
                                <table class="uk-table uk-table-middle uk-text-bold  uk-table-divider">
                                    <thead class="uk-text-bold">
                                        <tr>
                                            <th>Дата</th>
                                            <th>Сумма</th>
                                            <th>Платежная система</th>
                                            <th>Статус</th>
                                            <th>Номер транзакции</th>
                                            <th>Данные платежной системы</th>
                                            <th>Имя пользователя</th>
                                            <th>Фамилия пользователя</th>
                                            <th>Email пользователя</th>
                                            <th>Изменить статус</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let item of close_withdraw">
                                            <tr id="data{{item.profile.id}}">
                                                <td>{{item.profile.created_at}}</td>
                                                <td style="color: green">
                                                    <ng-container *ngIf="valute=='ruble'">{{(item.profile.summa * kurs_usd_rub).toFixed(2)}}Р</ng-container>
                                                    <ng-container *ngIf="valute=='dollars'">{{item.profile.summa.toFixed(2)}}$</ng-container>
                                                </td>
                                                <td>{{item.profile.type}}</td>
                                                <td style="color: green">{{item.profile.status}}</td>
                                                <td>{{item.profile.id}}</td>
                                                <td [innerHtml]="item.profile.data"></td>
                                                <td>
                                                    <ng-container *ngIf="item.user !=null && item.user.name!=null">
                                                        {{item.user.name}}
                                                    </ng-container>
                                                </td>
                                                <td>
                                                    <ng-container *ngIf="item.user !=null && item.user.family!=null">
                                                        {{item.user.family}}
                                                    </ng-container>
                                                </td>
                                                <td>
                                                    <ng-container *ngIf=" item.user !=null && item.user.email!=null">
                                                        {{item.user.email}}
                                                    </ng-container>
                                                </td>
                                                <td>
                                                    <form>
                                                        <div uk-form="target: > * > span:first-child">
                                                            <select (change)="changes(item.profile.id,$event);">
                                                                <option  value="В обработке">В обработке</option>
                                                                <option value="Отклонено">Отклонено</option>
                                                                <option selected value="Выведено">Выведено</option>
                                                            </select>

                                                        </div>
                                                    </form>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </li>
        <li [ngClass]="{'uk-active': item_uk_switcher==2}">
            <div class="uk-grid">
                <div class="uk-width-1-1 uk-margin-top">
                    <div class="uk-card uk-card-small uk-card-default uk-card-body uk-margin-small-bottom uk-card-hover
                    uk-text-small">

                        <div class="uk-card-body">

                            <div style="overflow-x:auto;">
                                <table class="uk-table uk-table-middle uk-text-bold  uk-table-divider">
                                    <thead class="uk-text-bold">
                                        <tr>
                                            <th>Дата</th>
                                            <th>Сумма</th>
                                            <th>Платежная система</th>
                                            <th>Статус</th>
                                            <th>Номер транзакции</th>
                                            <th>Данные платежной системы</th>
                                            <th>Имя пользователя</th>
                                            <th>Фамилия пользователя</th>
                                            <th>Email пользователя</th>
                                            <th>Изменить статус</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let item of active_withdraw">
                                            <tr id="data{{item.profile.id}}">
                                                <td>{{item.profile.created_at}}</td>
                                                <td style="color: green">
                                                    <ng-container *ngIf="valute=='ruble'">{{(item.profile.summa * kurs_usd_rub).toFixed(2)}}Р</ng-container>
                                                    <ng-container *ngIf="valute=='dollars'">{{item.profile.summa.toFixed(2)}}$</ng-container>
                                                </td>
                                                <td>{{item.profile.type}}</td>
                                                <td style="color: blue">{{item.profile.status}}</td>
                                                <td>{{item.profile.id}}</td>
                                                <td [innerHtml]="item.profile.data"></td>
                                                <td>
                                                    <ng-container *ngIf="item.user !=null && item.user.name!=null">
                                                        {{item.user.name}}
                                                    </ng-container>
                                                </td>
                                                <td>
                                                    <ng-container *ngIf="item.user !=null && item.user.family!=null">
                                                        {{item.user.family}}
                                                    </ng-container>
                                                </td>
                                                <td>
                                                    <ng-container *ngIf=" item.user !=null && item.user.email!=null">
                                                        {{item.user.email}}
                                                    </ng-container>
                                                </td>
                                                <td>
                                                    <form>
                                                        <div uk-form="target: > * > span:first-child">
                                                            <select (change)="changes(item.profile.id,$event);">
                                                        <option selected value="В обработке">В обработке</option>
                                                        <option value="Отклонено">Отклонено</option>
                                                        <option value="Выведено">Выведено</option>
                                                    </select>

                                                        </div>
                                                    </form>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </li>
        <li [ngClass]="{'uk-active': item_uk_switcher==3}">
            <div class="uk-grid">
                <div class="uk-width-1-1 uk-margin-top">
                    <div class="uk-card uk-card-small uk-card-default uk-card-body uk-margin-small-bottom uk-card-hover
                    uk-text-small">

                        <div class="uk-card-body">

                            <div style="overflow-x:auto;">
                                <table class="uk-table uk-table-middle uk-text-bold  uk-table-divider">
                                    <thead class="uk-text-bold">
                                        <tr>
                                            <th>Дата</th>
                                            <th>Сумма</th>
                                            <th>Платежная система</th>
                                            <th>Статус</th>
                                            <th>Номер транзакции</th>
                                            <th>Данные платежной системы</th>
                                            <th>Имя пользователя</th>
                                            <th>Фамилия пользователя</th>
                                            <th>Email пользователя</th>
                                            <th>Изменить статус</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let item of lost_withdraw">
                                            <tr id="data{{item.profile.id}}">
                                                <td>{{item.profile.created_at}}</td>
                                                <td style="color: green">
                                                    <ng-container *ngIf="valute=='ruble'">{{(item.profile.summa * kurs_usd_rub).toFixed(2)}}Р</ng-container>
                                                    <ng-container *ngIf="valute=='dollars'">{{item.profile.summa.toFixed(2)}}$</ng-container>
                                                </td>
                                                <td>{{item.profile.type}}</td>
                                                <td style="color: red">{{item.profile.status}}</td>
                                                <td>{{item.profile.id}}</td>
                                                <td [innerHtml]="item.profile.data"></td>
                                                <td>

                                                    <ng-container *ngIf="item.user !=null && item.user.name!=null">
                                                        {{item.user.name}}
                                                    </ng-container>
                                                </td>
                                                <td>

                                                    <ng-container *ngIf="item.user !=null && item.user.family!=null">
                                                        {{item.user.family}}
                                                    </ng-container>
                                                </td>
                                                <td  > 
                                                    <ng-container *ngIf=" item.user !=null && item.user.email!=null">
                                                        {{item.user.email}}
                                                    </ng-container>
                                                   
                                                </td>
                                                <td>
                                                    <form>
                                                        <div uk-form="target: > * > span:first-child">
                                                            <select (change)="changes(item.profile.id,$event);">
                                                                <option  value="В обработке">В обработке</option>
                                                                <option selected value="Отклонено">Отклонено</option>
                                                                <option value="Выведено">Выведено</option>
                                                            </select>

                                                        </div>
                                                    </form>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </li>
    </ul>

</div>