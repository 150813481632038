import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/Service/http.service';

@Injectable({
  providedIn: 'root'
})
export class PayService {

  constructor(private request:HttpService) { }
  public async list_pay() {
    let body = {};
    var result = await this.request.post('/cabinet/admin/pay',body).toPromise().then(
        (res:any)=>{
          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result; 
  }
  public async indexwihdraw() {
    let body = {};
    var result = await this.request.post('/cabinet/pay/indexwihdraw',body).toPromise().then(
        (res:any)=>{
          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result; 
  }
  public async indexdeposit() {
    let body = {};
    var result = await this.request.post('/cabinet/pay/indexdeposit',body).toPromise().then(
        (res:any)=>{
          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result; 
  }
  public async create_deposit(summa) {
    let body = {};
    var result = await this.request.post('/cabinet/pay/create_order_deposit/'+summa,body).toPromise().then(
        (res:any)=>{
          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result; 
  }
  public async create_deposit_interkassa(summa) {
    let body = {};
    var result = await this.request.post('/cabinet/pay/create_order_deposit/interkassa/'+summa,body).toPromise().then(
        (res:any)=>{
          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result; 
  }
  public async withdraw_send(body) {
    var result = await this.request.post('/cabinet/pay/create_order_withdraw',body).toPromise().then(
        (res:any)=>{
          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result; 
  }
  public async pay_change(id,status) {
    let body={id,status}
    var result = await this.request.post('/cabinet/admin/pay/update_status',body).toPromise().then(
        (res:any)=>{
          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result; 
  }
}
