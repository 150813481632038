<fieldset class="uk-fieldset">

    <legend class="uk-legend uk-flex-center">Сумма вывода <span class="rangeInputWithdrawShow">

        <ng-container *ngIf="valute=='ruble'">{{(balance*kurs_usd_rub).toFixed()}}</ng-container>
        <ng-container *ngIf="valute=='dollars'">{{(balance).toFixed()}}</ng-container>
</span>
        <ng-container *ngIf="valute=='ruble'">Р</ng-container>
        <ng-container *ngIf="valute=='dollars'">$</ng-container>

    </legend>

    <div class="uk-margin">
        <label class="pure-material-slider">
            <ng-container *ngIf="valute=='dollars'">
                <input class="uk-input" [(ngModel)]="summa" id="rangeInputWithdraw" (change)="rangeInputWithdrawChange();" style="border-radius: 50px; border: 1px solid black; background-color: white" value="{{balance}}" required>
            </ng-container>
            <ng-container *ngIf="valute=='ruble'">
            
                <input class="uk-input" [(ngModel)]="summa" id="rangeInputWithdraw" (change)="rangeInputWithdrawChange();" style="border-radius: 50px; border: 1px solid black; background-color: white" value="{{(balance*kurs_usd_rub).toFixed()}}" required>
            
            </ng-container>

        </label>
    </div>
    <br/>
</fieldset>
<div class="uk-child-width-1-4@s  uk-child-width-1-1 uk-text-center" style="    display: flex;
flex-wrap: wrap;
margin: 0;
padding: 0;
list-style: none;" uk-grid>


    <div class="uk-margin-medium-bottom" style="margin:auto;padding: 10px;">
        <div (click)="change_state('visa_block');" id="visa_block" class="uk-card uk-card-small uk-card-default uk-card-hover uk-card-body widthdraw_block" style="border-radius: 15px;background: #ffffff;">
            <img class="uk-align-center" width="auto" style="height:70px" src="/assets/cabinet/img/visa_master.svg" />

            <div class="layer">
                <form>

                    <input class="summa_withdraw" hidden name="summa_withdraw" type="text" value="131">
                    <input class="type" hidden name="type" type="text" value="visa">
                    <input class="data" hidden name="data" type="text" value="">
                    <div class="uk-margin">
                        <div class="uk-form-controls">
                            <label class="uk-form-label">Введите номер карты</label>
                            <input class="uk-input uk-text-center" id="number_visa" name="card" [(ngModel)]="card" style="border-radius: 50px " type="number" required placeholder="Номер карты">
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">CVV</label>
                        <div class="uk-form-controls">
                            <input class="uk-input uk-text-center" name="cvv" id="cvv_visa" [(ngModel)]="cvv" style="border-radius: 50px " type="number" required placeholder="594">
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Срок действия</label>
                        <div class="uk-form-controls">
                            <input class="uk-input uk-text-center" name="data" id="time_visa" [(ngModel)]="data" style="border-radius: 50px " type="text" required placeholder="02/22">
                        </div>
                    </div>
                    <div class="uk-margin">
                        <button type="button" (click)="withdraw_send('visa');" class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" style="border-radius: 50px ">Подать заявку</button>
                    </div>
                </form>
            </div>

        </div>
    </div>
    <div class="uk-margin-medium-bottom" style="margin:auto;padding: 10px;">
        <div (click)="change_state('qiwi_block');" id="qiwi_block" class="uk-card uk-card-small uk-card-default uk-card-hover uk-card-body widthdraw_block" style="border-radius: 15px;background: #ffffff;">
            <img class="uk-align-center" width="auto" style="height:70px" src="/assets/cabinet/img/qiwi.svg" />
            <div class="layer">
                <form id="qiwi">

                    <input class="summa_withdraw" hidden name="summa_withdraw" type="text" value="131">
                    <input class="type" hidden name="type" type="text" value="qiwi">
                    <input class="data" hidden name="data" type="text" value="">
                    <div class="uk-margin">
                        <div class="uk-form-controls">
                            <label class="uk-form-label">Введите номер телефона</label>
                            <input class="uk-input uk-text-center" id="qiwi_telephone" name="qiwi_telephone" [(ngModel)]="qiwi_telephone" style="border-radius: 50px " type="number" required placeholder="790591198584">
                        </div>
                    </div>
                    <div class="uk-margin">
                        <button type="button" (click)="withdraw_send('qiwi');" class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" style="border-radius: 50px ">Подать заявку</button>
                    </div>
                </form>
            </div>


        </div>
    </div>
    <div class="uk-margin-medium-bottom" style="margin:auto;padding: 10px;">
        <div (click)="change_state('yandex_wallet_block');" id="yandex_wallet_block" class="uk-card uk-card-small uk-card-default uk-card-hover uk-card-body widthdraw_block" style="border-radius: 15px;background: #ffffff;">
            <img class="uk-align-center" width="auto" style="height:70px" src="/assets/cabinet/img/yandex.svg" />
            <div class="layer">
                <form id="yandex">

                    <input class="summa_withdraw" hidden name="summa_withdraw" type="text" value="131">
                    <input class="type" hidden name="type" type="text" value="yandex">
                    <input class="data" hidden name="data" type="text" value="">
                    <div class="uk-margin">
                        <div class="uk-form-controls">
                            <label class="uk-form-label">Введите номер кошелька</label>
                            <input class="uk-input uk-text-center" id="yandex_wallet" name="yandex_wallet" [(ngModel)]="yandex_wallet" style="border-radius: 50px " type="number" required placeholder="Номер кошелька">
                        </div>
                    </div>
                    <div class="uk-margin">
                        <button type="button" (click)="withdraw_send('yandex');" class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" style="border-radius: 50px ">Подать заявку</button>
                    </div>
                </form>
            </div>

        </div>
    </div>
    <div class="uk-margin-medium-bottom" style="margin:auto;padding: 10px;">
        <div (click)="change_state('fk_block');" id="fk_block" class="uk-card uk-card-small uk-card-default uk-card-hover uk-card-body widthdraw_block" style="border-radius: 15px;background: #ffffff;">
            <img class="uk-align-center" width="auto" style="height:70px" src="/assets/cabinet/img/fkwallet.svg" />
            <div class="layer">
                <form id="fk">

                    <input class="summa_withdraw" hidden name="summa_withdraw" type="text" value="131">
                    <input class="type" hidden name="type" type="text" value="fk">
                    <input class="data" hidden name="data" type="text" value="">
                    <div class="uk-margin">
                        <div class="uk-form-controls">
                            <label class="uk-form-label">Введите номер кошелька</label>
                            <input class="uk-input uk-text-center" id="fk_wallet" name="fk_wallet" [(ngModel)]="fk_wallet" style="border-radius: 50px " type="number" required placeholder="Номер кошелька">
                        </div>
                    </div>
                    <div class="uk-margin">
                        <button type="button" (click)="withdraw_send('fk');" class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" style="border-radius: 50px ">Подать заявку</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="uk-margin-medium-bottom" style="margin:auto;padding: 10px;">
        <div (click)="change_state('tele2_block');" id="tele2_block" class="uk-card uk-card-small uk-card-default uk-card-hover uk-card-body widthdraw_block" style="border-radius: 15px;background: #ffffff;">
            <img class="uk-align-center" width="auto" style="height:70px" src="/assets/cabinet/img/tele2.svg" />
            <div class="layer">
                <form id="tele2">

                    <input class="summa_withdraw" hidden name="summa_withdraw" type="text" value="131">

                    <input class="type" hidden name="type" type="text" value="tele2">
                    <input class="data" hidden name="data" type="text" value="">
                    <div class="uk-margin">
                        <div class="uk-form-controls">
                            <label class="uk-form-label">Введите номер телефона</label>
                            <input class="uk-input uk-text-center" id="number_tele2" name="number_tele2" [(ngModel)]="number_tele2" style="border-radius: 50px " type="number" required placeholder="Номер телефона">
                        </div>
                    </div>
                    <div class="uk-margin">
                        <button type="button" (click)="withdraw_send('tele2');" class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" style="border-radius: 50px ">Подать заявку</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="uk-margin-medium-bottom" style="margin:auto;padding: 10px;">
        <div (click)="change_state('btc_block');" id="btc_block" class="uk-card uk-card-small uk-card-default uk-card-hover uk-card-body widthdraw_block" style="border-radius: 15px;background: #ffffff;">
            <img class="uk-align-center" width="auto" style="height:70px" src="/assets/cabinet/img/BTC-alt.svg" />
            <div class="layer">
                <form id="btc">

                    <input class="summa_withdraw" hidden name="summa_withdraw" type="text" value="131">
                    <input class="type" hidden name="type" type="text" value="btc">
                    <input class="data" hidden name="data" type="text" value="">
                    <div class="uk-margin">
                        <div class="uk-form-controls">
                            <label class="uk-form-label">Введите счет биткоина</label>
                            <input class="uk-input uk-text-center" id="bitcoin_wallet" name="bitcoin_wallet" [(ngModel)]="bitcoin_wallet" style="border-radius: 50px " type="text" required placeholder="Счет биткоина">
                        </div>
                    </div>
                    <div class="uk-margin">
                        <button type="button" (click)="withdraw_send('bitcoin');" class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" style="border-radius: 50px ">Подать заявку</button>
                    </div>
                </form>
            </div>
        </div>
    </div>




</div>
<div class="uk-grid">
    <div class="uk-width-1-1 uk-margin-top">
        <div class="uk-card uk-card-small uk-card-default uk-card-body uk-margin-small-bottom uk-card-hover
        uk-text-small">
            <div class="uk-card-header">
                <div class="uk-grid uk-grid-small uk-text-large" data-uk-grid="">
                    <div class="uk-width-expand uk-text-center">
                        <span class="uk-text-emphasis ">Выводы средств</span><br>
                        <span class="uk-text-emphasis ">Для отмены свяжитесь с тех поддержкой</span>
                    </div>
                </div>
            </div>

            <div class="uk-card-body">

                <div style="overflow-x:auto;">
                    <table class="uk-table uk-table-middle uk-text-bold  uk-table-divider">
                        <thead class="uk-text-bold">
                            <tr>
                                <th>Дата</th>
                                <th>Сумма</th>
                                <th>Платежная система</th>
                                <th>Номер транзакции</th>
                                <th>Статус</th>
                                <th>Данные платежной системы</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of list_items">
                                <td>{{item.created_at}}</td>
                                <td style="color: green">
                                    <ng-container *ngIf="valute=='ruble'">{{item.summa * kurs_usd_rub}}Р</ng-container>
                                    <ng-container *ngIf="valute=='dollars'">{{item.summa}}$</ng-container>
                                </td>
                                <td>{{item.type}}</td>
                                <td>{{item.id}}</td>

                                <ng-container *ngIf="item.status=='Отклонено'">
                                    <td style="color: red">{{item.status}}</td>
                                </ng-container>
                                <ng-container *ngIf="item.status=='В обработке'">
                                    <td style="color: blue">{{item.status}}</td>
                                </ng-container>
                                <ng-container *ngIf="item.status=='Выведено'">
                                    <td style="color: green">{{item.status}}</td>
                                </ng-container>
                                <td [innerHtml]="item.data"></td>

                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</div>