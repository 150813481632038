import { Component, OnInit, OnDestroy } from '@angular/core';
import { InvestService } from 'src/app/LogicApi/Invest/invest.service';
import { AuthService } from 'src/app/LogicApi/Auth/auth.service';
import { Subscription } from 'rxjs';
declare var $:any
@Component({
  selector: 'app-invest',
  templateUrl: './invest.component.html',
  styleUrls: ['./invest.component.sass']
})
export class InvestComponent implements OnInit, OnDestroy {

  constructor(private invest:InvestService,public auth :AuthService) { }
  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscriptions
     .forEach(s => s.unsubscribe());
    }



  item_uk_switcher=1;
  change_menu(id)
  {
    this.item_uk_switcher=id;
  }

  deposit_items=null;
  robots_items=null;
  interval;
  summa=0;
  deposit_table_list=null
  robots_table_list=null;
//  new_line_deposit=true;
  kurs_usd_rub=1;
  balance=0;
  private subscriptions: Subscription[] = [];
  bools=false;
  valute="";

  ngOnInit(): void {
    this.update_data();
    const langSub = this.auth.UserData$
    .subscribe(() => {
      if(!this.bools)
      {
        this.balance=this.auth.userdata.profile.balance;
        this.valute=this.auth.userdata.profile.currency;
        this.bools=true;
      }

    });
    this.subscriptions.push(langSub);

  }

  deposit_investing_change(id) {
    
    for (var i = 0; i < this.deposit_items.length; i++) {
        $('#btndeposit' + i).removeClass('uk-card-primary');
        $('#btndeposit' + i).css('border', '');
        $('#btndeposit' + i).removeClass('uk-card-default');
        if (id == i) {
            $('#btndeposit' + i).addClass('uk-card-primary');
            $('#datadeposit' + i).show();
        } else {
            $('#btndeposit' + i).css('border', ' 2px solid blue');
            $('#datadeposit' + i).hide();
            $('#btndeposit' + i).addClass('uk-card-default');
        }

    }
  }


  robots_investing_change(id) {
    for (var i = 0; i < this.robots_items.length; i++) {
        $('#btnrobots' + i).removeClass('uk-card-primary');
        $('#btnrobots' + i).css('border', '');
        $('#btnrobots' + i).removeClass('uk-card-default');
        if (id == i) {
            $('#btnrobots' + i).addClass('uk-card-primary');
            $('#datarobot' + i).show();
        } else {
            $('#btnrobots' + i).css('border', ' 2px solid blue');
            $('#datarobot' + i).hide();
            $('#btnrobots' + i).addClass('uk-card-default');
        }

    }
  }



  update_data()
  {
    this.invest.get().then((res)=>
    {
      if(res!=null)
      {
        this.deposit_items=res.list_deposit_type;
        this.robots_items=res.list_torg_robots_type;
        this.deposit_table_list=res.deposit_table_list;
        this.robots_table_list=res.robots_table_list;
        this.kurs_usd_rub=res.kurs_usd_rub;
       console.log(res);

      }
    });
  }
  async pay_serv(type_id,type,index)
  {
    let summa =0;
    if(type=="robots")
    {
      summa = $('#rangeInputRobots'+index)[0].value;
      console.log($('#rangeInputRobots'+index));

    }
    else
    {
      summa = $('#rangeInputDeposit'+index)[0].value;
      console.log($('#rangeInputDeposit'+index));

    }
    
    await this.invest.creates(type_id,summa).then((res:any)=>
    {
      console.log(res);
      
      this.update_data();
    })

  }


  rangeInputDepositChange(id,item) {
    let elem = $('#rangeInputDeposit'+id)[0];
    console.log(elem);
    
    let sum:number=0;
    if(isNaN(parseFloat(elem.value))) {
        sum=0;
    }
    else
    {
        sum=elem.value;
    }


    let target:any = $('.rangeInputDepositShow'+id)[0];
    let targetday:any = $('#day_price'+id)[0];
    let targetterm:any = $('#term_price'+id)[0];

      if(this.valute=='ruble')
      {
        console.log(target);

        target.innerHTML = Math.round(sum*this.kurs_usd_rub);
        targetday.innerHTML = (sum/100*(item.percent * this.kurs_usd_rub)).toFixed();
        targetterm.innerHTML =(sum/100*(item.percent *item.count_days * this.kurs_usd_rub)).toFixed();  
        
      }
      if(this.valute=='dollars')
      {
        target.innerHTML = Math.round(sum);
          targetday.innerHTML = (sum/100*item.percent).toFixed();
         targetterm.innerHTML = (sum/100*(item.percent *item.count_days)).toFixed();
         
      }
    }
        
  
    rangeInputRobotsChange(id,item) {
      let elem = $('#rangeInputRobots'+id)[0];
  
      let sum=0;
      if(isNaN(parseFloat(elem.value))) {
          sum=0;
      }
      else
      {
          sum=elem.value;
      }
  
  
  
      let target:any = document.querySelector('.rangeInputRobotsShow'+id);
      let targetday:any = document.querySelector('#day_price_robots'+id);
      let targetterm:any = document.querySelector('#term_price_robots'+id);
      if(this.valute=='ruble')
      {
        target.innerHTML = Math.round(sum*this.kurs_usd_rub);
        targetday.innerHTML = Math.round(sum/100*(item.percent * this.kurs_usd_rub));
        targetterm.innerHTML =Math.round(sum/100*(item.percent *item.count_days * this.kurs_usd_rub));  
        
      }
      if(this.valute=='dollars')
      {
        target.innerHTML = Math.round(sum);
          targetday.innerHTML = Math.round(sum/100*item.percent);
           targetterm.innerHTML = Math.round(sum/100*(item.percent *item.count_days));
         
      }
  }


}
