import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/Service/http.service';
import {tap} from "rxjs/operators";
import { Router } from '@angular/router';
import { HttpEvent } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http:HttpService,private router: Router) { }
  public UserData$: BehaviorSubject<String> = new BehaviorSubject<String>("");

  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly TOKEN_TYPE = 'TOKEN_TYPE';
  private readonly EXPIRES_IN = 'EXPIRES_IN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private loggedUser: string;
  public userdata;
  async getMe() {
    await this.http.post('/auth/me',{}).toPromise().then(
        (res:any)=>{
          if(res==null) {this.logout();}
          this.userdata=res;
          this.UserData$.next("");
          return this.userdata;
        },
        (err:any)=>{
          console.log(err);
          this.logout();
          return null;
        }
      );
  }

  async login(username: string, password: string ) {
   let user={'email': username, 'password':password}
    return await this.http.post(`/auth/login`, user).toPromise().then(
      (res:any)=>{

        console.log(res);
          if(res==null) return false
          if (typeof res.error !== "undefined") {
            return false
          }

          this.doLoginUser(user.email, res.access_token, res.token_type, res.EXPIRES_IN)
          return true
       },
       err=>{
         console.log(err);
         return false;
       } 

    );
  }
  async register(name: string, family: string,telephone: string, email: string,password: string ) {
    let user={name, family,telephone, email,password }
     return await this.http.post(`/auth/register`, user).toPromise().then(
       (res:any)=>{
         console.log(res);
         
           if(res==null) return false
           if (typeof res.error !== "undefined") {
             return false
           }
           return true
        },
        err=>{
          console.log(err);
          return false;
        } 
 
     );
   }
  async logout() {
    this.run=false;
     await this.http.post(`/auth/logout`,{ 'refreshToken': this.getRefreshToken()}).toPromise().then(
      (res:any)=>{
          if(res==null) return false
          this.doLogoutUser()
          return true
       },
       err=>{
         console.log(err);
         return false;
       } 

    );
    this.doLogoutUser();
  }
run=false;
async  isLoggedIn():Promise<boolean> {
    if(!!this.getJwtToken())
    {
     if(!this.run){
      await   this.getMe(); 
      this.run=true;
     } 
    }
    return !!this.getJwtToken();
  }

  refreshToken() {
    return this.http.post(`/auth/refresh`, {
      'refreshToken': this.getRefreshToken()
    }).pipe<HttpEvent<any>>(
      (res:any)=>{
          if(res==null) return null
         // this.doLogoutUser()
          return res;
       }
    ) 
  }

  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  private doLoginUser(username: string, tokens: string, token_type: string, EXPIRES_IN) {
    this.loggedUser = username;
    this.storeTokens(tokens, token_type, EXPIRES_IN);
  }

  private doLogoutUser() {
    this.loggedUser = null;
    this.removeTokens();
    this.router.navigate(['/cabinet/login']);
  }

  private getRefreshToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  private storeJwtToken(jwt: string) {
    localStorage.setItem(this.JWT_TOKEN, jwt);
  }

  private storeTokens(tokens: string, token_type: string, EXPIRES_IN) {
    localStorage.setItem(this.JWT_TOKEN, tokens);
    localStorage.setItem(this.REFRESH_TOKEN, EXPIRES_IN);
    localStorage.setItem(this.TOKEN_TYPE, token_type);
  }

  private removeTokens() {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
    localStorage.removeItem(this.TOKEN_TYPE);
  }
  
}
