import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsersComponent } from './users/users.component';
import { InvestsettingsComponent } from './investsettings/investsettings.component';
import { ProfileComponent } from './profile/profile.component';
import { PayComponent } from './pay/pay.component';


const routes: Routes = [

    {
      path:'',
      component:UsersComponent,
    },
    {
      path:'pay',
      component:PayComponent,
    },
    {
      path:'invest',
      component:InvestsettingsComponent,
    },
    {
      path:'profile/:id',
      component:ProfileComponent,
    }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
