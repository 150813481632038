<div class="uk-container uk-container-center">
    <ul class="uk-tab" data-uk-tab="{connect:'#my-id'}">
        <li (click)="change_menu(1)">
            <a>
                <h4>Депозиты</h4>
            </a>
        </li>
        <li (click)="change_menu(2)">
            <a>
                <h4>Торговые роботы</h4>
            </a>
        </li>
    </ul>
    <ul id="my-id" class="uk-switcher uk-margin">
        <li [ngClass]="{'uk-active': item_uk_switcher==1}">
            <div class="uk-grid">
                <div class="uk-width-1-1">

                    <div class="uk-card uk-card-small uk-card-default">
                        <div class="uk-card-header">
                            <div class="uk-grid uk-grid-small uk-text-medium" data-uk-grid="">
                                <div class="uk-width-expand uk-first-column">
                                    <span class="cat-txt">Создание депозита </span>
                                </div>
                            </div>
                        </div>
                        <div class="uk-card-body">
                            <form>

                                <div class="uk-margin">
                                    <label class="uk-form-label">Название</label>
                                    <div class="uk-form-controls">
                                        <input class="uk-input" name="name_deposit" [(ngModel)]="name_deposit" style="border-radius: 50px " type="text" required placeholder="" value="">
                                    </div>
                                </div>
                                <div class="uk-margin">
                                    <label class="uk-form-label">Процент</label>
                                    <div class="uk-form-controls">
                                        <input class="uk-input" name="procent_deposit" [(ngModel)]="procent_deposit" style="border-radius: 50px " type="text" required placeholder="" value="">
                                    </div>
                                </div>
                                <div class="uk-margin">
                                    <label class="uk-form-label">Срок контракта в днях</label>
                                    <div class="uk-form-controls">
                                        <input class="uk-input" name="term_deposit" [(ngModel)]="term_deposit" style="border-radius: 50px " type="text" required placeholder="" value="">
                                    </div>
                                </div>
                                <div class="uk-margin">
                                    <label class="uk-form-label">Минимальная сумма в $</label>
                                    <div class="uk-form-controls">
                                        <input class="uk-input" name="min_summa_deposit" [(ngModel)]="min_summa_deposit" style="border-radius: 50px " type="text" required placeholder="" value="">
                                    </div>
                                </div>
                                <div class="uk-margin">
                                    <label class="uk-form-label">Максимальная сумма в $</label>
                                    <div class="uk-form-controls">
                                        <input class="uk-input" name="max_summa_deposit" [(ngModel)]="max_summa_deposit" style="border-radius: 50px " type="text" required placeholder="" value="">
                                    </div>
                                </div>
                                <div class="uk-margin">
                                    <button (click)="save_deposit()" class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" style="border-radius: 50px ">Сохранить</button>
                                </div>
                            </form>
                        </div>

                    </div>

                </div>

                <div class="uk-width-1-1 uk-margin-top">
                    <div class="uk-card uk-card-small uk-card-default uk-card-body uk-margin-small-bottom uk-card-hover
        uk-text-small">
                        <div class="uk-card-header">
                            <div class="uk-grid uk-grid-small uk-text-large" data-uk-grid="">
                                <div class="uk-width-expand uk-text-center">
                                    <span class="uk-text-emphasis ">Типы торговых роботов</span>
                                </div>
                            </div>
                        </div>

                        <div class="uk-card-body">

                            <div style="overflow-x:auto;">
                                <table class="uk-table uk-table-middle uk-text-bold uk-text-center  uk-table-divider">
                                    <thead class="uk-text-bold">
                                        <tr>
                                            <th>Название</th>
                                            <th>Процент</th>
                                            <th>Срок контракта в днях</th>
                                            <th>Минимальная сумма</th>
                                            <th>Максимальная сумма</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr *ngFor="let item of deposits_list">
                                            <td>{{item.name}}</td>
                                            <td>{{item.percent}}</td>
                                            <td>{{item.count_days}}</td>
                                            <td>{{item.min_deposit}}$</td>
                                            <td>{{item.max_deposit}}$</td>
                                            <td>
                                                <a type="button" (click)="cancel_deposit(item.id)" class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" style="border-radius: 50px ">Удалить</a>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </li>
        <li [ngClass]="{'uk-active': item_uk_switcher==2}">
            <div class="uk-grid">
                <div class="uk-width-1-1">

                    <div class="uk-card uk-card-small uk-card-default">
                        <div class="uk-card-header">
                            <div class="uk-grid uk-grid-small uk-text-medium" data-uk-grid="">
                                <div class="uk-width-expand uk-first-column">
                                    <span class="cat-txt">Создание типа инвестирования для торговых роботов </span>
                                </div>
                            </div>
                        </div>
                        <div class="uk-card-body">
                            <form>

                                <div class="uk-margin">
                                    <label class="uk-form-label">Название</label>
                                    <div class="uk-form-controls">
                                        <input class="uk-input" name="name_robots" [(ngModel)]="name_robots" style="border-radius: 50px " type="text" required placeholder="" value="">
                                    </div>
                                </div>
                                <div class="uk-margin">
                                    <label class="uk-form-label">Процент</label>
                                    <div class="uk-form-controls">
                                        <input class="uk-input" name="procent_robots" [(ngModel)]="procent_robots" style="border-radius: 50px " type="text" required placeholder="" value="">
                                    </div>
                                </div>
                                <div class="uk-margin">
                                    <label class="uk-form-label">Срок контракта в днях</label>
                                    <div class="uk-form-controls">
                                        <input class="uk-input" name="term_robots" [(ngModel)]="term_robots" style="border-radius: 50px " type="text" required placeholder="" value="">
                                    </div>
                                </div>
                                <div class="uk-margin">
                                    <label class="uk-form-label">Минимальная сумма в $</label>
                                    <div class="uk-form-controls">
                                        <input class="uk-input" name="min_summa_robots" [(ngModel)]="min_summa_robots" style="border-radius: 50px " type="text" required placeholder="" value="">
                                    </div>
                                </div>
                                <div class="uk-margin">
                                    <label class="uk-form-label">Максимальная сумма в $</label>
                                    <div class="uk-form-controls">
                                        <input class="uk-input" name="max_summa_robots" [(ngModel)]="max_summa_robots" style="border-radius: 50px " type="text" required placeholder="" value="">
                                    </div>
                                </div>
                                <div class="uk-margin">
                                    <button (click)="save_robots()" class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" style="border-radius: 50px ">Сохранить</button>
                                </div>
                            </form>
                        </div>

                    </div>

                </div>

                <div class="uk-width-1-1 uk-margin-top">
                    <div class="uk-card uk-card-small uk-card-default uk-card-body uk-margin-small-bottom uk-card-hover
        uk-text-small">
                        <div class="uk-card-header">
                            <div class="uk-grid uk-grid-small uk-text-large" data-uk-grid="">
                                <div class="uk-width-expand uk-text-center">
                                    <span class="uk-text-emphasis ">Типы торговых роботов</span>
                                </div>
                            </div>
                        </div>

                        <div class="uk-card-body">

                            <div style="overflow-x:auto;">
                                <table class="uk-table uk-table-middle uk-text-bold uk-text-center  uk-table-divider">
                                    <thead class="uk-text-bold">
                                        <tr>
                                            <th>Название</th>
                                            <th>Процент</th>
                                            <th>Срок контракта в днях</th>
                                            <th>Минимальная сумма</th>
                                            <th>Максимальная сумма</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr *ngFor="let item of robots_list">
                                            <td>{{item.name}}</td>
                                            <td>{{item.percent}}</td>
                                            <td>{{item.count_days}}</td>
                                            <td>{{item.min_deposit}}$</td>
                                            <td>{{item.max_deposit}}$</td>
                                            <td>
                                                <a type="button" (click)="cancel_deposit(item.id)" class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" style="border-radius: 50px ">Удалить</a>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </li>
    </ul>

</div>