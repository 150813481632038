import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.sass']
})
export class IndexComponent implements OnInit, AfterViewInit,OnDestroy {

  constructor() { }
  ngOnDestroy(): void {
    $('body').removeClass('homePageOne');
  }
  ngAfterViewInit(): void {
    $('body').addClass('homePageOne');

  }

  ngOnInit(): void {
    var menutoggle = $(".menu-toggle");
    var offcanvasmenu = $("#offcanvas-menu");
    var closemenu = $(".close-menu");

    menutoggle.on("click", function() {
        offcanvasmenu.addClass("toggled");
        return false;
    });

    closemenu.on("click", function() {
        offcanvasmenu.removeClass("toggled");
        return false;
    });

  }

}
