import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CabinetComponent } from './cabinet.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { Cabinet404errorComponent } from './cabinet404error/cabinet404error.component';
import { AuthGuard } from 'src/app/Guards/AuthGuard.service';
import { LoginGuard } from 'src/app/Guards/LoginGuard.service';
import { LostPasswordComponent } from './lost-password/lost-password.component';
import { LostPasswordFinishComponent } from './lost-password-finish/lost-password-finish.component';
import { LostPasswordFinishNewPasswordComponent } from './lost-password-finish-new-password/lost-password-finish-new-password.component';



const children_routes:Routes=[
  {
    path:'',
    loadChildren: () => import('./personalcabinet/personalcabinet-routing').then(m => m.PersonalCabinetRoutingModule),
    canActivate: [LoginGuard]
  },
  {
    path:'login',
    component:LoginComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'register',
    component:RegisterComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'lostpassword',
    component:LostPasswordComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'lostpasswordfinish',
    component:LostPasswordFinishComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'lostpasswordfinishnew',
    component:LostPasswordFinishNewPasswordComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'**',
    component:Cabinet404errorComponent
  }
]

const routes: Routes = [

    {
      path:'',
      component:CabinetComponent,
      children:children_routes
    }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CabinetRoutingModule { }
