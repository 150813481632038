import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LostPasswordFinishComponent } from './lost-password-finish.component';
import { AuthHeaderModule } from 'src/app/components/auth-header/auth-header.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [LostPasswordFinishComponent],
  imports: [
    CommonModule,AuthHeaderModule,RouterModule,
  ]
})
export class LostPasswordFinishModule { }
