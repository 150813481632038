import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LostWithdrawComponent } from './lost-withdraw.component';



@NgModule({
  declarations: [ LostWithdrawComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports:[ 
    LostWithdrawComponent
  ]
})
export class LostWithdrawModule { }
