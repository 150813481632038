import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CabinetComponent } from './cabinet.component';
import { LoginModule } from './login/login.module';
import { CabinetRoutingModule } from './cabinet-routing.module';
import { RouterModule } from '@angular/router';
import { RegisterModule } from './register/register.module';
import { Cabinet404errorModule } from './cabinet404error/cabinet404error.module';
import { PersonalcabinetModule } from './personalcabinet/personalcabinet.module';
import { LostPasswordModule } from './lost-password/lost-password.module';
import { LostPasswordFinishModule } from './lost-password-finish/lost-password-finish.module';
import { LostPasswordFinishhNewPasswordModule } from './lost-password-finish-new-password/lost-password-finish-new-password.module';

@NgModule({
  declarations: [CabinetComponent],
  imports: [
    CommonModule,
    RouterModule,
    CabinetRoutingModule,
    LoginModule,
    RegisterModule,
    LostPasswordModule,
    LostPasswordFinishModule,
    LostPasswordFinishhNewPasswordModule,
    Cabinet404errorModule,
    PersonalcabinetModule
  ]
})
export class CabinetModule { }
