import { Component, OnInit, AfterContentInit, OnDestroy } from '@angular/core';
import { InvestService } from 'src/app/LogicApi/Invest/invest.service';
import { AuthService } from 'src/app/LogicApi/Auth/auth.service';
import { Subscription } from 'rxjs';
import { ProfileService } from 'src/app/LogicApi/Profile/profile.service';
declare var $:any
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.sass']
})
export class ProfileComponent implements OnInit,AfterContentInit,OnDestroy {

  constructor(private invest:InvestService,public auth :AuthService,private profile:ProfileService) { }
//  private subscriptions: Subscription[] = [];

  ngOnDestroy(): void {
   /* this.subscriptions
    .forEach(s => s.unsubscribe());*/
  //  clearInterval(this.interval);
    }
  ngAfterContentInit(): void {
    //this.files = [];
  }

/*
  deposit_items=null;
  robots_items=null;
  interval;
  summa=0;
  deposit_table_list=null
  robots_table_list=null;

  type=1;//1-5
  email=""
  family=""
  name=""
  thirty_name=""
  data=""
  telephone=""
  balance=""
  valute=""
  photo=""


  lost_password
  newpassword
  refreshpassword

  public files: any[];

  passport=null
  photo_with_passport=null
  address=null

  passport_tag
  photo_with_passport_tag
  address_tag*/

  item_uk_switcher=1;
  change_menu(id)
  {
    this.item_uk_switcher=id;
  }
/*
  update_data()
  {
    this.invest.get().then((res)=>
    {
      if(res!=null)
      {
        this.deposit_items=res.list_deposit_type;
        this.robots_items=res.list_torg_robots_type;
        this.deposit_table_list=res.deposit_table_list;
        this.robots_table_list=res.robots_table_list;

        //console.log(res);

      }
    });
  }
  bools=false;*/
  ngOnInit(): void {
    /*const langSub = this.auth.UserData$
    .subscribe(() => {
      if(!this.bools)
      {
        this.email=this.auth.userdata.profile.email;
        this.family=this.auth.userdata.profile.family;
        this.name=this.auth.userdata.profile.name;
        this.thirty_name=this.auth.userdata.profile.middlename;
        this.data=this.auth.userdata.profile.birthday;
        this.telephone=this.auth.userdata.profile.telephone;
        this.balance=this.auth.userdata.profile.balance;
        this.valute=this.auth.userdata.profile.currency;
        this.photo='/cabinet/profile/get_avatar/'+this.auth.userdata.profile.id
        this.bools=true;
      }

    });
  this.subscriptions.push(langSub);


    this.profile.get().then((res:any)=>{
      if(res!=null)
      {
        this.passport_tag=res.passport_tag;
        this.passport=res.passport;

        this.address=res.address;
        this.address_tag=res.address_tag;
        this.photo_with_passport=res.photo_with_passport;
        this.photo_with_passport_tag=res.photo_with_passport_tag;
          //console.log(res);

      }
    })

    $('.datatable').DataTable({
      responsive: true
    });

    this.update_data();
    this.interval = setInterval(() => {
     this.update_data();
     },60000)*/
  }/*
  change_link(type)
  {
    $('#item'+this.type).removeClass('text-theme-1');
    $('#item'+this.type).removeClass('font-medium');
    this.type=type;
    $('#item'+this.type).addClass('text-theme-1');
    $('#item'+this.type).addClass('font-medium');
  }
  personal_save()
  {
    this.profile.profile_data_update(this.email,this.name,this.family,this.thirty_name,this.data,this.telephone,
      this.valute,this.auth.userdata.profile.id,this.balance);
  }
  change_password()
  {
      if(this.newpassword==this.refreshpassword)
      this.profile.update_password(this.lost_password,this.newpassword,this.auth.userdata.profile.id);
  }

  onFileChanged(event: any) {
    this.files = event.target.files;
    this.profile.ImageUploadPost(this.files,this.auth.userdata.profile.id)
  }
  ProfileAddressUploadPost(event: any) {
    this.profile.ProfileAddressUploadPost(event.target.files,this.auth.userdata.profile.id)
  }
  ProfilePassportUploadPost(event: any) {
    this.profile.ProfilePassportUploadPost(event.target.files,this.auth.userdata.profile.id)
  }
  ProfilePhotoWithPassportUploadPost(event: any) {
    this.profile.ProfilePhotoWithPassportUploadPost(event.target.files,this.auth.userdata.profile.id)
  }*/
}
