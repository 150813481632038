<!-- BEGIN: Login Info -->
<div class="hidden xl:flex flex-col min-h-screen">
    <a href="" class="-intro-x flex items-center pt-5">
        <img alt="" class="w-6" src="/assets/auth/images/logo.svg">
        <span class="text-white text-lg ml-3"> Air<span class="font-medium">Solutions</span> </span>
    </a>
    <div class="my-auto">
        <img alt="Midone Tailwind HTML Admin Template" class="-intro-x w-1/2 -mt-16" src="/assets/auth/images/illustration.svg">
        <div class="-intro-x text-white font-medium text-4xl leading-tight mt-10">
            Присоеденитесь
            <br>к лучшей компании.
        </div>
        <div class="-intro-x mt-5 text-lg text-white"> Для получения большей функциональности вам нужно авторизоваться.</div>
    </div>
</div>
<!-- END: Login Info -->