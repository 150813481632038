<div id="main-wrapper">
    <div id="box">
        <div class="top-bar hidden-sm hidden-xs">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="left-bar">
                            <ul>
                                <li><img src="/assets/site/img/mail.png" alt=""> support@AirSolutionsGroup.com</li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <div class="right-bar text-right">
                            <div class="country dropdown inline-block">
                                <i class="fa fa-globe" aria-hidden="true"></i> Языки:

                                <select name="one" class="dropdown-select">
                                    <option value="">Русский</option>
                                    <option value="1">English</option>
                                    <option value="2">China</option>
                                    <option value="3">Japan</option>
                                </select>
                            </div>
                            <ul class="social-icon inline-block">
                                <li><a target="_blank" href="#"><i class="fa fa-vk" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- top-bar -->
    </div>
    <header class="header-section header-section-one">
        <div class="shadow-nav hidden-xs"></div>
        <nav class="navbar navbar-top navbar-inverse">
            <div class="container">
                <div class="nav-p">
                    <div class="navbar-wrapper">
                        <div class="navbar-header">
                            <a class="navbar-brand" [routerLink]="['/']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active">
                                <img class="fix-logo caption hidden-sm hidden-xs hidden-md" style="width: 210px;" src="/assets/site/img/startwhitelogo.png" alt="image">
                                <img class="scroll-logo caption hidden-sm hidden-xs hidden-md" style="width: 210px;" src="/assets/site/img/StartCom-logo1.png" alt="image">
                                <img class="caption-two visible-sm visible-xs" style="width: 175px;" src="/assets/site/img/startwhitelogo.png" alt="image">
                            </a>
                        </div>

                        <div class="collapse navbar-collapse hidden-sm hidden-xs">
                            <ul class="nav navbar-nav">
                                <li class=""><a [routerLink]="['/']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active"><i class="ei-home"></i> Главная</a></li>
                                <li class=""><a [routerLink]="['/about']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active"><i class="ei-strategy"></i> О компании</a></li>
                                <li class=""><a [routerLink]="['/bussiness-model']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active"><i class="ei-panel-1"></i> Бизнес-модель</a></li>
                                <li class=""><a [routerLink]="['/faq']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active"><i class="ei-desk-lamp"></i> F.A.Q.</a></li>
                                <li class=""><a [routerLink]="['/contacts']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active"><i class="fa fa-envelope-o"></i> Контакты</a></li>
                                <div class="nav-kp"><a [routerLink]="['/cabinet']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active" class="btn btn-primary"><i class="ei-log-in" aria-hidden="true" style="margin-right: 2px;"></i> Войти в кабинет</a></div>
                            </ul>
                        </div>
                    </div>
                    <div class="nav-right-bar navbar-right hidden-sm hidden-xs">
                        <span><a [routerLink]="['/cabinet']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active"><i class="ei-log-in" aria-hidden="true"></i> Войти в кабинет</a></span>
                    </div>
                    <span class="menu-toggle navbar-right visible-xs visible-sm"><i class="fa fa-bars" aria-hidden="true"></i></span>

                </div>
            </div>
        </nav>

    </header>
</div>