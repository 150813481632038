import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/Service/http.service';

@Injectable({
  providedIn: 'root'
})
export class BuySellService {

  constructor(private request:HttpService) { }
  public async get_pairs() {
    let body = {};
    var result = await this.request.post('/cabinet/buysell/get_pairs',body).toPromise().then(
        (res:any)=>{
          console.log(res);
          
          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result; 
  }
  public async get_data_pairs(symbol:string) {
    let body = {symbol};
    var result = await this.request.post('/cabinet/buysell/get_data_pairs',body).toPromise().then(
      (res:any)=>{
          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result; 
  }
  public async create_order(symbol:string,lot,type) {
    let body = {symbol,lot,type};
    var result = await this.request.post('/cabinet/buysell/create_order',body).toPromise().then(
        (res:any)=>{
          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result; 
  }
  public async close_order(id:number) {
    let body = {id};
    var result = await this.request.post('/cabinet/buysell/close_order',body).toPromise().then(
        (res:any)=>{
          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result; 
  }
  public async get_info_statistics() {
    let body = {};
    var result = await this.request.post('/cabinet/buysell/get_info_statistics',body).toPromise().then(
        (res:any)=>{
          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result; 
  }
}
