import { Component, OnInit, AfterViewInit } from '@angular/core';
declare var $:any
@Component({
  selector: 'app-site-slider',
  templateUrl: './site-slider.component.html',
  styleUrls: ['./site-slider.component.sass']
})
export class SiteSliderComponent implements OnInit, AfterViewInit {

  constructor() { }
  ngAfterViewInit(): void {
    
    
  }

  ngOnInit(): void {
  }

}
