<div id="main-wrapper">
    <div id="box">
        <div class="top-bar hidden-sm hidden-xs">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="left-bar">
                            <ul>
                                <li><img src="/assets/site/img/mail.png" alt=""> support@AirSolutionsGroup.com</li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <div class="right-bar text-right">
                            <div class="country dropdown inline-block">
                                <i class="fa fa-globe" aria-hidden="true"></i> Языки:

                                <select name="one" class="dropdown-select">
                                    <option value="">Русский</option>
                                    <option value="1">English</option>
                                    <option value="2">China</option>
                                    <option value="3">Japan</option>
                                </select>
                            </div>
                            <ul class="social-icon inline-block">
                                <li><a target="_blank" href="#"><i class="fa fa-vk" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- top-bar -->
    </div>
    <header class="header-section header-section-one">
        <div class="shadow-nav hidden-xs"></div>
        <nav class="navbar navbar-top navbar-inverse">
            <div class="container">
                <div class="nav-p">
                    <div class="navbar-wrapper">
                        <div class="navbar-header">
                            <a class="navbar-brand" [routerLink]="['/']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active">
                                <img class="fix-logo caption hidden-sm hidden-xs hidden-md" style="width: 210px;" src="/assets/site/img/startwhitelogo.png" alt="image">
                                <img class="scroll-logo caption hidden-sm hidden-xs hidden-md" style="width: 210px;" src="/assets/site/img/StartCom-logo1.png" alt="image">
                                <img class="caption-two visible-sm visible-xs" style="width: 175px;" src="/assets/site/img/startwhitelogo.png" alt="image">
                            </a>
                        </div>

                        <div class="collapse navbar-collapse hidden-sm hidden-xs">
                            <ul class="nav navbar-nav">
                                <li class=""><a [routerLink]="['/']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active"><i class="ei-home"></i> Главная</a></li>
                                <li class=""><a [routerLink]="['/about']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active"><i class="ei-strategy"></i> О компании</a></li>
                                <li class=""><a [routerLink]="['/bussiness-model']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active"><i class="ei-panel-1"></i> Бизнес-модель</a></li>
                                <li class=""><a [routerLink]="['/faq']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active"><i class="ei-desk-lamp"></i> F.A.Q.</a></li>
                                <li class=""><a [routerLink]="['/contacts']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active"><i class="fa fa-envelope-o"></i> Контакты</a></li>
                                <div class="nav-kp"><a [routerLink]="['/cabinet']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active" class="btn btn-primary"><i class="ei-log-in" aria-hidden="true" style="margin-right: 2px;"></i> Войти в кабинет</a></div>
                            </ul>
                        </div>
                    </div>
                    <div class="nav-right-bar navbar-right hidden-sm hidden-xs">
                        <span><a [routerLink]="['/cabinet']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active"><i class="ei-log-in" aria-hidden="true"></i> Войти в кабинет</a></span>
                    </div>
                    <span class="menu-toggle navbar-right visible-xs visible-sm"><i class="fa fa-bars" aria-hidden="true"></i></span>

                </div>
            </div>
        </nav>

    </header>
    <app-site-slider></app-site-slider>
</div>


<section class="information-section">
    <div class="container">
        <h3 style="font-size: 17px;">ПРИСОЕДИНЯЙТЕСЬ К Air Solutions Group И ЗАРАБАТЫВАЙТЕ НА ТЕХНОЛОГИЯХ БУДУЩЕГО ПРЯМО СЕЙЧАС</h3>
        <a href="#" data-toggle="modal" data-target=".modal3" class="btn btn-primary pull-right"><i class="fa fa-calculator" aria-hidden="true"></i> Калькулятор доходности</a>
    </div>
</section>
<section class="finance-section section-padding">
    <div class="container">
        <div class="title-bar text-center">
            <h2 class="title">Air Solutions Group / INSIDER BUSINESS GROUP LIMITED: ИНВЕСТИРУЙТЕ В БУДУЩЕЕ</h2>
            <span class="title-border"></span>
        </div>
        <!-- title-bar -->

        <div class="row text-center">
            <div class="col-md-4 wow fadeInLeft animated" style="visibility: visible; animation-name: fadeInLeft;">
                <div class="finance-wrapper wrapper-one">
                    <div class="caption">
                        <img src="/assets/site/img/icon/f4.png" alt="image">
                        <span class="hover-icon"><img src="/assets/site/img/icon/f1.png" alt="image"></span>
                    </div>

                    <h3>Будущее уже наступило</h3>
                    <p>И нам с вами стоит на нем зарабатывать уже сегодня. Мы отобрали 9 ключевых технологических направлений для инвестирования</p>
                    <a href="" class="link">Читать дальше</a>
                </div>
                <!-- finance-wrapper -->
            </div>

            <div class="col-md-4 wow fadeInLeft animated" data-wow-delay="0.5s" style="visibility: visible; animation-delay: 0.5s; animation-name: fadeInLeft;">
                <div class="finance-wrapper wrapper-two">
                    <div class="caption">
                        <img src="/assets/site/img/icon/f5.png" alt="image">
                        <span class="hover-icon"><img src="/assets/site/img/icon/f2.png" alt="image"></span>
                    </div>

                    <h3> Число стартапов растет ежесекундно</h3>
                    <p>Мы работаем с лучшими из них, выбирая стартапы со всего мира по четким критериям, гарантирующим успех - и стартапу и инвесторам</p>
                    <a href="" class="link">Читать дальше</a>
                </div>
                <!-- finance-wrapper -->
            </div>
            <div class="col-md-4 wow fadeInLeft animated" data-wow-delay="0.8s" style="visibility: visible; animation-delay: 0.8s; animation-name: fadeInLeft;">
                <div class="finance-wrapper wrapper-three">
                    <div class="caption">
                        <img src="/assets/site/img/icon/f6.png" alt="image">
                        <span class="hover-icon"><img src="/assets/site/img/icon/f3.png" alt="image"></span>
                    </div>

                    <h3>Air Solutions Group – это место для заработка</h3>
                    <p>Мы стартап о стартапах и для стартапов с инвестиционным потенциалом около 750 миллионов долларов. Вы должны стать его частью</p>
                    <a href="" class="link">Читать дальше</a>
                </div>
                <!-- finance-wrapper -->
            </div>
        </div>
    </div>
</section>
<section class="counting-section" style="position: unset;">
    <div class="container text-center">
        <h2 class="hidden">counting-title</h2>
        <div class="counting-pusher">
            <div class="counting-wrapper wow bounceIn animated" style="visibility: visible; animation-name: bounceIn;">
                <div class="count-description inline-block">
                    <span class="timer">213420</span>
                </div>
                <p>+Клиентов</p>
            </div>
            <div class="counting-wrapper wow bounceIn animated" data-wow-delay="0.2s" style="visibility: visible; animation-delay: 0.2s; animation-name: bounceIn;">
                <div class="count-description inline-block">
                    <span class="timer">263</span>
                </div>
                <p>Инвестора со всего мира работают в команде</p>
            </div>
            <div class="counting-wrapper wow bounceIn animated" data-wow-delay="0.4s" style="visibility: visible; animation-delay: 0.4s; animation-name: bounceIn;">
                <div class="count-description inline-block">
                    <span class="timer">138900</span> <i class="fa fa-doll" aria-hidden="true"></i>
                </div>
                <p>Среднее пополнение клиента</p>
            </div>
            <div class="counting-wrapper wow bounceIn animated" data-wow-delay="0.6s" style="visibility: visible; animation-delay: 0.6s; animation-name: bounceIn;">
                <div class="count-description inline-block">
                    <span class="timer">3</span>
                </div>
                <p>Года работы</p>
            </div>
        </div>
    </div>
    <div class="row" style="margin-top: 42px; text-align:center;">
        <div class="link-button wow tada animated animated animated" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: tada;">
            <a [routerLink]="['/cabinet']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active" class="btn btn-default"><i class="fa fa-user-plus"></i> Присоединяйтесь к нам прямо сейчас</a>
        </div>
    </div>
</section>
<section class="advice-section section-padding">
    <div class="container">
        <div class="title-bar text-center">
            <h2 class="title">О компании</h2>
            <span class="title-border"></span>
        </div>
        <!-- title-bar -->
        <div class="row">
            <div class="col-md-7">
                <div id="advice-carousel" class="carousel slide">
                    <div class="carousel-inner wow bounceInLeft animated" style="visibility: visible; animation-name: bounceInLeft;">
                        <div class="item active">
                            <h3>Air Solutions Group / INSIDER BUSINESS GROUP LIMITED: ИНВЕСТИРУЙТЕ В БУДУЩЕЕ.</h3>
                            <p>Air Solutions Group создано инвесторами и для инвесторов с целью помочь максимально эффективно вкладывать средства в новые технологии в сфере интернет-бизнеса. Наши офисы есть в Нью-Йорке, Москве, Амстердаме и Стокгольме. Наша
                                специализация — стартапы в сфере интернет-бизнеса, которые динамично развиваются, либо имеют большой скрытый потенциал. Проекты являют собой как исключительно медийные стартапы, так и e-commerce, технология Blockchain и
                                ее адаптация, Bitcoin и прочие криптовалюты, виртуальная реальность, 3D-концепты, искусственный интеллект и робототехника. Мы собрали международную команду инвестбанкиров, юристов, финансовых аналитиков, трейдеров, менеджеров
                                и программистов, чтобы это будущее монетизировать.</p>
                            <p>Полное название нашей компании «Insider Business Group Limited». Мы работаем согласно международному праву, а также этическому кодексу современного стартап-предпринимателя. Наша онлайн-платформа — это современный и защищенный
                                веб-сайт, служащий инструментом для получения дохода при доверительных отношениях между нами и клиентами. Он позволяет нам взаимодействовать наиболее эффективно с точки зрения инвестиций, прибыли и дивидендов. Приглашаем
                                стать нашим новым инвестором и стабильно зарабатывать на будущем!</p>
                        </div>
                    </div>
                </div>
                <!-- advice-carousel -->
            </div>
            <div class="col-md-1"> </div>
            <div class="col-md-4">
                <div class="title-bar text-center" style="margin-bottom: 60px;">
                    <h3 class="title">Документы компании</h3>
                    <span class="title-border"></span>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-6">
                    <div class="portfolio-item wow bounceIn animated" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: bounceIn;">
                        <div class="portfolio-image">
                            <a data-fancybox="gallery" href="/assets/site/img/reg-mini.jpg"><img style="width: 100%;" src="/assets/site/img/reg-mini.jpg"></a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-6">
                    <div class="portfolio-item wow bounceIn animated" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: bounceIn;">
                        <div class="portfolio-image">
                            <a data-fancybox="gallery" href="/assets/site/img/sv-mini.jpg"><img style="width: 100%;" src="/assets/site/img/sv-mini.jpg"></a>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12" style="font-size: 10px;text-align: center;"> СВИДЕТЕЛЬСТВО O РЕГИСТРАЦИИ ЮРИДИЧЕСКОГО ЛИЦА <br>Insider Business Group Limited <br> Company Number: 2780528</div>
                <div class="col-md-12 col-sm-12 col-xs-12 text-center">
                    <a target="_blank" href="https://opencorporates.com/" class="btn btn-default wow fadeInUp" data-wow-delay="0.1s" style=" visibility: hidden; animation-delay: 0.1s; animation-name: none;"><i class="ei-store-search"></i> ПРОВЕРИТЬ ДОКУМЕНТЫ</a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="call-to-action-two section-padding">
    <div class="container">
        <div class="title-bar text-center">
            <h2 class="title">Ваши возможности</h2>
            <span class="title-border"></span>
        </div>
        <!-- title-bar -->
        <div class="action-wrapper">
            <div class="row">
                <div class="col-sm-10 col-sm-offset-1">
                    <div class="row">
                        <div class="flat-wrapper" style="padding: 0px;">
                            <div class="col-md-8 col-sm-8">
                                <div class="flat-iconbox clearfix">
                                    <div class="item-two-column">
                                        <div class="iconbox wow bounceIn animated" data-wow-delay="0s" style="visibility: visible; animation-delay: 0s; animation-name: bounceIn;">
                                            <div class="box-header">
                                                <div class="box-icon">
                                                    <i class="fa fa-area-chart"></i>
                                                </div>
                                                <h4 class="box-title">Стабильный доход – 1.5% в день</h4>
                                            </div>
                                            <div class="box-content">Выгодные инвестиционные предложения, позволяющие получить прибыль за минимальное количество времени.
                                                <p class="box-readmore">
                                                    <a href="">Читать подробнее</a>
                                                </p>
                                            </div>
                                        </div>
                                        <!-- /.iconbox -->
                                    </div>
                                    <div class="item-two-column">
                                        <div class="iconbox wow bounceIn animated" data-wow-delay="0.2s" style="visibility: visible; animation-delay: 0.2s; animation-name: bounceIn;">
                                            <div class="box-header">
                                                <div class="box-icon">
                                                    <i class="fa fa-pie-chart"></i>
                                                </div>
                                                <h4 class="box-title">Создание капитала</h4>
                                            </div>
                                            <div class="box-content">Несколько вариантов активного дохода, которые просты и доступны абсолютно каждому. Модель инвестирования от 1 до 30 дней с динамичной системой бонусов.
                                                <p class="box-readmore">
                                                    <a href="">Читать подробнее</a>
                                                </p>
                                            </div>
                                        </div>
                                        <!-- /.iconbox -->
                                    </div>
                                    <div class="item-two-column">
                                        <div class="iconbox wow bounceIn animated" data-wow-delay="0.4s" style="visibility: visible; animation-delay: 0.4s; animation-name: bounceIn;">
                                            <div class="box-header">
                                                <div class="box-icon">
                                                    <i class="fa fa-bank"></i>
                                                </div>
                                                <h4 class="box-title">Автоматический вывод средств</h4>
                                            </div>
                                            <div class="box-content">Мы гарантируем вывод ваших средств в течение 24-х часов после окончания срока инвестиций. Этот процесс полностью автоматизирован.
                                                <p class="box-readmore">
                                                    <a href="">Читать подробнее</a>
                                                </p>
                                            </div>
                                        </div>
                                        <!-- /.iconbox -->
                                    </div>
                                    <!-- /.item-three-column -->
                                    <div class="item-two-column">
                                        <div class="iconbox wow bounceIn animated" data-wow-delay="0.6s" style="visibility: visible; animation-delay: 0.6s; animation-name: bounceIn;">
                                            <div class="box-header">
                                                <div class="box-icon">
                                                    <i class="fa fa-bar-chart-o"></i>
                                                </div>
                                                <h4 class="box-title">Минимальная инвестиция - 250 $.</h4>
                                            </div>
                                            <div class="box-content">Выгодные инвестиционные предложения, позволяющие получить максимальную прибыль за минимальное количество времени.
                                                <p class="box-readmore">
                                                    <a href="">Читать подробнее</a>
                                                </p>
                                            </div>
                                        </div>
                                        <!-- /.iconbox -->
                                    </div>
                                    <!-- /.item-three-column -->
                                </div>
                            </div>
                            <div class="col-md-4  col-sm-4">
                                <div class="noname wow bounceInRight animated" data-wow-delay="0.3s" style="padding: 0px 15px; margin-bottom: 30px; display: table-cell; visibility: visible; animation-delay: 0.3s; animation-name: bounceInRight;">
                                    <div class="quick-form">
                                        <h4 style="font-size: 20px;">Преимущества работы со AirSolution Group:</h4>
                                        <ul class="flat-list style1 style2">
                                            <li class="active">Лучшая команда аналитиков</li>
                                            <li class="active">Стабильный доход — от 1.5% в день</li>
                                            <li class="active">Минимальная инвестиция — 250 USD.</li>
                                            <li class="active">Полная прозрачность в работе с инвестициями</li>
                                            <li class="active">Автоматический вывод средств</li>
                                            <li class="active">Работа с разными платежными системами</li>
                                            <li class="active">Гибкие тарифные планы, долгосрочные инвестиции</li>
                                            <li class="active">Гарантии согласно международному финансовому праву</li>
                                            <li class="active">Онлайн-поддержка 24/7 и мощная защита от DDoS</li>
                                            <li class="active">SSL-сертификат по стандарту безопасности PCI DSS</li>
                                        </ul>
                                        <form id="request-call-back" method="post" action="" novalidate="novalidate">
                                            <div class="button text-center wow fadeInUp animated animated" style="visibility: hidden; animation-name: none;">
                                                <a style="white-space: nowrap;" href="#" data-toggle="modal" data-target=".modal3"><i class="fa fa-calculator" aria-hidden="true"></i> Калькулятор доходности</a>
                                            </div>
                                        </form>
                                        <p></p>
                                    </div>
                                </div>
                            </div>
                            <!-- /.flat-iconbox -->
                        </div>
                        <!-- /.flat-wrapper -->
                    </div>
                    <div class="button text-center wow fadeInUp animated" style="visibility: hidden; animation-name: none;">
                        <a [routerLink]="['/cabinet']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active"><i class="ti-pencil-alt"></i> Открыть аккаунт прямо сейчас</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- action-wrapper -->
    </div>
</section>
<section class="testimonial-section-two section-padding">
    <div class="container">
        <div class="title-bar text-center wow fadeInDown animated animated" style="visibility: visible; animation-name: fadeInDown;">
            <h2 class="title">Список некоторых стартапов, в которых мы инвестируем</h2>
            <span class="title-border"></span>
            <p>Мы убеждены, что открытость и прозрачность - залог долговечного <br>сотрудничества и непрерывного доверия</p>
        </div>
        <!-- title-bar -->
        <div id="testimonial-carousel" class="carousel slide text-center" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#testimonial-carousel" data-slide-to="0" class="active"></li>
                <li data-target="#testimonial-carousel" data-slide-to="1"></li>
                <li data-target="#testimonial-carousel" data-slide-to="2"></li>
                <li data-target="#testimonial-carousel" data-slide-to="3"></li>
            </ol>
            <div class="carousel-inner">
                <div class="item active">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="testimonial-wrapper text-center wow animated fadeInLeft animated" data-wow-delay="0.2s" style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInLeft;">
                                <span class="testimonial-caption"><img src="/assets/site/img/ico/bitcoin3.gif" alt="image" class="wow flipInX animated animated" data-wow-delay="0.6s" style="animation-delay: 0.6s; visibility: visible; animation-name: flipInX;"></span>
                                <h3>BitCoin, Ethereum, Dash и не только</h3>
                                <span class="position">Портфель «криптовалюты» </span>
                                <ul class="commend">
                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-star-half-o" aria-hidden="true"></i></li>
                                </ul>
                                <p align="left">Инвестирование в криптовалюты как один из главных приоритетов нашей компании. То, что сегодня происходит в мире технологий «blockchain» и «криптовалют» – просто шокирует. Этим интересуются все, но не все до конца понимают,
                                    что это такое, и как на этом зарабатывать. Мы не только понимаем, но уже зарабатываем. В нашем инвестиционном портфеле криптовалютам отведено особое место, которое сегодня составляет от 35% до 65% от общего объёма инвестиций.
                                    Команда Air Solutions Group сосредоточена на ключевых и самых надежных криптовалютах...

                                    <a href="#read1" data-toggle="collapse" aria-expanded="true">Читать дальше (кликните для показа/скрытия)</a></p>
                                <div id="read1" class="collapse" aria-expanded="false" style="">
                                    <p align="left">...динамика развития которых говорит сама за себя. Посмотрите сами, как быстро BitCoin практически достиг отметки $7400 со 100$ за единицу, а хардфорк Bitcoin состоялся раньше, чем планировалось. Но это не предел: по
                                        мнению соучредителя FundStrat Global Advisor Тома Ли, скоро цена биткоина перевалит отметку в 25 000 долларов США, причем уже совсем скоро, если криптовалюта займет 5% инвестиционных портфелей.<br> Позитивная динамика
                                        и в Ethereum – второй по популярности криптовалюты мира: цены на эфир продолжают оставаться высокими, несмотря на падение котировок после хардфорка Byzantium. Сегодня они в районе $300.<br> Ежедневно, даже ежесекундно,
                                        мы отслеживаем тенденции в мире «блокчейн» и действуем: инвестируем, принимаем участие в разработках платформ, ведь криптовалюты – это будущее. Мы не только в это верим, мы ради этого работаем, и зарабатываем. Присоединяйтесь!</p>
                                </div>
                            </div>
                            <!-- testimonial-wrapper -->
                        </div>
                        <div class="col-sm-6 hidden-xs">
                            <div class="testimonial-wrapper text-center wow fadeInRight animated animated" data-wow-delay="0.2s" style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInRight;">
                                <span class="testimonial-caption"><img src="/assets/site/img/ico/bitcoin-0-0.gif" alt="image" class="wow flipInX animated animated" data-wow-delay="0.6s" style="animation-delay: 0.6s; visibility: visible; animation-name: flipInX;"></span>
                                <h3 style="font-size: 18px;">Россия вперед: Наши интересы в Blackmoon, BioCoin, Car Taxi и не только</h3>
                                <span class="position">Русские стартапы из сферы «BlockChain»</span>
                                <ul class="commend">
                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-star-half-o" aria-hidden="true"></i></li>
                                </ul>
                                <p align="left">Мы не только были свидетелями того, как наши отечественные блокчейн-проекты шокировали весь мир и ставили рекорды по привлеченным инвестициям через ICO, мы были непосредственными участниками этого шоу. И, как говорится,
                                    show must go on!<br> В какой-то степени успех BioCoin, Car Taxi, и, особенно, Blackmoon, стал мотивом для нас создать на базе компании Air Solutions Group собственную веб-платформу для привлечения инвестиций. <br>
                                    <a href="#read2" data-toggle="collapse" aria-expanded="true">Читать дальше (кликните для показа/скрытия)</a>
                                </p>
                                <div id="read2" class="collapse" aria-expanded="false" style="">
                                    <p align="left">Пока что мы лишь рассматриваем технологию «блокчейн» как один из способов развития нашей компании и, возможно, совсем скоро сами заявим о ICO, как это в сентябре сделали ребята из Blackmoon, и в итоге привлекли суммарно
                                        $30 миллионов. Кто знает, может нам удастся даже побить этот рекорд.<br> Сегодня же мы сосредоточены на успехе именно этих проектов, ведь стали теми, кто приобрел их токены и планируем принимать активное участие
                                        в развитии данных стартапов, в том числе, с помощью нашей собственной веб-платформы и новых инвесторов.<br> Присоединяйтесь к нашей команде и, даже если вы ничего не смыслите в эвакуаторах или органическом сельском
                                        хозяйстве, наши методы инвестирования гарантируют вам прибыль. </p>
                                </div>
                                <p></p>
                            </div>
                            <!-- testimonial-wrapper -->
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="testimonial-wrapper text-center animated fadeInLeft">
                                <span class="testimonial-caption"><img src="/assets/site/img/ico/bitcoin-2-2.gif" alt="image" class="flipInX animated" style="-webkit-animation-delay: 0.2s; animation-delay: 0.2s;"> </span>
                                <h3>Рабочее название «E-farm»</h3>
                                <span class="position">Стартап из сферы «E-Commerce &amp; BlockChain»</span>
                                <ul class="commend">
                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-star-half-o" aria-hidden="true"></i></li>
                                </ul>
                                <p align="left">Это стартап об электронной коммерции, который являет собой современную платформу с частично открытым кодом для ее адаптации под нужды предпринимателей по принципу agile. Как это работает: любое усовершенствование в рамках
                                    системы, которое помогло отдельному предпринимателю увеличить продажи или улучшить, скажем, логистику, сразу же становится доступным в виде готового продукта/инструмента для других участников платформы. И за это нововведение
                                    ее разработчик получает процент от продаж...<br><a href="#read3" data-toggle="collapse" aria-expanded="true">Читать дальше (кликните для показа/скрытия)</a></p>
                                <div id="read3" class="collapse" aria-expanded="false" style="">
                                    <p align="left">...других участников, которые внедрили этот инструмент. Внутри платформы также предусмотрена криптовалюта «coin»</p>
                                </div>
                                <p></p>
                            </div>
                            <!-- testimonial-wrapper -->
                        </div>
                        <div class="col-sm-6 hidden-xs">
                            <div class="testimonial-wrapper text-center animated fadeInRight">
                                <span class="testimonial-caption"><img src="/assets/site/img/ico/bitcoin-5-5.gif" alt="image" class="flipInX animated" style="-webkit-animation-delay: 0.2s; animation-delay: 0.2s;"></span>
                                <h3>Рабочее название «D-Fusion»</h3>
                                <span class="position">Стартап из сферы «BlockChain»</span>
                                <ul class="commend">
                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-star-half-o" aria-hidden="true"></i></li>
                                </ul>
                                <p align="left">Речь идет о платформе, которая создана для того, чтобы помогать другим стартапам привлекать инвестиции в разных криптовалютах в рамках Itial Coin Offering. Создатели говорят о глобальном рынке стартапов, которые работают,
                                    создают и развивают собственные криптовалюты и готовы привлечь инвестиции под собственный проект в других криптовалютах. Авторы стартапа планируют, что проект будет работать как своеобразная биржа, где процесс ICO очень
                                    динамичный и, возможно, даже постоянный.<br> <a href="#read4" data-toggle="collapse" aria-expanded="true">Читать дальше (кликните для показа/скрытия)</a></p>
                                <div id="read4" class="collapse" aria-expanded="false" style="">
                                    <p align="left">Мы подписали этот стартап в Берлине. Ребята показались нам не просто амбициозными, но и очень квалифицированными. Гении в общем.</p>
                                </div>
                                <p></p>
                            </div>
                            <!-- testimonial-wrapper -->
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="testimonial-wrapper text-center animated fadeInLeft">
                                <span class="testimonial-caption"><img src="/assets/site/img/ico/bitcoin-8-8.gif" alt="image" class="flipInX animated" style="-webkit-animation-delay: 0.2s; animation-delay: 0.2s;"></span>
                                <h3>Рабочее название «Pantera Market»</h3>
                                <span class="position">Стартап из сферы «BlockChain &amp; Gaming»</span>
                                <ul class="commend">
                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-star-half-o" aria-hidden="true"></i></li>
                                </ul>
                                <p align="left">Идея стартапа заключается в том, чтобы интегрировать продажу компьютерных игр и, собственно, саму игру – от прибыли и бонусов до организации турниров, их трансляций и ставок на тотализаторе. Потенциальная прибыль от такой
                                    платформы измеряется миллиардами долларов, хотя создатели стартапа говорят о собственной внутренней криптовалюте, которая должна стать также инструментом мультипликации как платформы, так и масштабирования всего бизнеса.
                                    Идея принадлежит двум израильтянам и одному британцу, которые...<br><a href="#read5" data-toggle="collapse" aria-expanded="true">Читать дальше (кликните для показа/скрытия)</a></p>
                                <div id="read5" class="collapse" aria-expanded="false" style="">
                                    <p align="left">...сегодня работают в Сингапуре, а основную поддержку получают от Air Solutions Group, и этим очень довольны.</p>
                                </div>
                                <p></p>
                            </div>
                            <!-- testimonial-wrapper -->
                        </div>
                        <div class="col-sm-6 hidden-xs">
                            <div class="testimonial-wrapper text-center animated fadeInRight">
                                <span class="testimonial-caption"><img src="/assets/site/img/ico/bitcoin9-9.gif" alt="image" class="flipInX animated" style="-webkit-animation-delay: 0.2s; animation-delay: 0.2s;"></span>
                                <h3>Рабочее название «3D Face ID»</h3>
                                <span class="position">Стартап из сферы «VR» </span>
                                <ul class="commend">
                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-star-half-o" aria-hidden="true"></i></li>
                                </ul>
                                <p align="left">Этот проект начинался как мобильное приложение. Но уже сейчас очевидно, что его потенциал – это даже не стартап, а полноценный бизнес уже через 2-3 года, который можно легко масштабировать. Суть вот в чем: с помощью мобильного
                                    телефона вы можете идентифицировать людей, дома, улицы, скульптуры, получать о них нужную информацию мгновенно, а также всеми этими данными управлять в реальному времени. Речь идет о технологии виртуальной реальности
                                    (VR). Что-то похожее так помпезно недавно презентовал Apple, анонсируя новый iPhone. <a href="#read6" data-toggle="collapse" aria-expanded="true">Читать дальше (кликните для показа/скрытия)</a></p>
                                <div id="read6" class="collapse" aria-expanded="false" style="">
                                    <p align="left">Над подобным совместно трудятся Марк Цукерберг и Samsung. Хоть наш стартап родом из Беларуси, о нем уже наслышаны в Кремневой долине, потому что он опережает всех ближайших конкурентов.</p>
                                </div>
                                <p></p>
                            </div>
                            <!-- testimonial-wrapper -->
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="testimonial-wrapper text-center animated fadeInLeft">
                                <span class="testimonial-caption"><img src="/assets/site/img/ico/blocka.gif" alt="image" class="flipInX animated" style="-webkit-animation-delay: 0.2s; animation-delay: 0.2s;"></span>
                                <h3>Рабочее название «Empire»</h3>
                                <span class="position">Стартап из сферы «BlockChain»</span>
                                <ul class="commend">
                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-star-half-o" aria-hidden="true"></i></li>
                                </ul>
                                <p>Этот стартап мы рассмотрели на «пичинге» одной из конференций в Лиссабоне. Чем он нам приглянулся? «Empire» создан, чтобы решать реальные задачи с помощью технологий будущего, а также на их основе самосовершенствоваться
                                    и отвечать новым вызовам. Идея в том, чтобы очень оперативно и очень точно подбирать под конкретную задачу лучших специалистов со всего мира и контролировать достижение нужного результата. Взаимоотношения внутри платформы
                                    выстраиваются через систему индексов/маркеров и внутреннюю криптовалюту «unit».</p>
                            </div>
                            <!-- testimonial-wrapper -->
                        </div>
                    </div>
                </div>
            </div>
            <a class="left carousel-control" href="#testimonial-carousel" role="button" data-slide="prev"><i class="fa fa-long-arrow-left" aria-hidden="true"></i>ПРЕД.</a>
            <a class="right carousel-control" href="#testimonial-carousel" role="button" data-slide="next">СЛЕД. <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a>
        </div>
    </div>
</section>

<section class="video-play dark dark-strong" style="margin-top:0">
    <div id="P1" class="player mb_YTPlayer isMuted" data-property="{videoURL:'https://www.youtube.com/watch?v=vMZGdFal7jA',containment:'#P1',startAt:15,mute:true,autoPlay:true,opacity:1}">
        <span id="controlBar_P1" class="mb_YTPBar inlinePlayer compact" style="white-space: nowrap; position: absolute; z-index: 1000; display: inline-block;">
            <div class="buttonBar"><span class="mb_YTPPlaypause ytpicon">P</span><span class="mb_YTPMuteUnmute ytpicon">A</span>
        <div class="mb_YTPVolumeBar simpleSlider muted" style="display: inline-block; cursor: default;">
            <div class="level horizontal"></div>
        </div><span class="mb_YTPTime">00 : 16 / 01 : 03</span><span class="mb_YTPUrl ytpicon" title="view on YouTube">Y</span><span class="mb_OnlyYT ytpicon">O</span>
    </div>
    <div class="mb_YTPProgress" style="position: absolute;">
        <div class="mb_YTPLoaded" style="position: absolute; left: 0px; width: 56.663%;"></div>
        <div class="mb_YTPseekbar" style="position: absolute; left: 0px; width: 0px;"></div>
    </div>
    </span>
    <div class="mbYTP_wrapper" id="wrapper_P1" style="position: absolute; z-index: 0; min-width: 100%; min-height: 100%; left: 0px; top: 0px; overflow: hidden; opacity: 1; transition-property: opacity; transition-duration: 1000ms;">

        <iframe id="iframe_P1" class="playerBox" style="position: absolute; z-index: 0; width: 100%; height: 656px; top: 0px; left: 0px; overflow: hidden; opacity: 1; user-select: none; margin-top: -96px; margin-left: 0px; max-width: initial; transition-property: opacity; transition-duration: 1000ms;"
            frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" title="YouTube video player" width="640" height="360" src="https://www.youtube.com/embed/vMZGdFal7jA?modestbranding=1&amp;autoplay=0&amp;controls=0&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1&amp;version=3&amp;playerapiid=iframe_P1&amp;origin=https%3A%2F%2Fairsolutions.site&amp;allowfullscreen=true&amp;wmode=transparent&amp;iv_load_policy=3&amp;html5=1&amp;widgetid=1"
            unselectable="on"></iframe>
        <div class="YTPOverlay" style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%;"></div>
    </div>
    <div class="container dark-content" style="position: relative;">

        <div class="row">
            <div class="col-md-12 text-center">
                <div class="title wow fadeInLeft animated animated" style="visibility: visible; animation-name: fadeInLeft;">Промо-ролик о нашей работе</div>
                <a data-fancybox="" href="https://www.youtube.com/watch?v=vMZGdFal7jA" class="ei ei-play-2 wow pulse animated animated" style="animation-iteration-count: 100; visibility: visible; animation-name: pulse;"></a>
                <!--    <em>Чтобы еще раз прокрутить все в голове и расставить пополочках, советуем просмотреть наш ролик.</em>  -->

                <div class="duration">
                    <div class="link-button wow fadeInDown animated animated animated" data-wow-delay="0.5s" style="visibility: visible; animation-delay: 0.5s; animation-name: fadeInDown;">
                        <a href="" class="btn btn-primary"><i class="ti-stats-up"></i> Инвестировать прямо сейчас</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</section>

<section class="contact-section">
    <div class="contact-section-one">
        <div class="container">
            <div class="section-one-wrapper">
                <div class="row">
                    <h3>Контактные данные нашего центра</h3>
                    <div class="col-md-7 section-left">
                        <div class="contact-wrapper">
                            <p>Мы убеждены, что коммуникация является одним из ключевых инструментов успеха в нашем деле. Выберите отдел, и мы обязательно ответим вам в кратчайшие сроки.</p>
                            <ul class="location">
                                <li>
                                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                                    <div class="content">
                                        <span class="text"><strong>Адрес нашего офиса:</strong> <br>Россия, 123317, Москва, <br> Пресненская набережная 12, Корпус 2, этаж 12, офис 37</span>
                                    </div>
                                </li>
                                <li>
                                    <i class="fa fa-phone" aria-hidden="true"></i>
                                    <span class="text">+3 (712) 674 22 42<i style="font-size:  15px;padding: 5px;color: #7d818a;">(ПН-ПТ: 9:00 - 21:00 по МСК)</i></span>
                                </li>
                                <li>
                                    <i class="fa fa-envelope-o" aria-hidden="true"></i>
                                    <span class="text">support@airsolutionsgroup.club</span>
                                </li>
                            </ul>
                        </div>
                        <!-- contact-wrapper -->
                    </div>
                    <div class="col-md-5">
                        <h3>Форма обратной связи </h3>
                        <div class="contact-wrapper">
                            <form class="contact-form" method="post" action="" id="form_3">
                                @csrf
                                <div class="form-group">
                                    <input type="text" class="form-control" required="" name="name_3" placeholder="Ваше имя">
                                </div>
                                <div class="form-group">
                                    <input class="contactSearchBar" value="Email" onblur="if(this.value=='')this.value='Email'" onfocus="if(this.value=='Email')this.value=''" name="email_3" required="" type="email">
                                </div>
                                <div class="form-group">
                                    <input type="phone" class="form-control" required="" name="phone_3" placeholder="Номер телефона">
                                </div>
                                <div class="form-group">
                                    <textarea class="form-control" required="" name="message_3" placeholder="Ваше сообщение" rows="3"></textarea>
                                </div>
                                <div class="form-group">
                                    <button type="submit" class="btn btn-default"><i class="fa fa-paper-plane-o"></i> Отправить</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- contact-section-one -->
</section>