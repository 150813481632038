import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ActiveWithdrawComponent } from './active-withdraw.component';



@NgModule({
  declarations: [ ActiveWithdrawComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports:[ 
    ActiveWithdrawComponent
  ]
})
export class ActiveWitdrawModule { }
