<div class="uk-grid ">

    <div class="uk-width-1-1 uk-margin-medium-bottom">
        <div class="uk-card uk-card-small uk-card-default">
            <div class="uk-card-body">
                <div style="overflow-x:auto;">
                    <table class="uk-table uk-table-striped uk-table-condensed uk-text-nowrap">
                        <thead>
                            <tr>
                                <th>№</th>
                                <th>Имя</th>
                                <th>Фамилия</th>
                                <th>Администратор</th>
                                <th>Баланс</th>
                                <th>Дата регистрации</th>
                                <th>Детальный просмотр</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr *ngFor="let user of list_users">
                                <td>{{user.id}}</td>
                                <td>{{user.name}}</td>
                                <td>{{user.family}}</td>
                                <td>
                                    <ng-container *ngIf="user.is_admin==1">Администратор</ng-container>
                                    <ng-container *ngIf="user.is_admin==0">Пользователь</ng-container>

                                </td>
                                <td>{{user.balance}}</td>
                                <td>{{user.created_at}}</td>
                                <td><a (click)="show(user.id)" class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" style="border-radius: 50px " tabindex="1">Просмотр</a></td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</div>