<!--link rel="stylesheet" href="/assets/auth/css/app.css"-->
<div id="elem"></div>
<div class="container sm:px-10">
    <div class="block xl:grid grid-cols-2 gap-4">
        <app-auth-header></app-auth-header>
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
            <div class="my-auto mx-auto xl:ml-20 bg-white xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
                <h2 class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
                    Введите новый пароль
                </h2>
                <div class="intro-x mt-8">
                    <input type="text" class="intro-x login__input input input--lg border border-gray-300 block mt-4" placeholder="Пароль">
                    <div class="intro-x w-full grid grid-cols-12 gap-4 h-1 mt-3">
                        <div class="col-span-3 h-full rounded bg-theme-9"></div>
                        <div class="col-span-3 h-full rounded bg-theme-9"></div>
                        <div class="col-span-3 h-full rounded bg-theme-9"></div>
                        <div class="col-span-3 h-full rounded bg-gray-200"></div>
                    </div>
                    <a href="" class="intro-x text-gray-600 block mt-2 text-xs sm:text-sm">Защищен ли пароль</a>
                    <input type="text" class="intro-x login__input input input--lg border border-gray-300 block mt-4" placeholder="Повторите пароль">
                </div>
                <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
                    <button class="button button--lg w-full xl:w-32 text-gray-700 border border-gray-300 mt-3 xl:mt-0">Сохранить</button>
                </div>
            </div>
        </div>
        <!-- END: Login Form -->
    </div>
</div>