import { Component, OnInit, OnDestroy } from '@angular/core';
import { PayService } from 'src/app/LogicApi/Pay/pay.service';
import { AuthService } from 'src/app/LogicApi/Auth/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.sass']
})
export class PayComponent implements OnInit,OnDestroy {

  constructor(private pay:PayService,public auth:AuthService) { }
  type_show="close_withdraw";
  close_withdraw=null;
  active_withdraw=null;
  lost_withdraw=null;
  kurs_usd_rub=0;
  bools=false;
  private subscriptions: Subscription[] = [];

  ngOnInit(): void {

    this.pay.list_pay().then((res:any)=>
    {
      this.kurs_usd_rub=res.kurs_usd_rub;
      this.close_withdraw=res.data;
      this.active_withdraw=res.data2;
      this.lost_withdraw=res.data3;
      console.log(res);

    })
    const langSub = this.auth.UserData$
    .subscribe(() => {
      if(!this.bools)
      {
        this.kurs_usd_rub=this.auth.userdata.kurs;

        this.valute=this.auth.userdata.profile.currency;
        this.bools=true;
      }

    });
    this.subscriptions.push(langSub);
  }
  ngOnDestroy(): void {
    this.subscriptions
    .forEach(s => s.unsubscribe());
    }
    valute="";

  changes(id,event)
  {
    console.log(event);
    this.pay.pay_change(id,event.target.value).then((res)=>
    {
      console.log(res);
      
      this.pay.list_pay().then((res:any)=>
      {
        this.kurs_usd_rub=res.kurs_usd_rub;
        this.close_withdraw=res.data;
        this.active_withdraw=res.data2;
        this.lost_withdraw=res.data3;
        //console.log(res);

      })
    })


  }

  item_uk_switcher=1;
  change_menu(id)
  {
    console.log(id);
    
    this.item_uk_switcher=id;
  }

}
