import { AfterContentInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/LogicApi/Auth/auth.service';
import { InvestService } from 'src/app/LogicApi/Invest/invest.service';
import { ProfileService } from 'src/app/LogicApi/Profile/profile.service';
declare var $:any;

@Component({
  selector: 'app-my-data',
  templateUrl: './my-data.component.html',
  styleUrls: ['./my-data.component.sass']
})
export class MyDataComponent implements OnInit ,AfterContentInit,OnDestroy {

  constructor(private invest:InvestService,public auth :AuthService,private profile:ProfileService) { }
  email=""
  family=""
  name=""
  thirty_name=""
  data=""
  telephone=""
  balance=""
  valute=""
  photo=""
  created_at
  bools=false;
  isadmin=false;
   private subscriptions: Subscription[] = [];

  ngOnInit(): void {
    const langSub = this.auth.UserData$
    .subscribe(() => {
      if(!this.bools)
      {
        this.email=this.auth.userdata.profile.email;
        this.family=this.auth.userdata.profile.family;
        this.name=this.auth.userdata.profile.name;
        this.thirty_name=this.auth.userdata.profile.middlename;
        this.data=this.auth.userdata.profile.birthday;
        this.telephone=this.auth.userdata.profile.telephone;
        this.balance=this.auth.userdata.profile.balance;
        this.valute=this.auth.userdata.profile.currency;
        this.photo='/cabinet/profile/get_avatar/'+this.auth.userdata.profile.id
        this.created_at=this.auth.userdata.profile.created_at
        this.isadmin=this.auth.userdata.profile.is_admin

        this.bools=true;
      }

    });
  this.subscriptions.push(langSub);
  }
  ngOnDestroy(): void {
     this.subscriptions
     .forEach(s => s.unsubscribe());
   //  clearInterval(this.interval);
     }
   ngAfterContentInit(): void {

  }

  openfile(id)
  {
    $('#'+id).click();
  }

  personal_save()
  {
    this.profile.profile_data_update(this.email,this.name,this.family,this.thirty_name,this.data,this.telephone,
      this.valute,this.auth.userdata.profile.id,this.balance).then((res)=>{
        this.bools=false;
      })
  }
  onFileChanged(event: any) {
    this.profile.ImageUploadPost(event.target.files,this.auth.userdata.profile.id).then((res)=>{
      this.bools=false;
    })
    
  }
}
