import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayComponent } from './pay.component';
import { FormsModule } from '@angular/forms';
import { ActiveWitdrawModule } from './active-withdraw/active-withdraw.module';
import { CloseWithdrawModule } from './close-withdraw/CloseWithdraw.module';
import { LostWithdrawModule } from './lost-withdraw/LostWithdraw.module';



@NgModule({
  declarations: [ PayComponent],
  imports: [
    CommonModule,
    FormsModule,
    ActiveWitdrawModule,
    CloseWithdrawModule,
    LostWithdrawModule
  ],
  exports:[ 
    
  ]
})
export class PayModule { }
