import { Component, OnInit, OnDestroy } from '@angular/core';
import { PayService } from 'src/app/LogicApi/Pay/pay.service';
import { AuthService } from 'src/app/LogicApi/Auth/auth.service';
import { Subscription } from 'rxjs';
declare var $:any
@Component({
  selector: 'app-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.sass']
})
export class DepositComponent implements OnInit,OnDestroy {

  constructor(private pay:PayService,public auth:AuthService) { }
  ngOnDestroy(): void {
    this.subscriptions
    .forEach(s => s.unsubscribe());
    }
  list_items=null;
  summa=10;
  selected_type="";

  Merchand_id
  order_amount
  order_id
  sign
  id
  kurs_usd_rub:number=1;
  private subscriptions: Subscription[] = [];
  bools=false;
  valute="";
  balance=0;
  /*pay_serv()
  {
    if(this.auth.userdata.profile.currency=='ruble')
          {
            this.summa=this.summa/this.auth.userdata.kurs;
          }
    this.pay.create_deposit(this.summa).then(
      (res)=>
      {
        if(res!=null)
        {
          this.Merchand_id=res.Merchand_id
          this.order_amount=res.summa
          this.order_id=res.order_id
          this.sign=res.sign
          //$('form#form_pay')[0].submit()
        }
      }
    );
  }*/
  ngOnInit(): void {
    this.pay.indexdeposit().then((res)=>{
      this.list_items = res.data;
      console.log(res);
      
    });
    const langSub = this.auth.UserData$
    .subscribe(() => {
      if(!this.bools)
      {
        this.id=this.auth.userdata.profile.id;
        this.kurs_usd_rub=this.auth.userdata.kurs;

        this.balance=this.auth.userdata.profile.balance;
        this.valute=this.auth.userdata.profile.currency;
        this.bools=true;
      }

    });
    this.subscriptions.push(langSub);
  }

  
  rangeInputDepositChange() {
    let elem = $('#rangeInputDeposit')[0];
    console.log(elem);
    
    let sum:number=0;
    if(isNaN(parseFloat(elem.value))) {
        sum=0;
    }
    else
    {
        sum=elem.value;
    }


    let target:any = $('.rangeInputDepositShow')[0];
    let targetday:any = $('#order_amount')[0];
   // let targetterm:any = $('#term_price'+id)[0];

        target.innerHTML = parseInt(sum.toString());
          targetday.innerHTML =parseInt(sum.toString())
          // targetterm.innerHTML = (sum/100*(item.percent *item.count_days)).toFixed();
         
      
    }


    create_order(currency_type) {
      console.log('https://airsolutions.site/cabinet/pay/create_order_deposit/' + parseInt($("#rangeInputDeposit").val()));
      let summa;

      if(this.valute=='ruble')
      {
        summa = (parseInt($("#rangeInputDeposit").val(),10)/ this.kurs_usd_rub).toFixed()

      }
      else if(this.valute=='dollars')
      {
        summa = (parseInt($("#rangeInputDeposit").val(),10)).toFixed();

      }

      this.pay.create_deposit(summa).then((data)=>
      {
       
            if (data.success != true) {
                alert(data.error);
                return;
            }
            this.Merchand_id=data.Merchand_id
            this.order_amount=data.summa
            this.order_id=data.order_id
            this.sign=data.sign
            /*
            $("#order_amount").val(data.Merchand_id);
            $("#order_amount").val(data.summa); //// For Append
            $("#order_id").val(data.order_id); //// For Append
            $("#sign").val(data.sign); //// For Append
            $("#form_pay")[0].submit(); //// For replace with previous one
            */           
            window.location.href="https://www.free-kassa.ru/merchant/cash.php?m="+data.Merchand_id+"&oa="+data.summa+"&o="+data.order_id+"&s="+data.sign+"&lang=ru&us_login="+this.id
        
      },(err) =>{
        console.log(err);
    })
  }


  create_order_interkassa() {
    console.log('https://airsolutions.site/cabinet/pay/create_order_deposit/interkassa/' + parseInt($("#rangeInputDeposit").val()));
    let summa;

    if(this.valute=='ruble')
    {
      summa = (parseInt($("#rangeInputDeposit").val(),10)/ this.kurs_usd_rub).toFixed()

    }
    else if(this.valute=='dollars')
    {
      summa = ($("#rangeInputDeposit").val()).toFixed();

    }

    this.pay.create_deposit(summa).then((data)=>
    {
      console.log(data);
            if (data.success != true) {
              alert(data.error);
              return;
          }

         
          $("#form_interkassa").html(data.form); //// For Append
          $("#payment_interkassa").submit(); //// For replace with previous one
      
    },(err) =>{
      console.log(err);
  })
}
}
