import { Component, OnInit } from '@angular/core';
import { InvestService } from 'src/app/LogicApi/Invest/invest.service';

@Component({
  selector: 'app-investsettings',
  templateUrl: './investsettings.component.html',
  styleUrls: ['./investsettings.component.sass']
})
export class InvestsettingsComponent implements OnInit {

  constructor(private invest: InvestService) { }
  deposits_list=null;
  max_summa_deposit
  min_summa_deposit
  term_deposit
  procent_deposit
  name_deposit
  
  robots_list=null;

  max_summa_robots
  min_summa_robots
  term_robots
  procent_robots
  name_robots


  update_data()
  {
    this.invest.admin_investing().then((res)=>
    {
      this.deposits_list=res.list_deposit_type;
      this.robots_list=res.list_torg_robots_type
    })
  }
  ngOnInit(): void {
   this.update_data();
  }
  cancel_deposit(id)
  {
    this.invest.admin_invest_remove(id).then((res)=>{
      this.update_data();
    })
  }
  save_robots()
  {
    this.invest.admin_invest_create_post('invest_torgov_robots',this.name_robots,
      this.procent_robots,this.term_robots,this.min_summa_robots,this.max_summa_robots)
    .then((res)=>{
      this.name_robots=""
      this.procent_robots=""
      this.term_robots=""
      this.min_summa_robots=""
      this.max_summa_robots=""
      this.update_data();
     // console.log(res);

    })
  }
  save_deposit()
  {
    this.invest.admin_invest_create_post('invest_deposit',this.name_deposit,
      this.procent_deposit,this.term_deposit,this.min_summa_deposit,this.max_summa_deposit)
    .then((res)=>{
      this.name_deposit=""
      this.procent_deposit=""
      this.term_deposit=""
      this.min_summa_deposit=""
      this.max_summa_deposit=""
      this.update_data();
     // console.log(res);

    })
  }
  item_uk_switcher=1;
  change_menu(id)
  {
    console.log(id);
    this.item_uk_switcher=id;
  }
}
