<div class="uk-container uk-container-center">
    <ul class="uk-tab" data-uk-tab="{connect:'#my-id'}">
        <li (click)="change_menu(1)">
            <a>
                <h4>Личные данные</h4>
            </a>
        </li>
        <li (click)="change_menu(2)">
            <a>
                <h4>Безопастность</h4>
            </a>
        </li>

        <li (click)="change_menu(4)">
            <a>
                <h4>Загруженные документы</h4>
            </a>
        </li>
        <li (click)="change_menu(5)">
            <a>
                <h4>Депозиты</h4>
            </a>
        </li>
        <li (click)="change_menu(6)">
            <a>
                <h4>Торговые роботы</h4>
            </a>
        </li>
    </ul>
    <ul id="my-id" class="uk-switcher uk-margin">
        <li [ngClass]="{'uk-active': item_uk_switcher==1}">
            <div class="uk-grid">
                <div class="uk-width-1-3@s uk-width-1-1">
                    <div class="uk-card uk-card-small uk-card-default">
                        <div class="uk-card-header">
                            <div class="uk-grid uk-grid-small uk-text-small" data-uk-grid="">
                                <div class="uk-width-expand uk-first-column">
                                    <span class="cat-txt">{{family}} {{name}} </span>
                                </div>
                            </div>
                        </div>
                        <div class="uk-card-media">
                            <div class="uk-inline-clip uk-transition-toggle" tabindex="0">
                                <ng-container *ngIf="photo!=null && photo!=''">
                                    <img class="lazy" data-width="400" data-height="470" data-uk-img="" alt="" [src]="photo">

                                </ng-container>
                                <ng-container *ngIf="photo==null || photo==''">
                                    <img class="lazy" data-src="/img/favicon.98322d20.png" data-width="400" data-height="470" data-uk-img="" alt="" src="/img/favicon.98322d20.png">
                                </ng-container>
                            </div>
                        </div>
                        <div class="uk-card-body">
                            <div uk-form-custom>
                                <form>
                                    <div uk-form-custom="target: true" class="uk-width-1-1">
                                        <input name="image" id="el1" hidden (change)="onFileChanged($event)" type="file">
                                        <button type="button" (click)="openfile('el1')" class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" style="border-radius: 50px " tabindex="1">Выбрать файл</button>
                                    </div>
                                </form>
                            </div>
                            <span class="uk-margin-small-bottom uk-margin-remove-adjacent uk-text-bold"> {{family}} {{name}} </span>
                            <p class="uk-text-small uk-text-muted">Дата регистрации: {{created_at}}</p>
                        </div>

                    </div>

                </div>
                <div class="uk-width-2-3@s uk-width-1-1">

                    <div class="uk-card uk-card-small uk-card-default">
                        <div class="uk-card-header">
                            <div class="uk-grid uk-grid-small uk-text-medium" data-uk-grid="">
                                <div class="uk-width-expand uk-first-column">
                                    <span class="cat-txt">Ваши регистрационные данные </span>
                                </div>
                            </div>
                        </div>
                        <div class="uk-card-body">
                            <form>
                                <div class="uk-margin">
                                    <label class="uk-form-label" for="email">Email</label>
                                    <div class="uk-form-controls">

                                        <input class="uk-input" name="email" [(ngModel)]="email" id="email" style="border-radius: 50px " type="email" placeholder="">

                                    </div>
                                </div>
                                <div class="uk-margin">
                                    <label class="uk-form-label" for="name">Имя</label>
                                    <div class="uk-form-controls">
                                        <input class="uk-input" name="name" id="name" [(ngModel)]="name" style="border-radius: 50px " required type="text" placeholder="">
                                    </div>
                                </div>
                                <div class="uk-margin">
                                    <label class="uk-form-label" for="family">Фамилия</label>
                                    <div class="uk-form-controls">
                                        <input class="uk-input" name="family" id="family" [(ngModel)]="family" style="border-radius: 50px " required type="text" placeholder="">
                                    </div>
                                </div>
                                <div class="uk-margin">
                                    <label class="uk-form-label" for="middlename">Отчество</label>
                                    <div class="uk-form-controls">
                                        <input class="uk-input" name="thirty_name" [(ngModel)]="thirty_name" id="middlename" style="border-radius: 50px " required type="text" placeholder="">
                                    </div>
                                </div>
                                <div class="uk-margin">
                                    <label class="uk-form-label" for="birthday">Дата рождения</label>
                                    <div class="uk-form-controls">
                                        <input class="uk-input" name="data" [(ngModel)]="data" id="birthday" style="border-radius: 50px " required type="date" placeholder="">
                                    </div>
                                </div>
                                <div class="uk-margin">
                                    <label class="uk-form-label" for="telephone">Номер телефона</label>
                                    <div class="uk-form-controls">
                                        <input class="uk-input" id="telephone" [(ngModel)]="telephone" name="telephone" style="border-radius: 50px " required type="number" placeholder="">
                                    </div>
                                </div>

                                <div class="uk-margin">
                                    <label class="uk-form-label" for="balance">Баланс пользователя в $</label>
                                    <div class="uk-form-controls">

                                        <input class="uk-input" id="balance" [(ngModel)]="balance" name="balance" style="border-radius: 50px " required type="number" placeholder="">
                                    </div>
                                </div>

                                <div class="uk-margin">
                                    <label class="uk-form-label" for="currency">Выберите валюту</label>
                                    <div class="uk-form-controls">
                                        <select id="currency" name="currency" [(ngModel)]="valute" style="border-radius: 50px " class="uk-select">
                                                <ng-container *ngIf="valute=='ruble'">
                                                    <option  selected value="ruble">Рубли</option>
                                                    <option value="dollars">Доллары</option>
                                                </ng-container>
                                                <ng-container *ngIf="valute=='dollars'">
                                                    <option   value="ruble">Рубли</option>
                                                    <option selected value="dollars">Доллары</option>
                                                </ng-container>
                                        </select>
                                    </div>

                                </div>


                                <div class="uk-margin">
                                    <button (click)="personal_save()" class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" style="border-radius: 50px ">Сохранить</button>
                                </div>
                            </form>
                        </div>

                    </div>

                </div>
            </div>
        </li>
        <li [ngClass]="{'uk-active': item_uk_switcher==2}">
            <form class="uk-form-horizontal uk-margin-large">

                <div class="uk-margin">
                    <label class="uk-form-label">Новый пароль</label>
                    <div class="uk-form-controls">
                        <input class="uk-input" name="newpassword" [(ngModel)]="newpassword" style="border-radius: 50px " required type="password" minlength="8" placeholder="">
                    </div>
                </div>
                <div class="uk-margin">
                    <label class="uk-form-label" for="form-horizontal-text">Повторите пароль</label>
                    <div class="uk-form-controls">
                        <input class="uk-input" name="refreshpassword" [(ngModel)]="refreshpassword" style="border-radius: 50px " required type="password" minlength="8" placeholder="">
                    </div>
                </div>

                <div class="uk-margin">
                    <button (click)="change_password()" class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" style="border-radius: 50px ">Сохранить</button>
                </div>

            </form>
        </li>
        <li [ngClass]="{'uk-active': item_uk_switcher==4}">
            <div class="uk-grid">
                <div class="uk-width-1-1 uk-margin-top">
                    <div class="uk-card uk-card-small uk-card-default uk-card-body uk-margin-small-bottom uk-card-hover
                    uk-text-small">
                        <div class="uk-card-header">
                            <div class="uk-grid uk-grid-small uk-text-large" data-uk-grid="">
                                <div class="uk-width-expand uk-text-center">
                                    <span class="uk-text-emphasis ">Документы, подтверждающие личность</span>
                                </div>
                            </div>
                        </div>

                        <div class="uk-card-body">

                            <div style="overflow-x:auto;">
                                <table class="uk-table uk-table-middle uk-text-bold  uk-table-divider uk-table-hover">
                                    <thead class="uk-text-bold">
                                        <tr class="uk-text-center">
                                            <th>№</th>
                                            <th>Дата</th>
                                            <th>Фото</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr *ngFor="let item of passport">
                                            <td>{{item.id}}</td>
                                            <td>{{item.created_at}}
                                                <td>
                                                    <td><img class="lazy" data-width="400" data-height="470" data-uk-img="" alt="" src="/cabinet/profile/get_document/{{id}}/{{passport_tag}}/{{item.id}}"></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="uk-width-1-1 uk-margin-top">
                    <div class="uk-card uk-card-small uk-card-default uk-card-body uk-margin-small-bottom uk-card-hover
                    uk-text-small">
                        <div class="uk-card-header">
                            <div class="uk-grid uk-grid-small uk-text-large" data-uk-grid="">
                                <div class="uk-width-expand uk-text-center">
                                    <span class="uk-text-emphasis ">Документы, подтверждающие адрес</span>
                                </div>
                            </div>
                        </div>

                        <div class="uk-card-body">

                            <div style="overflow-x:auto;">
                                <table class="uk-table uk-table-middle uk-text-bold  uk-table-divider uk-table-hover">
                                    <thead class="uk-text-bold">
                                        <tr class="uk-text-center">
                                            <th>№</th>
                                            <th>Дата</th>
                                            <th>Фото</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of address">
                                            <td>{{item.id}}</td>
                                            <td>{{item.created_at}}
                                                <td>
                                                    <td><img class="lazy" data-width="400" data-height="470" data-uk-img="" alt="" src="/cabinet/profile/get_document/{{id}}/{{address_tag}}/{{item.id}}"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="uk-width-1-1 uk-margin-top">
                    <div class="uk-card uk-card-small uk-card-default uk-card-body uk-margin-small-bottom uk-card-hover
                    uk-text-small">
                        <div class="uk-card-header">
                            <div class="uk-grid uk-grid-small uk-text-large" data-uk-grid="">
                                <div class="uk-width-expand uk-text-center">
                                    <span class="uk-text-emphasis ">Фото пользователя с паспортом</span>
                                </div>
                            </div>
                        </div>

                        <div class="uk-card-body">

                            <div style="overflow-x:auto;">
                                <table class="uk-table uk-table-middle uk-text-bold  uk-table-divider uk-table-hover">
                                    <thead class="uk-text-bold">
                                        <tr class="uk-text-center">
                                            <th>№</th>
                                            <th>Дата</th>
                                            <th>Фото</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of photo_with_passport">
                                            <td>{{item.id}}</td>
                                            <td>{{item.created_at}}
                                                <td>
                                                    <td><img class="lazy" data-width="400" data-height="470" data-uk-img="" alt="" src="/cabinet/profile/get_document/{{id}}/{{photo_with_passport_tag}}/{{item.id}}"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </li>
        <li [ngClass]="{'uk-active': item_uk_switcher==5}">
            <div class="uk-grid">
                <div class="uk-width-1-1">

                    <div class="uk-width-1-1 uk-margin-top">
                        <div class="uk-card uk-card-small uk-card-default uk-card-body uk-margin-small-bottom uk-card-hover
                    uk-text-small">
                            <div class="uk-card-header">
                                <div class="uk-grid uk-grid-small uk-text-large" data-uk-grid="">
                                    <div class="uk-width-expand uk-text-center">
                                        <span class="uk-text-emphasis ">Ваши вклады</span>
                                    </div>
                                </div>
                            </div>

                            <div class="uk-card-body">

                                <div style="overflow-x:auto;">
                                    <table class="uk-table uk-table-middle uk-text-bold  uk-table-divider">
                                        <thead class="uk-text-bold">
                                            <tr>
                                                <th>Тип вклада</th>
                                                <th>Дата</th>
                                                <th>Сумма</th>
                                                <th>Прибыль</th>
                                                <th>Номер транзакции</th>
                                                <th>Статус</th>
                                                <th>Отмена</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngFor="let item_table of deposit_table_list">

                                                <tr *ngFor="let item of item_table[0]">
                                                    <td>{{item_table[1].name}}</td>
                                                    <td>{{item.created_at}}</td>
                                                    <td>
                                                        <ng-container *ngIf="valute=='ruble'">{{(item.summa * kurs_usd_rub).toFixed()}}Р</ng-container>
                                                        <ng-container *ngIf="valute=='dollars'">{{(item.summa).toFixed()}}</ng-container>

                                                    </td>
                                                    <td style="color:green;">
                                                        <ng-container *ngIf="valute=='ruble'">{{(item.profit * kurs_usd_rub).toFixed()}}Р</ng-container>
                                                        <ng-container *ngIf="valute=='dollars'">{{(item.profit).toFixed()}}</ng-container>

                                                    </td>
                                                    <td>{{item.id}}</td>
                                                    <ng-container *ngIf="item.status=='Обрабатывается'">
                                                        <td style="color:blue">{{item.status}}</td>
                                                    </ng-container>
                                                    <ng-container *ngIf="item.status=='Выполнено'">
                                                        <td style="color:red">{{item.status}}</td>
                                                    </ng-container>
                                                    <td>
                                                        <button (click)="cancel_deposit(item.id)" class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" style="border-radius: 50px ">Отменить</button> 
                                                    </td>
                                                </tr>


                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </li>
        <li [ngClass]="{'uk-active': item_uk_switcher==6}">
            <div class="uk-grid">
                <div class=" uk-width-1-1">

                    <div class="uk-width-1-1 uk-margin-top">
                        <div class="uk-card uk-card-small uk-card-default uk-card-body uk-margin-small-bottom uk-card-hover
                    uk-text-small">
                            <div class="uk-card-header">
                                <div class="uk-grid uk-grid-small uk-text-large" data-uk-grid="">
                                    <div class="uk-width-expand uk-text-center">
                                        <span class="uk-text-emphasis ">Ваши вклады</span>
                                    </div>
                                </div>
                            </div>

                            <div class="uk-card-body">

                                <div style="overflow-x:auto;">
                                    <table class="uk-table uk-table-middle uk-text-bold  uk-table-divider">
                                        <thead class="uk-text-bold">
                                            <tr>
                                                <th>Тип вклада</th>
                                                <th>Дата</th>
                                                <th>Сумма</th>
                                                <th>Прибыль</th>
                                                <th>Номер транзакции</th>
                                                <th>Статус</th>
                                                <th>Отмена</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngFor="let item_table of robots_table_list">

                                                <tr *ngFor="let item of item_table[0]">
                                                    <td>{{item_table[1].name}}</td>
                                                    <td>{{item.created_at}}</td>
                                                    <td>
                                                        <ng-container *ngIf="valute=='ruble'">{{(item.summa * kurs_usd_rub).toFixed()}}Р</ng-container>
                                                        <ng-container *ngIf="valute=='dollars'">{{(item.summa).toFixed()}}</ng-container>

                                                    </td>
                                                    <td style="color:green;">
                                                        <ng-container *ngIf="valute=='ruble'">{{(item.profit * kurs_usd_rub).toFixed()}}Р</ng-container>
                                                        <ng-container *ngIf="valute=='dollars'">{{(item.profit).toFixed()}}</ng-container>

                                                    </td>
                                                    <td>{{item.id}}</td>
                                                    <ng-container *ngIf="item.status=='Обрабатывается'">
                                                        <td style="color:blue">{{item.status}}</td>
                                                    </ng-container>
                                                    <ng-container *ngIf="item.status=='Выполнено'">
                                                        <td style="color:red">{{item.status}}</td>
                                                    </ng-container>
                                                    <td>
                                                        <button (click)="cancel_deposit(item.id)" class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" style="border-radius: 50px ">Отменить</button> 
                                                    </td>
                                                </tr>


                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </li>
    </ul>

</div>