import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonalcabinetComponent } from './personalcabinet.component';
import { RouterModule } from '@angular/router';
import { InvestComponent } from './invest/invest.component';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ProfileModule } from './profile/profile.module';
import { FinanceModule } from './finance/finance.module';
import { BuysellModule } from './buysell/buysell.module';
import { AdminModule } from './admin/admin.module';



@NgModule({
  declarations: [PersonalcabinetComponent,InvestComponent],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    FormsModule,
    ProfileModule,
    FinanceModule,
    BuysellModule,
    AdminModule
  ],
  bootstrap:[PersonalcabinetModule]
})
export class PersonalcabinetModule { }
