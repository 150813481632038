import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { VerificationComponent } from './verification.component';



@NgModule({
  declarations: [VerificationComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
  ],
  exports:[
    VerificationComponent
  ]
})
export class VerificationModule { }
