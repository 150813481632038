import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CloseWithdrawComponent } from './close-withdraw.component';



@NgModule({
  declarations: [ CloseWithdrawComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports:[ 
    CloseWithdrawComponent
  ]
})
export class CloseWithdrawModule { }
