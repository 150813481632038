import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SecureComponent } from './secure.component';



@NgModule({
  declarations: [SecureComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
  ],
  exports:[
    SecureComponent
  ]
})
export class SecureModule { }
