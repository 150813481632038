import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../LogicApi/Auth/auth.service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
  export class AdminGuard implements CanActivate {
  
    constructor(private authService: AuthService, private router: Router) { }
  
  async canActivate() {
      let act =await this.authService.isLoggedIn().then((res:any)=>{return res;})

      if (act) {
        if(this.authService.userdata.profile.is_admin!=1)
        {this.router.navigate(['/cabinet']);}
        else
        return true;
      }
      return !act;
    }
  }