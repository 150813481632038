import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthHeaderComponent } from './auth-header.component';



@NgModule({
  declarations: [AuthHeaderComponent],
  imports: [
    CommonModule
  ],
  exports:[AuthHeaderComponent]
})
export class AuthHeaderModule { }
