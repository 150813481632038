import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-close-withdraw',
  templateUrl: './close-withdraw.component.html',
  styleUrls: ['./close-withdraw.component.sass']
})
export class CloseWithdrawComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
