import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.sass']
})
export class ContactsComponent implements OnInit,OnDestroy,AfterViewInit {

  constructor() { }

 
  
  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    $('body').removeClass('contact-page');
  }
  ngAfterViewInit(): void {
    $('body').addClass('contact-page');

  }

}
