<form class="uk-form-horizontal uk-margin-large">

    <div class="uk-margin">
        <label class="uk-form-label" for="form-horizontal-text">Старый пароль</label>
        <div class="uk-form-controls">
            <input class="uk-input" name="lost_password" [(ngModel)]="lost_password" style="border-radius: 50px " required type="password" minlength="8" placeholder="">
        </div>
    </div>
    <div class="uk-margin">
        <label class="uk-form-label">Новый пароль</label>
        <div class="uk-form-controls">
            <input class="uk-input" name="newpassword" [(ngModel)]="newpassword" style="border-radius: 50px " required type="password" minlength="8" placeholder="">
        </div>
    </div>
    <div class="uk-margin">
        <label class="uk-form-label" for="form-horizontal-text">Повторите пароль</label>
        <div class="uk-form-controls">
            <input class="uk-input" name="refreshpassword" [(ngModel)]="refreshpassword" style="border-radius: 50px " required type="password" minlength="8" placeholder="">
        </div>
    </div>

    <div class="uk-margin">
        <button (click)="change_password()" class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" style="border-radius: 50px ">Сохранить</button>
    </div>

</form>