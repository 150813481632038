import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/LogicApi/Auth/auth.service';
import { Router } from '@angular/router';
declare var $:any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit, AfterViewInit,OnDestroy {

  login:string="";
  password:string="";
  constructor(private auth:AuthService,private router:Router) { }

  private loadExternalStyles(styleUrl: string) {
    return new Promise((resolve, reject) => {
      const styleElement = document.createElement('link');
      styleElement.href = styleUrl;
      styleElement.rel="stylesheet";
      styleElement.onload = resolve;
      document.getElementById('elem').appendChild(styleElement);
    });
  }

  ngAfterViewInit(): void {
    this.loadExternalStyles('/assets/auth/css/app.css').then(() => {    
    $('body').addClass('login');
    $('.container2').css('display','none');
    /*if($('#load')[0].style.display=='none')*/
    $('#load')[0].style.display='block';

    $('body').css('display','block');
    console.log(1);
    
  }).catch(() => {
    $('body').addClass('login');
    $('.container2').css('display','none');
    $('#load')[0].style.display='block';

    $('body').css('display','block');
    console.log(2);
  });


  }
  ngOnDestroy(): void {
    console.log(3);

    $('body').css('display','flex');
    $('#load').css('display','none');
    $('.container2').css('display','');
   $('body').removeClass('login');
  }

  ngOnInit(): void {
  }
 async loginbtn()
  {
    console.log(4);

    $('body').css('display','flex');
    $('#load').css('display','none');
    $('.container2').css('display','');
   $('body').removeClass('login');
   await this.auth.login(this.login,this.password).then((result)=>{
    if(result)
    {
      
     this.router.navigate(['/cabinet/']);
    }
    else
    {
      $('body').addClass('login');
      $('.container2').css('display','none');
      $('body').css('display','block');
      $('#load').css('display','block');
    }
   });
   
  }
}