import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/Service/http.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private request:HttpService) { }

  public async get()
  {
    let body = {};
    var result = await this.request.post('/cabinet/profile',body).toPromise().then(
        (res:any)=>{
          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result;
  }
   public async profile_data_update(email,name,family,middlename,birthday,telephone,currency,user,balance)
  {
    let body = {email,name,family,middlename,birthday,telephone,currency,user,balance};
    var result = await this.request.post('/cabinet/profile/data_update',body).toPromise().then(
        (res:any)=>{
          //console.log(res);

          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result;
  }
  public async update_password(lostpassword,password,user)
  {
    let body = {lostpassword,password,user};
    var result = await this.request.post('/cabinet/profile/change_password',body).toPromise().then(
        (res:any)=>{
          //console.log(res);
          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result;
  }
  public async ImageUploadPost(files,user)
  {
    const formData = new FormData();
    for (const file of files) {
        formData.append('image', file, file.name);
    }
    formData.append('user', user);

    var result = await this.request.post('/cabinet/profile/upload_photo',formData).toPromise().then(
        (res:any)=>{
         // console.log(res);

          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result;
  }

  public async ProfilePassportUploadPost(files,user)
  {
    const formData = new FormData();
    for (const file of files) {
        formData.append('image', file, file.name);
    }
    formData.append('user', user);

    var result = await this.request.post('/cabinet/profile/upload_passport_verification',formData).toPromise().then(
        (res:any)=>{
          //console.log(res);

          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result;
  }
  public async ProfileAddressUploadPost(files,user)
  {
    const formData = new FormData();
    for (const file of files) {
        formData.append('image', file, file.name);
    }
    formData.append('user', user);

    var result = await this.request.post('/cabinet/profile/upload_address_verification',formData).toPromise().then(
        (res:any)=>{
         // console.log(res);

          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result;
  }
  public async ProfilePhotoWithPassportUploadPost(files,user)
  {
    const formData = new FormData();
    for (const file of files) {
        formData.append('image', file, file.name);
    }
    formData.append('user', user);

    var result = await this.request.post('/cabinet/profile/upload_photo_with_passport_verification',formData).toPromise().then(
        (res:any)=>{
          //console.log(res);

          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result;
  }
  public async ImageDocumentGet(user = null,type=null,photo_id=null)
  {
    let body = {user,type,photo_id};
    var result = await this.request.post('/profile/get_document/'+user+'/'+type+'/'+photo_id,body).toPromise().then(
        (res:any)=>{

          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result;
  }
  public async admin_open_profile_user(user_id)
  {
    let body = {};
    var result = await this.request.post('/cabinet/admin/users/'+user_id+'/profile',body).toPromise().then(
        (res:any)=>{
          return res;
      },
      err=>{
        console.log(err);
        return null;
      });
      return result;
  }
}
