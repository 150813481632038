<app-site-header></app-site-header>
<section class="banner-section">
    <div class="container">
        <div class="header-title text-center">
            <h2 class="title">О компании</h2>
            <span class="sub-title">Главная &gt; <span>О компании</span></span>
            <div class="mouse hidden-xs wow fadeInDown animated" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <div class="mouse-icon">
                    <div class="wheel"></div>
                </div>
            </div>
        </div>
        <!-- header-title -->
    </div>
</section>
<section class="working-section section-padding">
    <div class="container">
        <div class="section-wrapper">
            <div class="row">
                <div class="col-md-9">
                    <div class="wrapper-content">
                        <div class="iconbox wow fadeInDown animated animated" data-wow-delay="0.6s" style="visibility: visible; animation-delay: 0.6s; animation-name: fadeInDown;">
                            <h5 class="title">Air Solutions Group / INSIDER BUSINESS GROUP LIMITED: ИНВЕСТИРУЙТЕ В БУДУЩЕЕ.</h5>
                            <span class="title-border"></span>
                            <br>
                            <p>Мы — компания, которая инвестирует в будущее. мы помогаем своим клиентам воплощать их мечты в реальность. Air Solutions Group создано инвесторами и для инвесторов с целью помочь максимально эффективно вкладывать средства в
                                новые технологии в сфере интернет-бизнеса. Наши офисы есть в Нью-Йорке, Москве, Амстердаме и Стокгольме.</p>
                            <br>
                            <h5 class="title">Полное название нашей компании <b>«Insider Business Group Limited»</b></h5><span class="title-border"></span><br>
                            <p>Наша специализация — стартапы в сфере интернет-бизнеса, которые динамично развиваются, либо имеют большой скрытый потенциал. Проекты являют собой как исключительно медийные стартапы, так и e-commerce, технология Blockchain
                                и ее адаптация, Bitcoin и прочие криптовалюты, виртуальная реальность, 3D-концепты, искусственный интеллект и робототехника. Мы собрали международную команду инвестбанкиров, юристов, финансовых аналитиков, трейдеров, менеджеров
                                и программистов, чтобы это будущее монетизировать.</p>
                            <div class="link-button wow fadeInUp animated animated animated" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp; text-align: center;">
                                <a style="white-space:normal;" [routerLink]="['/cabinet']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active" class="btn btn-primary"><i class="fa fa-hand-o-right" aria-hidden="true"></i> Инвестировать прямо сейчас</a>
                                <a [routerLink]="['/bussiness-model']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active" class="btn btn-default"><i class="fa fa-file-text-o" aria-hidden="true"></i> Читать нашу бизнес-модель</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="section-sidebar">
                        <div class="project-part">
                            <h4>Полезные ссылки</h4>
                            <ul>
                                <li><a [routerLink]="['/']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active">Главная <i class="fa fa-angle-right" aria-hidden="true"></i></a></li>
                                <li><a [routerLink]="['/about']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active">О компании <i class="fa fa-angle-right" aria-hidden="true"></i></a></li>
                                <li><a [routerLink]="['/bussiness-model']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active">Бизнес-модель <i class="fa fa-angle-right" aria-hidden="true"></i></a></li>
                                <li><a [routerLink]="['/faq']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active">F.A.Q. <i class="fa fa-angle-right" aria-hidden="true"></i></a></li>
                                <li><a [routerLink]="['/contacts']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active">Контакты <i class="fa fa-angle-right" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                        <div class="project-doc">
                            <div class="col-md-12">
                                <h4>Документы компании:</h4>
                                <div class="col-md-6 col-sm-6 col-xs-6">
                                    <div class="portfolio-item wow bounceIn" data-wow-delay="0.3s" style=" animation-delay: 0.3s; animation-name: none;">
                                        <div class="portfolio-image">
                                            <a data-fancybox="gallery" href="/assets/site/img/reg-mini.jpg"><img style="width: 100%;" src="/assets/site/img/reg-mini.jpg"></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-6">
                                    <div class="portfolio-item wow bounceIn" data-wow-delay="0.3s" style=" animation-delay: 0.3s; animation-name: none;">
                                        <div class="portfolio-image">
                                            <a data-fancybox="gallery" href="/assets/site/img/sv-mini.jpg"><img style="width: 100%;" src="/assets/site/img/sv-mini.jpg"></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-12 col-xs-12" style="font-size: 10px;text-align: center;"> СВИДЕТЕЛЬСТВО O РЕГИСТРАЦИИ ЮРИДИЧЕСКОГО ЛИЦА <br>Insider Business Group Limited <br> Company Number: 2780528</div>
                                <div class="asset-part">
                                    <a [routerLink]="['/cabinet']" class="nav-link " [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active" class="btn btn-default"><i class="fa fa-user-circle-o" aria-hidden="true"></i> Личный кабинет</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>