<div class="uk-grid">
    <div class="uk-width-1-3@s uk-width-1-1">
        <div class="uk-card uk-card-small uk-card-default">
            <div class="uk-card-header">
                <div class="uk-grid uk-grid-small uk-text-small" data-uk-grid="">
                    <div class="uk-width-expand uk-first-column">
                        <span class="cat-txt">{{family}} {{name}} </span>
                    </div>
                </div>
            </div>
            <div class="uk-card-media">
                <div class="uk-inline-clip uk-transition-toggle" tabindex="0">
                    <ng-container *ngIf="photo!=null && photo!=''">
                        <img class="lazy" data-width="400" data-height="470" data-uk-img="" alt="" [src]="photo">

                    </ng-container>
                    <ng-container *ngIf="photo==null || photo==''">
                        <img class="lazy" data-src="/img/favicon.98322d20.png" data-width="400" data-height="470" data-uk-img="" alt="" src="/img/favicon.98322d20.png">
                    </ng-container>
                </div>
            </div>
            <div class="uk-card-body">
                <div uk-form-custom>
                    <form>
                        <div uk-form-custom="target: true" class="uk-width-1-1">
                            <input name="image" id="el1" hidden (change)="onFileChanged($event)" type="file">
                            <button type="button" (click)="openfile('el1')" class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" style="border-radius: 50px " tabindex="1">Выбрать файл</button>
                        </div>
                    </form>
                </div>
                <span class="uk-margin-small-bottom uk-margin-remove-adjacent uk-text-bold"> {{family}} {{name}} </span>
                <p class="uk-text-small uk-text-muted">Дата регистрации: {{created_at}}</p>
            </div>

        </div>

    </div>
    <div class="uk-width-2-3@s uk-width-1-1">

        <div class="uk-card uk-card-small uk-card-default">
            <div class="uk-card-header">
                <div class="uk-grid uk-grid-small uk-text-medium" data-uk-grid="">
                    <div class="uk-width-expand uk-first-column">
                        <span class="cat-txt">Ваши регистрационные данные </span>
                    </div>
                </div>
            </div>
            <div class="uk-card-body">
                <form>
                    <div class="uk-margin">
                        <label class="uk-form-label" for="email">Email</label>
                        <div class="uk-form-controls">

                            <ng-container *ngIf="isadmin==true">
                                <input class="uk-input" name="email" [(ngModel)]="email" id="email" style="border-radius: 50px " type="email" placeholder="">
                            </ng-container>
                            <ng-container *ngIf="isadmin==false">
                                <input class="uk-input" name="email" [(ngModel)]="email" id="email" style="border-radius: 50px " type="email" disabled placeholder="">
                            </ng-container>
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label" for="name">Имя</label>
                        <div class="uk-form-controls">
                            <input class="uk-input" name="name" id="name" [(ngModel)]="name" style="border-radius: 50px " required type="text" placeholder="">
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label" for="family">Фамилия</label>
                        <div class="uk-form-controls">
                            <input class="uk-input" name="family" id="family" [(ngModel)]="family" style="border-radius: 50px " required type="text" placeholder="">
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label" for="middlename">Отчество</label>
                        <div class="uk-form-controls">
                            <input class="uk-input" name="thirty_name" [(ngModel)]="thirty_name" id="middlename" style="border-radius: 50px " required type="text" placeholder="">
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label" for="birthday">Дата рождения</label>
                        <div class="uk-form-controls">
                            <input class="uk-input" name="data" [(ngModel)]="data" id="birthday" style="border-radius: 50px " required type="date" placeholder="">
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label" for="telephone">Номер телефона</label>
                        <div class="uk-form-controls">
                            <input class="uk-input" id="telephone" [(ngModel)]="telephone" name="telephone" style="border-radius: 50px " required type="number" placeholder="">
                        </div>
                    </div>
                    <ng-container *ngIf="isadmin==true">

                        <div class="uk-margin">
                            <label class="uk-form-label" for="balance">Баланс пользователя в $</label>
                            <div class="uk-form-controls">

                                <input class="uk-input" id="balance" [(ngModel)]="balance" name="balance" style="border-radius: 50px " required type="number" placeholder="">
                            </div>
                        </div>

                    </ng-container>

                    <div class="uk-margin">
                        <label class="uk-form-label" for="currency">Выберите валюту</label>
                        <div class="uk-form-controls">
                            <select id="currency" name="currency" [(ngModel)]="valute" style="border-radius: 50px " class="uk-select">
                                    <ng-container *ngIf="valute=='ruble'">
                                        <option  selected value="ruble">Рубли</option>
                                        <option value="dollars">Доллары</option>
                                    </ng-container>
                                    <ng-container *ngIf="valute=='dollars'">
                                        <option   value="ruble">Рубли</option>
                                        <option selected value="dollars">Доллары</option>
                                    </ng-container>
                            </select>
                        </div>

                    </div>


                    <div class="uk-margin">
                        <button (click)="personal_save()" class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" style="border-radius: 50px ">Сохранить</button>
                    </div>
                </form>
            </div>

        </div>

    </div>
</div>