import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SiteComponent } from './site.component';
import { IndexComponent } from './index/index.component';
import { RulesComponent } from './rules/rules.component';
import { FaqComponent } from './faq/faq.component';
import { ContactsComponent } from './contacts/contacts.component';
import { BusinessModelComponent } from './business-model/business-model.component';
import { AboutComponent } from './about/about.component';


const children_routes:Routes=[
  {
    path:'',
    component:IndexComponent
  },
  {
    path:'about',
    component:AboutComponent
  },
  {
    path:'bussiness-model',
    component:BusinessModelComponent
  },
  {
    path:'contacts',
    component:ContactsComponent
  },
  {
    path:'faq',
    component:FaqComponent
  },
  {
    path:'rules',
    component:RulesComponent
  },
]

const routes: Routes = [

    {
      path:'',
      component:SiteComponent,
      children:children_routes
    }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SiteRoutingModule { }
