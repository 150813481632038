import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { financeComponent } from './finance.component';
import { DepositModule } from './deposit/deposit.module';
import { WithdrawModule } from './withdraw/Withdraw.module';



@NgModule({
  declarations: [financeComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    DepositModule,
    WithdrawModule
  ],
  exports:[],
  bootstrap:[financeComponent]
})
export class FinanceModule { }
