<fieldset class="uk-fieldset">
    <ng-container *ngIf="balance!=null">
        <legend class="uk-legend uk-flex-center"> Вы также можете торговать тут. У вас на балансе {{balance}}$. Изменение 1 пункта объемом 1 лот = {{pnc}}$. Доступные средства - {{funds}}$
        </legend>
    </ng-container>
</fieldset>


<div class="uk-grid" (window:resize)="onResize($event)">
    <div class="uk-width-1-1 uk-margin-top">
        <div class="uk-card uk-card-small uk-card-default uk-card-body uk-margin-small-bottom uk-card-hover
    uk-text-small">
<!--            <div class="uk-card-header">-->
<!--                <div class="uk-grid uk-grid-small uk-text-large" data-uk-grid="">-->
<!--                    <div class="uk-width-expand uk-text-center">-->
<!--                        <span class="uk-text-emphasis "> График {{selected_pairs}}</span>-->

<!--                        <div class="uk-margin">-->
<!--                            <label class="uk-form-label" for="currency">Выбрать график</label>-->
<!--                            <div class="uk-form-controls">-->
                                <select style="font-size: 15px;" class="" name="selected_pairs" [(ngModel)]="selected_pairs" (change)="change_pairs($event)" style="border-radius: 50px " class="uk-select">
                                    <option style="font-size: 15px;" value="EURUSD">EURUSD</option>
                                    <option style="font-size: 15px;" *ngFor="let item of pairs" [value]="item.symbol">{{item.symbol}}</option>
                                </select>
<!--                            </div>-->

<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="uk-card-body" id="chart" style="width: 100%;">-->
            <div class="chart_block_tradeview">
                <!-- TradingView Widget BEGIN -->
                <div class="tradingview-widget-container">
                    <div id="tradingview_0cbc7"></div>
                    <div class="tradingview-widget-copyright"><a href="https://ru.tradingview.com/symbols/EURUSD/?exchange=FOREXCOM" rel="noopener" target="_blank"><span class="blue-text">График EURUSD</span></a> от TradingView</div>
                </div>
                <!-- TradingView Widget END -->
            </div>
            </div>
        </div>
    </div>
<div class="uk-grid">
    <div class="uk-width-1-1 uk-margin-top">
        <div class="uk-card uk-card-small uk-card-default uk-card-body uk-margin-small-bottom uk-card-hover
    uk-text-small">
            <div class="uk-card-header">
                <div class="uk-grid uk-grid-small uk-text-large" data-uk-grid="">
                    <div class="uk-width-expand uk-text-center">
                        <span class="uk-text-emphasis ">Провести операцию</span>
                        <br>
                        <span style="color: red;">{{error}}</span>
                    </div>
                </div>
            </div>

            <div class="uk-card-body">
                <div class="uk-margin">
                    <label class="uk-form-label" for="lot">Введите объем</label>
                    <div class="uk-form-controls">
                        <input class="uk-input" id="lot" [(ngModel)]="lot" name="lot" style="border-radius: 50px " required type="number" placeholder="">
                        <input class="uk-input" id="symbol" name="selected_pairs" [(ngModel)]="selected_pairs" value="EURUSD" style="border-radius: 50px " required type="hidden" placeholder="">

                    </div>
                </div>
                <div class="uk-margin">
                    <label class="uk-form-label" for="currency">Какую сделку вы хотите Провести</label>
                    <div class="uk-form-controls">
                        <select class="" name="type_action" [(ngModel)]="type_action" style="border-radius: 50px " class="uk-select">
                                    <option value="buy">Купить</option>
                                    <option value="sell">Продать</option>
                                </select>
                    </div>

                </div>
                <div class="uk-margin">
                    <button (click)="create()" class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" style="border-radius: 50px ">Подтвердить</button>
                </div>

            </div>

        </div>
    </div>
</div>

<div class="uk-grid">
    <div class="uk-width-1-1 uk-margin-top">
        <div class="uk-card uk-card-small uk-card-default uk-card-body uk-margin-small-bottom uk-card-hover
    uk-text-small">
            <div class="uk-card-header">
                <div class="uk-grid uk-grid-small uk-text-large" data-uk-grid="">
                    <div class="uk-width-expand uk-text-center">
                        <span class="uk-text-emphasis ">Ваши депозиты</span>
                    </div>
                </div>
            </div>

            <div class="uk-card-body">

                <div style="overflow-x:auto;">
                    <table class="uk-table uk-table-middle uk-text-bold  uk-table-divider">
                        <thead class="uk-text-bold">
                            <tr>
                                <th class="text-center">№</th>
                                <th class="text-center ">Тип</th>
                                <th class="text-center ">Цена</th>
                                <th class="text-center ">Объем</th>
                                <th class="text-center ">Валютная пара</th>
                                <th class="text-center ">Дата/время</th>
                                <th class="text-center ">Прибыль</th>
                                <th class="text-center ">Закрыть сделку</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of table_data">
                                <td class="text-center">{{item.id}}</td>
                                <td class="text-center" *ngIf="item.type=='buy'">Покупка</td>
                                <td class="text-center" *ngIf="item.type=='sell'">Продажа</td>

                                <td>
                                    <div class="text-gray-600 text-xs ">{{item.price}}</div>
                                </td>
                                <td class="text-center">{{item.lot}}</td>
                                <td class="text-center">{{item.symbol}}</td>

                                <td class="text-center">{{item.created_at}}</td>
                                <td class="text-center">{{item.profit.toFixed(6)}}</td>

                                <td class="text-center">
                                    <button (click)="close(item.id)" class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" style="border-radius: 50px ">Закрыть сделку</button>

                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</div>
<!--div class="intro-y flex items-center mt-8">
    <h2 class="text-lg font-medium mr-auto">
        Вы также можете торговать тут. У вас на балансе {{balance.toFixed(3)}}$. Изменение 1 пункта объемом 1 лот = 100000$. Доступные средства - {{funds.toFixed(3)}}$
    </h2>
</div>
<div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
    <div class="col-span-12 lg:col-span-12">
        <div class="intro-y box" id="chart-content">
            <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200">
                <h2 class="font-medium text-base mr-auto">
                    График {{selected_pairs}}
                </h2>
                <div class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0">
                    <div class="mr-3">Выбрать график</div>
                    <select class="input box mt-3 sm:mt-0" name="selected_pairs" [(ngModel)]="selected_pairs" (change)="change_pairs($event)">
                        <option *ngFor="let item of pairs" [value]="item.symbol">{{item.symbol}}</option>
                    </select>
                </div>
            </div>
            <div id="chart" (window:resize)="onResize($event)">

            </div>

        </div>
    </div>
</div>

<div class="grid grid-cols-12 gap-6 mt-5">

    <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200">
            <h2 class="font-medium text-base mr-auto">
                Провести операцию
            </h2>
            <h2 class="font-medium text-base mr-auto" style="color: red;">
                {{error}}
            </h2>
        </div>
        <div class="p-5" id="inline-form">
            <div class="preview">
                <div class="flex  flex-row items-center">
                    <div class="flex-col">
                        <input type="text" name="lot" [(ngModel)]="lot" class="input w-full border col-span-4" placeholder="Объем">

                    </div>
                    <div class="flex-col">
                        <select name="type_action" [(ngModel)]="type_action" class="input input--lg box w-full  mt-3 lg:mt-0 ml-auto">

                            <option value="buy">Купить</option>
                            <option value="sell">Продать</option>
                        </select>
                    </div>
                    <div class="flex-col">
                        <button type="button" (click)="create()" class="button bg-theme-1 text-white">Подтвердить</button>

                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <table class="table table-report -mt-2">
            <thead>
                <tr>
                    <th class="text-center">№</th>
                    <th class="text-center ">Тип</th>
                    <th class="text-center ">Цена</th>
                    <th class="text-center ">Объем</th>
                    <th class="text-center ">Валютная пара</th>
                    <th class="text-center ">Дата/время</th>
                    <th class="text-center ">Прибыль</th>
                    <th class="text-center ">Закрыть сделку</th>

                </tr>
            </thead>
            <tbody>
                <tr class="intro-x" *ngFor="let item of table_data">
                    <td class="text-center">{{item.id}}</td>
                    <td class="text-center" *ngIf="item.type=='buy'">Покупка</td>
                    <td class="text-center" *ngIf="item.type=='sell'">Продажа</td>

                    <td>
                        <div class="text-gray-600 text-xs ">{{item.price}}</div>
                    </td>
                    <td class="text-center">{{item.lot}}</td>
                    <td class="text-center">{{item.symbol}}</td>

                    <td class="text-center">{{item.created_at}}</td>
                    <td class="text-center">{{item.profit.toFixed(6)}}</td>

                    <td class="text-center">
                        <button class="button w-24 rounded-full mr-1 mb-2 bg-theme-6 text-white" (click)="close(item.id)">Закрыть сделку</button>

                    </td>

                </tr>
            </tbody>
        </table>
    </div>
</div> -->