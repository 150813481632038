<div class="uk-container uk-container-center">
    <ul class="uk-tab" data-uk-tab="{connect:'#my-id'}">
        <li (click)="change_menu(1)">
            <a>
                <h4>Список пользователей</h4>
            </a>
        </li>
        <li (click)="change_menu(2)">
            <a>
                <h4>Поиск пользователей</h4>
            </a>
        </li>
    </ul>
    <ul id="my-id" class="uk-switcher uk-margin">
        <li [ngClass]="{'uk-active': item_uk_switcher==1}">
            <app-list></app-list>
        </li>
        <li [ngClass]="{'uk-active': item_uk_switcher==2}">
            <app-search></app-search>
        </li>
    </ul>

</div>