import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
declare var $:any;
declare var WOW:any;
@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.sass']
})
export class SiteComponent implements OnInit,AfterViewInit,OnDestroy {

  constructor() { }
  tm:boolean = false;

  ngAfterViewInit(): void {
  }


  
  ngOnInit (): void {
    $('.container2').fadeOut(800,'swing',function () {
      $('body').css('display','block');
      $('#load').fadeIn(800);
    }); 
        if(!this.tm){

 
      // -------------------------------------------------------------
      // Sticky Menu
      // -------------------------------------------------------------
  
      function menuSticky() {
          var navHeight = $("#box").height();
          ($(window).scrollTop() > navHeight) ? $('nav').addClass('sticky'): $('nav').removeClass('sticky');
      }
      // -------------------------------------------------------------
      //  	Offcanvas Menu
      // -------------------------------------------------------------
      
     
  
  
      // -------------------------------------------------------------
      //  Offcanvas Menu Sub-menu
      // -------------------------------------------------------------
      if ($('.dropBar').length) {
          $('.dropBar').on("click", function() {
              $(this).parent().find('ul').slideToggle();
              return false;
          });
      }
  
  
  
      // -------------------------------------------------------------
      // Sub-menu
      // -------------------------------------------------------------
      if ($('.dropmenu').length) {
          $('.dropmenu').on("click", function() {
              $(this).parent().find('ul').slideToggle();
              return false;
          });
      }
  
  
  
      // -------------------------------------------------------------
      //      Single-Page-Menu-Scrolling  Easy Plugin
      // -------------------------------------------------------------
  
      function singlePageScroll() {
          $('a.page-scroll').on('click', function(event) {
              var $anchor = $(this);
              $('html, body').stop().animate({
                  scrollTop: $($anchor.attr('href')).offset().top
              }, 1500, 'easeInOutExpo');
              event.preventDefault();
          });
      }
  
  
  
  
      // -------------------------------------------------------------
      //      Search Bar
      // -------------------------------------------------------------
  
          var openbar = $(".open-bar");
          var searchbar = $("#search-bar");
          var closebar = $(".close-bar");
  
          openbar.on("click", function() {
              searchbar.addClass("active");
              return false;
          });
  
          closebar.on("click", function() {
              searchbar.removeClass("active");
              return false;
          });
  
  
  
      // -------------------------------------------------------------
      //      Map/Contact-Box-Remove
      // -------------------------------------------------------------
  
          var mapview = $(".map-view");
          var contactsection = $(".contact-section-one");
          var formview = $(".form-view");
  
          mapview.on("click", function() {
              contactsection.fadeOut('3000');
              return false;
          });
  
          formview.on("click", function() {
              contactsection.fadeIn('3000');
              return false;
          });
  
  
      // -------------------------------------------------------------
      //      Cart-Box-Open/Remove
      // -------------------------------------------------------------
  
          var openclose = $(".cart-wrapper");
  
          openclose.on("click", function() {
              return $(this).toggleClass("open");
          });
  
  
  
      // -------------------------------------------------------------
      //      LightBox-Js
      // -------------------------------------------------------------
  
      if ($('#lightBox').length) {
          $('#lightBox').poptrox({
              usePopupCaption: true,
              usePopupNav: true,
              popupPadding: 0
          });
      }
  
  
  
      // -------------------------------------------------------------
      //      Client-Slider
      // -------------------------------------------------------------
  
      if ($('.client-carousel').length) {
          $('.client-carousel').owlCarousel({
              loop: true,
              autoplay: true,
              autoplayTimeout: 3000,
              margin: 10,
              dots: false,
              nav: true,
              responsive: {
                  0: {
                      items: 1,
                      nav: false,
                  },
                  600: {
                      items: 3,
                      nav: false,
                  },
                  1000: {
                      items: 5
                  }
              }
          });
      }
      if ($('.expert-carousel').length) {
          $('.expert-carousel').owlCarousel({
              loop: true,
              autoplay: true,
              margin: 20,
              items: 3,
              nav: false,
              responsive: {
                  0: {
                      items: 1
                  },
                  600: {
                      items: 2
                  },
                  1000: {
                      items: 3
                  }
              }
          });
      }
  
  
  
      // -------------------------------------------------------------
      //      service-Slider
      // -------------------------------------------------------------
  
      if ($('.service-carousel').length) {
          $('.service-carousel').owlCarousel({
              loop: true,
              autoplay: true,
              margin: 20,
              items: 3,
              nav: false,
              responsive: {
                  0: {
                      items: 1
                  },
                  600: {
                      items: 2
                  },
                  1000: {
                      items: 3
                  }
              }
          });
      }
  
  
  
      // -------------------------------------------------------------
      //      service-Slider
      // -------------------------------------------------------------
  
      if ($('#testimonial-carousel').length) {
          $('#testimonial-carousel').carousel({
              interval: 0
          });
      }
  
      function progressBar() {
          $('.progressSection').on('inview', function(event, visible, visiblePartX, visiblePartY) {
              if (visible) {
                  $.each($('div.progress-bar'), function() {
                      $(this).css('width', $(this).attr('aria-valuenow') + '%');
                  });
                  $(this).off('inview');
              }
          });
      }
      function funFactCounting() {
          if ($('.counting-section').length) {
              $('.counting-section').on('inview', function(event, visible, visiblePartX, visiblePartY) {
                  if (visible) {
                      $(this).find('.timer').each(function() {
                          var $this = $(this);
                          $({ Counter: 0 }).animate({ Counter: $this.text() }, {
                              duration: 2000,
                              easing: 'swing',
                              step: function() {
                                  $this.text(Math.ceil(this.Counter));
                              }
  
                          });
                      });
                      $(this).unbind('inview');
                  }
              });
          }
      }
  
  
  
  
      // -------------------------------------------------------------
      //      Google Map
      // -------------------------------------------------------------
  
  
  
  
      // -------------------------------------------------------------
      // Back To Top
      // -------------------------------------------------------------
  
      function backToTopBtnAppear() {
          if ($("#toTop").length) {
              var windowpos = $(window).scrollTop(),
                  backToTopBtn = $("#toTop");
  
              if (windowpos > 300) {
                  backToTopBtn.fadeIn();
              } else {
                  backToTopBtn.fadeOut();
              }
          }
      }
  
      function backToTop() {
          if ($("#toTop").length) {
              var backToTopBtn = $("#toTop");
              backToTopBtn.on("click", function() {
                  $("html, body").animate({
                      scrollTop: 0
                  }, 1000);
  
                  return false;
              })
          }
      }
  
  
  
  
      // -------------------------------------------------------------
      // 		Preloader
      // -------------------------------------------------------------
  
      function preloader() {
          if ($('#preloader').length) {
              $('#preloader').delay(500).fadeOut('slow');
          }
      }
  
  
  
  
      // -------------------------------------------------------------
      //      WHEN WINDOW LOAD
      // -------------------------------------------------------------
  
      $(window).on("load", function() {
  
          backToTop();
  
          preloader();
  
          new WOW().init();
  
          funFactCounting();
  
          progressBar();
      })
  
  
  
      // -------------------------------------------------------------
      //      WHEN WINDOW SCROLL
      // -------------------------------------------------------------
      $(window).on("scroll", function() {
  
          backToTopBtnAppear();
  
          menuSticky();
  
          singlePageScroll();
      });
      this.tm=true;
       }
    }

  ngOnDestroy(): void {
    $('body').css('display','flex');
    $('#load').css('display','none');
    $('.container2').css('display','');
  }
   push(e, defName, endName) {
    e.preventDefault();
    var val = $('#'+defName).val();
    switch (defName) {
        case 'plan1def':
          $('#'+endName).val((val * 0.015 * 14).toFixed(2));
            break;
        case 'plan7def':
          $('#'+endName).val((val * 0.02 * 30).toFixed(2));
            break;
        case 'plan14def':
            $('#'+endName).val((val * 0.025 * 45).toFixed(2));
            break;
        case 'plan28def':
            $('#'+endName).val((val * 0.03 * 60).toFixed(2));
            break;

        default:
            break;
    }
    return false;
}
}
