<div class="uk-grid">
    <div class="uk-width-1-3@s uk-width-1-1">
        <div class="uk-card uk-card-small uk-card-default">
            <div class="uk-card-header">
                <div class="uk-grid uk-grid-small uk-text-small" data-uk-grid="">
                    <div class="uk-width-expand uk-first-column">
                        <span class="cat-txt">{{family}} {{name}} </span>
                    </div>
                </div>
            </div>
            <div class="uk-card-media">
                <div class="uk-inline-clip uk-transition-toggle" tabindex="0">
                    <ng-container *ngIf="photo!=null && photo!=''">
                        <img class="lazy" data-width="400" data-height="470" data-uk-img="" alt="" [src]="photo">

                    </ng-container>
                    <ng-container *ngIf="photo==null || photo==''">
                        <img class="lazy" data-src="/img/favicon.98322d20.png" data-width="400" data-height="470" data-uk-img="" alt="" src="/img/favicon.98322d20.png">
                    </ng-container>
                </div>
            </div>
            <div class="uk-card-body">
                <div uk-form-custom>
                    <form>
                        <div uk-form-custom="target: true" class="uk-width-1-1">
                            <input name="image" id="el1" (change)="onFileChanged($event)" type="file" hidden>
                            <button type="button" (click)="openfile('el1')" class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" style="border-radius: 50px " tabindex="1">Выбрать файл</button>
                        </div>
                    </form>
                </div>
                <span class="uk-margin-small-bottom uk-margin-remove-adjacent uk-text-bold"> {{family}} {{name}} </span>
                <p class="uk-text-small uk-text-muted">Дата регистрации: {{created_at}}</p>
            </div>

        </div>

    </div>
    <div class="uk-width-2-3@s uk-width-1-1">

        <div class="uk-card uk-card-small uk-card-default">
            <div class="uk-card-header">
                <div class="uk-grid uk-grid-small uk-text-large" data-uk-grid="">
                    <div class="uk-width-expand uk-text-center">
                        <span class="uk-text-emphasis ">Верификация документов</span>
                    </div>
                </div>
            </div>

            <div class="uk-card-body">

                <div class="uk-grid uk-grid-small uk-text-medium" data-uk-grid="">
                    <div class="uk-width-large uk-margin-auto uk-text-center">
                        <span class="uk-text-emphasis uk-text-bold">Документы, подтверждающие личность</span>
                    </div>
                    <div class="uk-width-large uk-margin-auto uk-text-center">
                        <span class="uk-text-emphasis">Фото паспорта, загран паспорта или водительского удостоверения</span>
                    </div>
                    <div class="uk-width-large uk-margin-auto uk-text-center">
                        <form>


                            <div uk-form-custom="target: true" class="uk-width-1-1">
                                <input name="image" id="el2" (change)="ProfilePassportUploadPost($event)" type="file" hidden>
                                <button type="button" (click)="openfile('el2')" class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" style="border-radius: 50px " tabindex="1">Выбрать файл</button>
                            </div>

                        </form>
                    </div>
                </div>
                <div class="uk-grid uk-grid-small uk-text-medium" data-uk-grid="">
                    <div class="uk-width-large uk-margin-auto uk-text-center">
                        <span class="uk-text-emphasis uk-text-bold">Документы, подтверждающие адрес</span>
                    </div>
                    <div class="uk-width-large uk-margin-auto uk-text-center">
                        <span class="uk-text-emphasis">Скан или фото платежки по ЖКХ или другого документа, подтверждающего адрес прописки.</span>
                    </div>
                    <div class="uk-width-large uk-margin-auto uk-text-center">
                        <form>


                            <div uk-form-custom="target: true" class="uk-width-1-1">
                                <input name="image" id="el3" (change)="ProfileAddressUploadPost($event)" type="file" hidden>
                                <button type="button" (click)="openfile('el3')" class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" style="border-radius: 50px " tabindex="1">Выбрать файл</button>
                            </div>

                        </form>
                    </div>
                </div>
                <div class="uk-grid uk-grid-small uk-text-medium" data-uk-grid="">
                    <div class="uk-width-large uk-margin-auto uk-text-center">
                        <span class="uk-text-emphasis uk-text-bold">При необходимости:</span>
                    </div>
                    <div class="uk-width-large uk-margin-auto uk-text-center">
                        <span class="uk-text-emphasis">Фото Вас с паспортом</span>
                    </div>
                    <div class="uk-width-large uk-margin-auto uk-text-center">
                        <form>


                            <div uk-form-custom="target: true" class="uk-width-1-1">
                                <input name="image" id="el4" (change)="ProfilePhotoWithPassportUploadPost($event)" type="file" hidden>
                                <button type="button" (click)="openfile('el4')" class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" style="border-radius: 50px " tabindex="1">Выбрать файл</button>
                            </div>

                        </form>
                    </div>
                </div>


            </div>

        </div>

    </div>
</div>