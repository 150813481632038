import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../LogicApi/Auth/auth.service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
  export class AuthGuard implements CanActivate {
  
    constructor(private authService: AuthService, private router: Router) { }
  
  async  canActivate() {
      let act =await this.authService.isLoggedIn().then((res:any)=>{return res;})

      if (act) {
        this.router.navigate(['/cabinet']);
      }
      return !act;
    }
  }