import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-lost-password-finish',
  templateUrl: './lost-password-finish.component.html',
  styleUrls: ['./lost-password-finish.component.sass']
})
export class LostPasswordFinishComponent implements OnInit {

  constructor() { }

  private loadExternalStyles(styleUrl: string) {
    return new Promise((resolve, reject) => {
      const styleElement = document.createElement('link');
      styleElement.href = styleUrl;
      styleElement.rel="stylesheet";
      styleElement.onload = resolve;
      document.getElementById('elem').appendChild(styleElement);
    });
  }


  ngAfterViewInit(): void {
    this.loadExternalStyles('/assets/auth/css/app.css').then(() => {
      $('body').addClass('login');
    $('.container2').css('display','none');
      $('body').css('display','block');
      $('#load').css('display','block');
    }).catch(() => {
      $('body').addClass('login');
      $('.container2').css('display','none');
        $('body').css('display','block');
        $('#load').css('display','block');
    });

    
  }
  ngOnDestroy(): void {
    $('body').css('display','none');
    $('#load').css('display','none');
    $('.container2').css('display','');
   $('body').removeClass('login');
  }

  ngOnInit(): void {
  }
}
