import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteSliderComponent } from './site-slider.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [SiteSliderComponent],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports:[
    CommonModule, SiteSliderComponent
  ]
})
export class SiteSliderModule { }
